import * as React from 'react';
import PropTypes from 'prop-types';
import { timeConverter } from '../../../tools/time';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Badge from '@mui/material/Badge';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function TicketsTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      component={'div'}
      sx={{
        blogRadius: '10px',
      }}
    >
      <Table
        sx={{
          minWidth: 500,
          bgcolor: '#1E272E',
          blogRadius: 10,
        }}
        aria-label="custom pagination table"
      >
        <TableHead sx={{ bgcolor: '#111827' }}>
          <TableRow>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              No
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Author
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Category
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Title
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Content
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Date
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Time
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ bgcolor: '#111827' }}>
          {(rowsPerPage > 0
            ? props.data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : props.data
          ).map((obj, index) => (
            <TableRow
              key={`${obj.title}${index}`}
              onClick={() => props.getActiveTicket(obj.id)}
            >
              <TableCell style={{ width: 30, color: '#dddddd' }} align="left">
                {`#00${obj.id}`}
              </TableCell>
              <TableCell style={{ width: 60, color: '#dddddd' }} align="left">
                {obj.author?.username}
              </TableCell>
              <TableCell style={{ width: 80, color: '#dddddd' }} align="left">
                {obj.category}
              </TableCell>
              <TableCell sx={{ color: '#bbbbbb' }} align="left">
                {obj.title}
              </TableCell>
              <TableCell sx={{ color: '#bbbbbb' }} align="left">
                {obj.content}
              </TableCell>
              <TableCell style={{ width: 80, color: '#dddddd' }} align="left">
                {timeConverter(obj.date).format('DD-MM-yy')}
              </TableCell>

              <TableCell style={{ width: 50, color: '#dddddd' }} align="left">
                {timeConverter(obj.created_at).format('HH:mm')}
              </TableCell>

              <TableCell style={{ width: 50, color: '#dddddd' }} align="center">
                <Badge
                  badgeContent={obj.status}
                  color={obj.status === 'Open' ? 'success' : 'error'}
                />
              </TableCell>

              {/* <TableCell style={{ width: 160, color: '#dddddd' }} align="right">
                <IconButton
                  aria-label="edit"
                  sx={{ color: '#00A8CC' }}
                  onClick={() => props.editClient(log)}
                >
                  <EditOutlinedIcon color="inherit" />
                </IconButton>
                <IconButton aria-label="delete" sx={{ color: '#F05454' }}>
                  <DeleteIcon color="inherit" />
                </IconButton>
              </TableCell> */}
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter sx={{ bgcolor: '#bbbbbb' }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={8}
              count={props.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
