import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  todayLogs: null,
  weekLogs: null,
  monthLogs: null,
  customLogs: null,
};

const getLogsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getTodayLogsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    todayLogs: action.payload,
  });
};

const getWeekLogsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    weekLogs: action.payload,
  });
};

const getMonthLogsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    monthLogs: action.payload,
  });
};

const getCustomLogsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    customLogs: action.payload,
  });
};

const getLogsFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const geyserLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOGS_START:
      return getLogsStart(state, action);
    case actionTypes.GET_TODAY_LOGS_SUCCESS:
      return getTodayLogsSuccess(state, action);
    case actionTypes.GET_WEEK_LOGS_SUCCESS:
      return getWeekLogsSuccess(state, action);
    case actionTypes.GET_MONTH_LOGS_SUCCESS:
      return getMonthLogsSuccess(state, action);
    case actionTypes.GET_CUSTOM_LOGS_SUCCESS:
      return getCustomLogsSuccess(state, action);
    case actionTypes.GET_LOGS_FAILD:
      return getLogsFaild(state, action);
    default:
      return state;
  }
};

export default geyserLogsReducer;
