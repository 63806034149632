import React, { Component } from 'react';
import './insights-table.css';

export default class AnalyticsTable extends Component {
  render() {
    return (
      <div className="ANALYTICS_TABLE_CONTAINER">
        <div className="ANALYTICS_TABLE_HEADER">
          <div className="ANALYTICS_TABLE_HEADER_ITEM">
            <span>Power</span>
            <p>{this.props.monthlyUsage?.power_consumption}&nbsp;KWh</p>
          </div>
          <div className="ANALYTICS_TABLE_HEADER_ITEM">
            <span>Cost</span>
            <p>R&nbsp;{this.props.monthlyUsage?.power_consumption_cost}</p>
          </div>
          <div className="ANALYTICS_TABLE_HEADER_ITEM">
            <span>Saving</span>
            <p>R&nbsp;{this.props.monthlyUsage?.cost_saving}</p>
          </div>
        </div>
        <div className="ANALYTICS_DAILY_TABLE_DATA">
          {this.props.dailyUsage
            ? this.props.dailyUsage.map((data, index) => (
                <div
                  className="ANALYTICS_DAILY_TABLE_DATA_ITEM"
                  style={index % 2 === 0 ? { opacity: 0.7 } : { opacity: 1 }}
                  key={data.date}
                >
                  <span>{data.date}</span>
                  <div>
                    <div className="ANALYTICS_DAILY_TABLE_DATA_ITEM_CELL">
                      <span>Power:</span> <p>{data.power_consumption}KWh</p>
                    </div>
                    <div className="ANALYTICS_DAILY_TABLE_DATA_ITEM_CELL">
                      <span>Spent:</span> <p> R{data.power_consumption_cost}</p>
                    </div>
                    <div className="ANALYTICS_DAILY_TABLE_DATA_ITEM_CELL">
                      <span>Saved:</span> <p>R{data.cost_saving}</p>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}
