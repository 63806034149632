import React, { Component } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { getTermsAndConditions } from '../../store/actions/termsAndConditions';
import './styles/terms-conditions.css';

class TermsConditions extends Component {
  componentDidMount() {
    const token = this.props.token;
    this.props.getTermsAndConditions(token);
  }
  render() {
    return (
      <div className="TERMS_MAIN_CONTAINER" id={this.props.mode}>
        {/* <div className="TERMS_HEADER_CONTAINER">
          <h1>Terms & Conditions</h1>
        </div> */}
        <div className="TERMS_CONTENT_CONTAINER">
          {this.props.termsAndConditions
            ? parse(this.props.termsAndConditions[0].t_and_c)
            : null}
        </div>
        <hr></hr>
        <div className="TERMS_CONTENT_CONTAINER">
          {this.props.termsAndConditions
            ? parse(this.props.termsAndConditions[0].privacy)
            : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    termsAndConditions: state.termsAndConditions.termsAndConditions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTermsAndConditions: (token) => dispatch(getTermsAndConditions(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions);
