import React, { Component } from 'react';
import { connect } from 'react-redux';
import './bar-chart.css';
import { Axis, Chart, Interval, Tooltip, Legend } from 'bizcharts';

const demoData = [
  {
    day: 'Mon',
    run_duration: 30,
  },
  {
    day: 'Tue',
    run_duration: 60,
  },
  {
    day: 'Wed',
    run_duration: 120,
  },
  {
    day: 'Thu',
    run_duration: 40,
  },
  {
    day: 'Fri',
    run_duration: 30,
  },
  {
    day: 'Sat',
    run_duration: 45,
  },
  {
    day: 'Sun',
    run_duration: 30,
  },
  {
    day: 'Monn',
    run_duration: 30,
  },
  {
    day: 'Tuen',
    run_duration: 60,
  },
  {
    day: 'Wedn',
    run_duration: 120,
  },
  {
    day: 'Thun',
    run_duration: 40,
  },
  {
    day: 'Frin',
    run_duration: 30,
  },
  {
    day: 'Satn',
    run_duration: 45,
  },
  {
    day: 'Sunn',
    run_duration: 30,
  },
  {
    day: 'Mone',
    run_duration: 30,
  },
  {
    day: 'Tuee',
    run_duration: 60,
  },
  {
    day: 'Wede',
    run_duration: 120,
  },
  {
    day: 'Thue',
    run_duration: 40,
  },
  {
    day: 'Frie',
    run_duration: 30,
  },
  {
    day: 'Sate',
    run_duration: 45,
  },
  {
    day: 'Sune',
    run_duration: 30,
  },
  {
    day: 'Mons',
    run_duration: 30,
  },
  {
    day: 'Tues',
    run_duration: 60,
  },
  {
    day: 'Weds',
    run_duration: 120,
  },
  {
    day: 'Thus',
    run_duration: 40,
  },
  {
    day: 'Fris',
    run_duration: 30,
  },
  {
    day: 'Sats',
    run_duration: 45,
  },
  {
    day: 'Suns',
    run_duration: 30,
  },
];
class HistoChart extends Component {
  state = {
    openInfo: false,
    anchorEl: null,
  };

  render() {
    const data =
      this.props.dailyUsage?.length > 0 ? this.props.dailyUsage : demoData;
    const mode = this.props.mode;

    const scale = {
      power_consumption_cost: {
        type: 'linear',
        min: 0,
      },
      power_consumption: {
        type: 'linear',
        min: 0,
      },
      cost_saving: {
        type: 'linear',
        min: 0,
      },
    };

    return (
      <div className="BAR_CHART_MAIN_CONTAINER">
        {this.props.dailyUsage?.length > 0 ? null : (
          <div className="ANALYTICS_CHART_WATER_MARK_CONTAINER">
            <p>No data available yet ...</p>
          </div>
        )}
        <Chart
          height="inherit"
          width="inherit"
          autoFit
          data={data}
          padding="auto"
          scale={scale}
        >
          {/* <Interval
            position={`date*${this.props.type}`}
            // style={{ lineWidth: 2, stroke: getTheme().colors10[0] }}
          /> */}
          <Axis name={`${this.props.type}`} visible={false} />
          <Axis
            name="date"
            label={{
              rotate: 0.5,
              autoRotate: true,
              style: { textAlign: 'start', fontSize: 8 },
            }}
          ></Axis>
          <Interval
            shape="smooth"
            color="day"
            position={`date*${this.props.type}`}
            style={{ lineWidth: 2, stroke: 0 }}
          ></Interval>
          <Legend
            itemWidth={70}
            itemHeight={25}
            itemStates={{
              active: {
                nameStyle: {
                  opacity: 0.8,
                  fill: mode === 'light' ? '#000' : '#fff',
                },
              },
              unchecked: {
                nameStyle: {
                  fill: mode === 'light' ? '#333' : '#ccc',
                },
                markerStyle: {
                  opacity: 0.2,
                },
              },
              inactive: {
                nameStyle: {
                  fill: mode === 'light' ? '#000' : '#fff',
                },
                markerStyle: {
                  opacity: 0.2,
                },
              },
            }}
            pageNavigator={{
              marker: {
                style: {
                  inactiveFill: '#bbb',
                  inactiveOpacity: 0.45,
                  fill: mode === 'light' ? '#000' : '#fff',
                  opacity: 0.8,
                  size: 20,
                },
              },
              text: {
                style: {
                  fill: mode === 'light' ? '#333' : '#ccc',
                  fontSize: 10,
                },
              },
            }}
            offsetY={10}
          />
          <Tooltip shared />
        </Chart>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    dailyUsage: state.geyserUsage.usage,
    mode: state.users.theme?.mode,
  };
};

export default connect(mapStateToProps, null)(HistoChart);
