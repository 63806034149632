import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './styles/controller-graphs.css';
import PieChart from './pie-chart/PieChart';
// import LineChart from './line-chart/LineChart';
// import HistogramChart from './histogram-chart/HistogramChart';
import MonthlyUsageChart from './monthly-usage-chart/MonthlyUsageChart';
import UsageChart from './usage-chart/UsageChart';
import { getGeyserUsageByDate } from '../../store/actions/geyserUsage';
import { getGeyserMonthlyUsage } from '../../store/actions/insights';

class ControllerGraphs extends Component {
  componentDidMount() {
    const { endDate, startDate } = this.calculateLast7Days();
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    this.props.getUsageByDate(token, IMEI, startDate, endDate);
    this.props.getMonthlyUsage(token, IMEI);
  }

  calculateLast7Days = () => {
    var result = [];
    for (var i = 0; i < 7; i++) {
      var d = new Date();
      if (i === 0) {
        d.setDate(d.getDate() + i);
        result.push(moment(d.setHours(23, 59, 59, 99)).format());
      } else {
        d.setDate(d.getDate() - i);
        result.push(moment(d.setHours(0, 0, 0, 0)).format());
      }
    }

    return { endDate: result[0], startDate: result[result.length - 1] };
  };

  render() {
    return (
      <div className="DASHBOARD_GRAPHS_WRAPPER_CONTAINER" id={this.props.mode}>
        <div className="DASHBOARD_GRAPHS_CONTAINER">
          <div className="DASHBOARD_GRAPH_CONTAINER">
            <PieChart
              data={this.props.usage ? this.props.usage : []}
              describtion="This graph represent the daily usage duration of the geyser in (Min) during the last 7 days."
              mode={this.props.mode}
            ></PieChart>
          </div>
          <div
            className="DASHBOARD_GRAPH_CONTAINER"
            style={{ marginLeft: '20px' }}
          >
            <UsageChart
              describtion="This graph represent the cost of daily usage and saving in Rands during the last 7 days."
              data={this.props.usage ? this.props.usage : []}
              mode={this.props.mode}
            ></UsageChart>
            {/* <HistogramChart
              describtion="This graph represent the cost of daily usage in Rands during the last 7 days."
              data={this.props.usage ? this.props.usage : []}
              mode={this.props.mode}
            ></HistogramChart> */}
          </div>
          <div
            className="DASHBOARD_GRAPH_CONTAINER"
            style={{ marginLeft: '20px' }}
          >
            {/* <LineChart
              describtion="This graph represent the monthly power consumption of the geyser in relation to the runing duration"
              data={this.props.monthlyUsage ? this.props.monthlyUsage : []}
              mode={this.props.mode}
            ></LineChart> */}
            <MonthlyUsageChart
              describtion="This graph represent the monthly power consumption of the geyser in relation to the runing duration"
              data={this.props.monthlyUsage ? this.props.monthlyUsage : []}
              mode={this.props.mode}
            ></MonthlyUsageChart>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    controller: state.controller.controller,
    controllers: state.controller.controllers,
    parameters: state.controllerParameters.controllerParameters,
    usage: state.geyserUsage.usage,
    monthlyUsage: state.geyserAnalytics.monthlyUsage,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsageByDate: (token, IMEI, startDate, endDate) =>
      dispatch(getGeyserUsageByDate(token, IMEI, startDate, endDate)),
    getMonthlyUsage: (token, IMEI) =>
      dispatch(getGeyserMonthlyUsage(token, IMEI)),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ControllerGraphs);
