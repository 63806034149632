import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  controllerAlert: null,
  controllerAlerts: null,
  unreadAlerts: null,
  alertsCount: 0,
};

const getControllerAlertStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getControllerAlertSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    controllerAlert: action.payload,
  });
};

const getControllerAlertsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    controllerAlerts: action.payload,
  });
};

const getControllerUnreadAlertsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    unreadAlerts: action.payload,
  });
};

const getAlertsCountSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    alertsCount: action.payload,
  });
};

const getControllerAlertFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const controllerAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTROLLER_ALERT_START:
      return getControllerAlertStart(state, action);
    case actionTypes.GET_CONTROLLER_ALERT_SUCCESS:
      return getControllerAlertSuccess(state, action);
    case actionTypes.GET_CONTROLLER_ALERTS_SUCCESS:
      return getControllerAlertsSuccess(state, action);
    case actionTypes.GET_CONTROLLER_UNREAD_ALERTS_SUCCESS:
      return getControllerUnreadAlertsSuccess(state, action);
    case actionTypes.GET_ALERTS_COUNT_SUCCESS:
      return getAlertsCountSuccess(state, action);
    case actionTypes.GET_CONTROLLER_ALERT_FAILD:
      return getControllerAlertFaild(state, action);
    default:
      return state;
  }
};

export default controllerAlertReducer;
