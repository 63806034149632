import React, { Component } from 'react';
import './styles/general.css';
import ControlGraphs from './control-graphs/ControlGraphs';
// import DashboardAlert from '../alerts/dashboard-alert/DashboardAlert';
// import LineChart from './control-graphs/line-chart/LineChart';
import PieChart from '../graphs/pie-chart/PieChart';

export default class General extends Component {
  render() {
    return (
      <div className="CONTROL_DASHBOARD_GENERAL_MAIN_CONTAINER">
        <div className="CONTROL_DASHBOARD_GENERAL_GRAPHS_MAIN_CONTAINER">
          <ControlGraphs></ControlGraphs>
        </div>
        {/* <div className="CONTROL_DASHBOARD_GENERAL_LINE_CHART_MAIN_CONTAINER">
          <div className="CONTROL_DASHBOARD_GENERAL_LINE_CHART_CONTAINER">
            <LineChart describtion="This graph represent the monthly power consumption of the geyser in relation to the working time"></LineChart>
          </div>
        </div> */}
        {/* <div className="CONTROL_DASHBOARD_GENERAL_PEAK_GRAPH_MAIN_CONTAINER">
          <div className="CONTROL_DASHBOARD_GENERAL_PEAK_GRAPH_CONTAINER"></div>
        </div> */}

        <div className="CONTROL_DASHBOARD_GENERAL_DEVICES_MAIN_CONTAINER">
          <div className="CONTROL_DASHBOARD_GENERAL_DEVICES_CONTAINER">
            <div className="CONTROL_DASHBOARD_GENERAL_DEVICES_DES_CONTAINER">
              <h3>Devices</h3>
              <span>
                You can monitor the performance data of any controller by going
                to the Devices tab and search for the target controller using
                one of the available filters.
              </span>
            </div>
          </div>
        </div>
        <div className="CONTROL_DASHBOARD_GENERAL_ALERTS_MAIN_CONTAINER">
          <div className="CONTROL_DASHBOARD_GENERAL_ALERTS_CONTAINER">
            <div className="CONTROL_DASHBOARD_GENERAL_ALERTS_DES_CONTAINER">
              <h3>Alerts</h3>
              <p>
                You can view all the alerts generated by the system by going to
                the Alerts tab, check the alert details and take the necessary
                action.
              </p>
            </div>
            <div className="CONTROL_DASHBOARD_GENERAL_ALERTS_GRAPH_CONTAINER">
              <PieChart
                data={[
                  {
                    type: 'Warning',
                    value: 2,
                  },
                  {
                    type: 'Error',
                    value: 10,
                  },
                ]}
                describtion="This graph represent the number of Alerts with their status."
              ></PieChart>
            </div>
          </div>
        </div>
        <div className="CONTROL_DASHBOARD_GENERAL_SUPPORT_MAIN_CONTAINER">
          <div className="CONTROL_DASHBOARD_GENERAL_SUPPORT_CONTAINER">
            <div className="CONTROL_DASHBOARD_GENERAL_SUPPORT_DES_CONTAINER">
              <h3>Support</h3>
              <p>
                Contact the technical support to get assistance with any issue
                regarding controller performance, errors or unwanted behavior.
                Go to support tab to open a new ticket or view previous tickets.
              </p>
            </div>
            <div className="CONTROL_DASHBOARD_GENERAL_SUPPORT_GRAPH_CONTAINER">
              <PieChart
                data={[
                  {
                    type: 'Open',
                    value: 2,
                  },
                  {
                    type: 'Closed',
                    value: 10,
                  },
                  {
                    type: 'Pending',
                    value: 1,
                  },
                ]}
                describtion="This graph represent the number of open tickets and their status."
              ></PieChart>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
