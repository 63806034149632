import React, { Component } from 'react';
import './user-guide-item.css';

export default class UserGuideItem extends Component {
  render() {
    return (
      <div className="USER_GUIDE_ITEM">
        <div className="USER_GUIDE_ITEM_HEADER">
          <div className="USER_GUIDE_ITEM_HEADER_TITLE">
            <h2>{this.props.title}</h2>
          </div>
          <div className="USER_GUIDE_ITEM_HEADER_PIC">
            <div className="USER_GUIDE_ITEM_HEADER_PIC_ITEM">
              {this.props.pic}
            </div>
          </div>
        </div>
        <div className="USER_GUIDE_ITEM_DES">
          <p>{this.props.description}</p>
        </div>
      </div>
    );
  }
}
