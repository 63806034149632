import React, { Component } from 'react';
import './styles/previous-tickets.css';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PieChart from '../graphs/pie-chart/PieChart';

export default class PreviousTickets extends Component {
  state = {
    ticketID: 0,
    preTicketExpanded: true,
  };

  handleClick = (ticketID) => {
    if (this.state.ticketID === ticketID) {
      this.setState({ ticketID: 0 });
    } else {
      this.setState({ ticketID: ticketID });
    }
  };

  render() {
    const tickets = this.props.tickets;
    return (
      <div className="SUPPORT_PREVIOUS_TICKETS_CONTAINER" id={this.props.mode}>
        <Accordion
          expanded={this.state.preTicketExpanded}
          sx={{
            width: '100%',
            borderRadius: '20px !important',
            boxShadow: 'none',
            bgcolor: 'inherit',
            color: 'inherit',
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                onClick={() =>
                  this.setState({
                    preTicketExpanded: !this.state.preTicketExpanded,
                  })
                }
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color="inherit">Previous Tickets</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="SUPPORT_PREVIOUS_TICKETS_CONTENT">
              <div className="SUPPORT_PREVIOUS_TICKETS_LIST">
                <List
                  sx={{
                    width: '100%',
                    // maxWidth: 360,
                    bgcolor: 'inherit',
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  // subheader={
                  //   <ListSubheader component="div" id="nested-list-subheader">
                  //     Nested List Items
                  //   </ListSubheader>
                  //}
                >
                  {tickets.map((ticket, index) => (
                    <div key={index}>
                      <ListItemButton
                        onClick={() => this.handleClick(ticket.id)}
                      >
                        <ListItemIcon>
                          <ConfirmationNumberOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={ticket.title} />
                        {this.state.ticketID === index + 1 ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={this.state.ticketID === index + 1 ? true : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        <div className="SUPPORT_PREVIOUS_TICKET_DATA">
                          <Chip
                            label={ticket.status}
                            sx={{
                              bgcolor: '#5AD8A6',
                            }}
                          />
                          <Chip
                            label={moment(ticket.created_at).calendar()}
                            variant="outlined"
                            sx={{
                              marginLeft: 5,
                              color: 'inherit',
                            }}
                          />
                          <Chip
                            label="View Ticket"
                            variant="outlined"
                            sx={{
                              marginLeft: 5,
                              color: 'inherit',
                            }}
                            onClick={() => this.props.previewTicket(ticket.id)}
                            onDelete={() => this.props.previewTicket(ticket.id)}
                            deleteIcon={
                              <RemoveRedEyeOutlinedIcon></RemoveRedEyeOutlinedIcon>
                            }
                          />
                        </div>
                      </Collapse>
                    </div>
                  ))}
                </List>
              </div>
              <div
                className="SUPPORT_PREVIOUS_TICKETS_GRAPH"
                style={{ display: 'none' }}
              >
                <PieChart
                  data={[
                    {
                      type: 'Open',
                      value: 2,
                    },
                    {
                      type: 'Closed',
                      value: 10,
                    },
                    {
                      type: 'Pending',
                      value: 1,
                    },
                  ]}
                  describtion="This graph represent the number of open tickets and their status."
                ></PieChart>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}
