import React from 'react';
import './card.css';
import Button from '@mui/material/Button';
import BarChart from '../../charts/bar-chart/BarChart';

function Card(props) {
  return (
    <div className="ANALYTICS_CAROUSEL_CARD">
      <h2>{props.title}</h2>
      <BarChart mode={props.mode} type={props.type}></BarChart>
      <p>{props.description}</p>
      <div>
        <Button onClick={props.goToSlide}>Next</Button>
      </div>
    </div>
  );
}

export default Card;
