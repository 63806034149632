import React, { Component } from 'react';
import './styles/control-settings.css';

export default class ControlSettings extends Component {
  render() {
    return (
      <div className="CONTROL_DASHBOARD_CONTENT_SETTINGS_CONTAINER">
        ControlSettings
      </div>
    );
  }
}
