import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './styles/run-schedule.css';
import SimpleTimeSlot from './simple-time-slot/SimpleTimeSlot';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { timeConverter } from '../../../../tools/time';

import {
  getTimeSlotByDay,
  postTimeSlotData,
} from '../../../../store/actions/runSechedule';

class RunSchedule extends Component {
  state = {
    value: '1',
    days: {
      Monday: '1',
      Tuesday: '2',
      Wednesday: '3',
      Thursday: '4',
      Friday: '5',
      Saturday: '6',
      Sunday: '7',
    },
  };

  componentDidMount() {
    const dayName = this.getToday();
    this.props.setDay(dayName);
    this.props.getTimeSlots(
      this.props.token,
      dayName,
      this.props.activeController.serial_number
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeController !== prevProps.activeController &&
      this.props.activeController
    ) {
      const dayName = this.getToday();
      this.props.getTimeSlots(
        this.props.token,
        dayName,
        this.props.activeController.serial_number
      );
    }
  }

  handleChange = (e, newValue) => {
    this.setState({ value: newValue });
  };

  getToday = () => {
    const date = new Date();
    const dayName = timeConverter(date).format('dddd');
    const { days } = this.state;
    this.setState({ value: days[dayName] });
    return dayName;
  };

  getTimeSlots = (day) => {
    const token = this.props.token;
    const IMEI = this.props.activeController.serial_number;

    this.props.getTimeSlots(token, day, IMEI);
    this.props.setDay(day);
  };

  handleCreateTimeSlot = (day) => {
    const token = this.props.token;
    const IMEI = this.props.activeController.serial_number;
    const startTime = moment(new Date()).format();
    const endTime = moment(new Date()).format();
    this.props.createTimeSlot(token, IMEI, day, startTime, endTime);
  };

  render() {
    return (
      <div className="DASHBOARD_CONTROLLER_CONFIGS_SCHEDULE_CONTAINER">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={this.state.value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <TabList
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="lab API tabs example"
              >
                <Tab
                  sx={
                    this.props.mode === 'light'
                      ? { color: '#162447' }
                      : { color: '#BBE1FA' }
                  }
                  label="Monday"
                  value="1"
                  onClick={() => this.getTimeSlots('Monday')}
                />
                <Tab
                  sx={
                    this.props.mode === 'light'
                      ? { color: '#162447' }
                      : { color: '#BBE1FA' }
                  }
                  label="Tuesday"
                  value="2"
                  onClick={() => this.getTimeSlots('Tuesday')}
                />
                <Tab
                  sx={
                    this.props.mode === 'light'
                      ? { color: '#162447' }
                      : { color: '#BBE1FA' }
                  }
                  label="Wednesday"
                  value="3"
                  onClick={() => this.getTimeSlots('Wednesday')}
                />
                <Tab
                  sx={
                    this.props.mode === 'light'
                      ? { color: '#162447' }
                      : { color: '#BBE1FA' }
                  }
                  label="Thursday"
                  value="4"
                  onClick={() => this.getTimeSlots('Thursday')}
                />
                <Tab
                  sx={
                    this.props.mode === 'light'
                      ? { color: '#162447' }
                      : { color: '#BBE1FA' }
                  }
                  label="Friday"
                  value="5"
                  onClick={() => this.getTimeSlots('Friday')}
                />
                <Tab
                  sx={
                    this.props.mode === 'light'
                      ? { color: '#162447' }
                      : { color: '#BBE1FA' }
                  }
                  label="Saturday"
                  value="6"
                  onClick={() => this.getTimeSlots('Saturday')}
                />
                <Tab
                  sx={
                    this.props.mode === 'light'
                      ? { color: '#162447' }
                      : { color: '#BBE1FA' }
                  }
                  label="Sunday"
                  value="7"
                  onClick={() => this.getTimeSlots('Sunday')}
                />
              </TabList>
            </Box>

            <TabPanel value="1" sx={{ overflow: 'auto' }}>
              <div className="RUN_SCHEDULE_TAB_PANEL_CONTAINER">
                {this.props.timeSlots && this.props.timeSlots.length > 0
                  ? this.props.timeSlots.map((slot, index) => (
                      <SimpleTimeSlot
                        key={`slot-${slot.start_time}`}
                        timeSlot={slot}
                      ></SimpleTimeSlot>
                    ))
                  : null}
              </div>
            </TabPanel>
            <TabPanel value="2" sx={{ overflow: 'auto' }}>
              <div className="RUN_SCHEDULE_TAB_PANEL_CONTAINER">
                {this.props.timeSlots && this.props.timeSlots.length > 0
                  ? this.props.timeSlots.map((slot, index) => (
                      <SimpleTimeSlot
                        key={slot.created_at}
                        timeSlot={slot}
                      ></SimpleTimeSlot>
                    ))
                  : null}
              </div>
            </TabPanel>
            <TabPanel value="3" sx={{ overflow: 'auto' }}>
              <div className="RUN_SCHEDULE_TAB_PANEL_CONTAINER">
                {this.props.timeSlots && this.props.timeSlots.length > 0
                  ? this.props.timeSlots.map((slot, index) => (
                      <SimpleTimeSlot
                        key={slot.created_at}
                        timeSlot={slot}
                      ></SimpleTimeSlot>
                    ))
                  : null}
              </div>
            </TabPanel>
            <TabPanel value="4" sx={{ overflow: 'auto' }}>
              <div className="RUN_SCHEDULE_TAB_PANEL_CONTAINER">
                {this.props.timeSlots && this.props.timeSlots.length > 0
                  ? this.props.timeSlots.map((slot, index) => (
                      <SimpleTimeSlot
                        key={slot.created_at}
                        timeSlot={slot}
                      ></SimpleTimeSlot>
                    ))
                  : null}
              </div>
            </TabPanel>
            <TabPanel value="5" sx={{ overflow: 'auto' }}>
              <div className="RUN_SCHEDULE_TAB_PANEL_CONTAINER">
                {this.props.timeSlots && this.props.timeSlots.length > 0
                  ? this.props.timeSlots.map((slot, index) => (
                      <SimpleTimeSlot
                        key={slot.created_at}
                        timeSlot={slot}
                      ></SimpleTimeSlot>
                    ))
                  : null}
              </div>
            </TabPanel>
            <TabPanel value="6" sx={{ overflow: 'auto' }}>
              <div className="RUN_SCHEDULE_TAB_PANEL_CONTAINER">
                {this.props.timeSlots && this.props.timeSlots.length > 0
                  ? this.props.timeSlots.map((slot, index) => (
                      <SimpleTimeSlot
                        key={slot.created_at}
                        timeSlot={slot}
                      ></SimpleTimeSlot>
                    ))
                  : null}
              </div>
            </TabPanel>
            <TabPanel value="7" sx={{ overflow: 'auto' }}>
              <div className="RUN_SCHEDULE_TAB_PANEL_CONTAINER">
                {this.props.timeSlots && this.props.timeSlots.length > 0
                  ? this.props.timeSlots.map((slot, index) => (
                      <SimpleTimeSlot
                        key={slot.created_at}
                        timeSlot={slot}
                      ></SimpleTimeSlot>
                    ))
                  : null}
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    timeSlotLoading: state.timeSlot.loading,
    timeSlots: state.timeSlot.timeSlots,
    activeController: state.controller.controller,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTimeSlots: (token, day, IMEI) =>
      dispatch(getTimeSlotByDay(token, day, IMEI)),
    createTimeSlot: (token, IMEI, day, start_time, end_time) =>
      dispatch(postTimeSlotData(token, IMEI, day, start_time, end_time)),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(RunSchedule);
