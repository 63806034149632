import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  termsAndConditions: null,
};

const getTermsAndConditionsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getTermsAndConditionsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    termsAndConditions: action.payload,
  });
};

const getTermsAndConditionsFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const termsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TERMS_AND_CONDITIONS_START:
      return getTermsAndConditionsStart(state, action);
    case actionTypes.GET_TERMS_AND_CONDITIONS_SUCCESS:
      return getTermsAndConditionsSuccess(state, action);
    case actionTypes.GET_TERMS_AND_CONDITIONS_FAILD:
      return getTermsAndConditionsFaild(state, action);
    default:
      return state;
  }
};

export default termsReducer;
