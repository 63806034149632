import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getNotificationStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_START,
  };
};

export const getNotificationsSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const getUnreadNotificationsSuccess = (data) => {
  return {
    type: actionTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const getNotificationSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const getNotificationsCountSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_COUNT_SUCCESS,
    payload: data,
  };
};

export const getNotificationFaild = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_FAILD,
  };
};

export const getNotifications = (token) => {
  return (dispatch) => {
    dispatch(getNotificationStart());

    axios
      .get(`${HOST_URL}/notifications/notification/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getNotificationsSuccess(response.data)))
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const getNotificationsCount = (token) => {
  return (dispatch) => {
    dispatch(getNotificationStart());

    axios
      .get(`${HOST_URL}/notifications/notification/count_1/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getNotificationsCountSuccess(response.data)))
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const getLastNNotifications = (token, N) => {
  return (dispatch) => {
    dispatch(getNotificationStart());
    axios
      .get(`${HOST_URL}/notifications/notification/n_${N}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getNotificationsSuccess(response.data)))
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const getNotificationsByStatus = (token, status) => {
  return (dispatch) => {
    dispatch(getNotificationStart());

    axios
      .get(`${HOST_URL}/notifications/notification/status_${status}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) =>
        dispatch(getUnreadNotificationsSuccess(response.data))
      )
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const getNotification = (token, id) => {
  return (dispatch) => {
    dispatch(getNotificationStart());

    axios
      .get(`${HOST_URL}/notifications/notification/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getNotificationSuccess(response.data)))
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const updateNotification = (token, read, id) => {
  return (dispatch) => {
    dispatch(getNotificationStart());
    let fd = new FormData();
    fd.append('read', read);

    axios
      .patch(`${HOST_URL}/notifications/notification/${id}/`, fd, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        dispatch(getNotificationSuccess(response.data));
        dispatch(getNotifications(token));
        dispatch(getNotificationsByStatus(token, false));
      })
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const updateAllNotificationsStatus = (token, id, IMEI, read) => {
  return (dispatch) => {
    dispatch(getNotificationStart());
    let fd = new FormData();
    fd.append('IMEI', IMEI);
    fd.append('read', read);

    axios
      .put(`${HOST_URL}/notifications/notification/${id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getNotificationsSuccess(response.data));
        dispatch(getNotificationsByStatus(token, false));
      })
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const deleteAllNotifcations = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getNotificationStart());
    axios
      .delete(`${HOST_URL}/notifications/notification/${IMEI}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getNotificationsSuccess(response.data));
        dispatch(getNotificationsByStatus(token, false));
      })
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};
