import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  usage: null,
};

const getGeyserUsageStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getGeyserUsageSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    usage: action.payload,
  });
};

const getGeyserUsageFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const geyserUsageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GEYSER_USAGE_START:
      return getGeyserUsageStart(state, action);
    case actionTypes.GET_GEYSER_USAGE_SUCCESS:
      return getGeyserUsageSuccess(state, action);
    case actionTypes.GET_GEYSER_USAGE_FAILD:
      return getGeyserUsageFaild(state, action);
    default:
      return state;
  }
};

export default geyserUsageReducer;
