import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/notifications.css';
import { dateTimeCalender } from '../../tools/time';
import Button from '@mui/material/Button';
import {
  getNotifications,
  updateNotification,
  updateAllNotificationsStatus,
  deleteAllNotifcations,
  getNotificationsCount,
  getLastNNotifications,
} from '../../store/actions/notifications';
import { CircularProgress } from '@mui/material';

class Notifications extends Component {
  state = {
    notificationsQ: 10,
  };
  componentDidMount() {
    const token = this.props.token;
    const { notificationsQ } = this.state;
    this.props.getNNotifications(token, notificationsQ);
    this.props.getNotificationsCount(token);
  }

  getMoreNotifications = () => {
    const token = this.props.token;
    const { notificationsQ } = this.state;
    this.props.getNNotifications(token, notificationsQ + 10);
    this.setState({ notificationsQ: notificationsQ + 10 });
  };

  updateNotificationStatus = (notification) => {
    const token = this.props.token;
    const id = notification.id;
    const readStatus = notification.read;

    if (readStatus === false) {
      this.props.updateNotification(token, true, id);
    }
  };

  handleMarkNotificationsAsRead = () => {
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    const id = this.props.notifications[0]?.id;
    if (IMEI) {
      this.props.updateNotificationsStatus(token, id, IMEI, true);
    }
  };

  handleDeleteAllNotifications = () => {
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    if (IMEI) {
      this.props.deleteNotifications(token, IMEI);
    }
  };

  render() {
    const notifications = this.props.notifications;
    return (
      <div className="NOTIFICATIONS_MAIN_CONTAINER" id={this.props.mode}>
        {this.props.loadingNotifications ? (
          <CircularProgress
            color="warning"
            size={40}
            sx={{ marginTop: '2em' }}
          ></CircularProgress>
        ) : notifications && notifications.length === 0 ? (
          <div className="NO_NOTIFICATION_CONTAINER">
            <h2>No Notifications</h2>
          </div>
        ) : (
          <div className="ALERT_ACTIONS_CONTAINER">
            <Button
              variant="outlined"
              onClick={this.handleMarkNotificationsAsRead}
            >
              Mark All as read
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={this.handleDeleteAllNotifications}
            >
              Delete all
            </Button>
          </div>
        )}
        {notifications && notifications.length > 0
          ? notifications.map((notification, index) => (
              <div
                className="NOTIFICATION_CONTAINER"
                key={notification.created_at}
              >
                <div className="NOTIFICATION_HEADER">
                  <h3>
                    {notification.generated_by.controller_meta_data?.lable}
                  </h3>
                  {!notification.read ? (
                    <Button
                      size="small"
                      onClick={() =>
                        this.updateNotificationStatus(notification)
                      }
                    >
                      mark as read
                    </Button>
                  ) : (
                    <Button size="small" color="success">
                      read
                    </Button>
                  )}
                </div>
                <div className="NOTIFICATION_BODY">
                  <span>{notification.title}</span>
                  <p>{notification.description}</p>
                </div>
                <div className="NOTIFICATION_FOOTER">
                  <span>{dateTimeCalender(notification.created_at)}</span>
                </div>
              </div>
            ))
          : null}
        {notifications &&
        notifications.length < this.props.notificationsCount ? (
          <div className="ALERT_ACTIONS_CONTAINER">
            {this.props.loadingNotifications ? (
              <CircularProgress
                color="warning"
                size={40}
                sx={{ marginTop: '2em' }}
              ></CircularProgress>
            ) : (
              <Button onClick={this.getMoreNotifications}>Load more</Button>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    notifications: state.notifications.notifications,
    loadingNotifications: state.notifications.loading,
    notificationsCount: state.notifications.notificationsCount,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (token) => dispatch(getNotifications(token)),
    getNNotifications: (token, n) => dispatch(getLastNNotifications(token, n)),
    getNotificationsCount: (token) => dispatch(getNotificationsCount(token)),
    updateNotification: (token, read, id) =>
      dispatch(updateNotification(token, read, id)),
    updateNotificationsStatus: (token, id, IMEI, read) =>
      dispatch(updateAllNotificationsStatus(token, id, IMEI, read)),
    deleteNotifications: (token, IMEI) =>
      dispatch(deleteAllNotifcations(token, IMEI)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
