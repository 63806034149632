import moment from 'moment-timezone';

export const dateTimeCalender = (dateTime) => {
  const newTime = moment(dateTime).tz('Africa/Johannesburg').calendar();
  return newTime;
};

export const timeConverter = (dateTime) => {
  const newTime = moment(dateTime).tz('Africa/Johannesburg');
  return newTime;
};
