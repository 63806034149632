import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { CircularProgress } from '@mui/material';
import './styles/simple-time-slot.css';
import {
  activateTimeSlot,
  deactivateTimeSlot,
  getTimeSlotByDay,
} from '../../../../../store/actions/runSechedule';

class SimpleTimeSlot extends Component {
  state = {
    startTime: '',
    endTime: '',
  };
  componentDidMount() {
    const startTime = this.props.timeSlot.start_time
      .split('T')[1]
      .split('+')[0]
      .slice(0, -3);
    const endTime = this.props.timeSlot.end_time
      .split('T')[1]
      .split('+')[0]
      .slice(0, -3);

    this.setState({
      startTime: startTime,
      endTime: endTime,
    });
  }

  updateTimeSlotStatus = (status) => {
    const token = this.props.token;
    const timeSlot_id = this.props.timeSlot.id;
    const day = this.props.timeSlot.day;
    const IMEI = this.props.activeController?.serial_number;
    if (status) {
      this.props.deactivateTimeSlot(token, IMEI, day, timeSlot_id);
    } else {
      this.props.activateTimeSlot(token, IMEI, day, timeSlot_id);
    }
  };

  render() {
    return (
      <div
        className="DASHBOARD_SIMPLE_TIME_SLOT_CONTAINER"
        id={this.props.mode}
      >
        <div
          className="DASHBOARD_SIMPLE_TIME_SLOT_TIME"
          style={
            this.props.timeSlot?.enabled ? { backgroundColor: '#fa7d09' } : {}
          }
        >
          <span
            style={this.props.timeSlot?.enabled ? { color: '#202040' } : {}}
          >
            {this.state.startTime}
            &nbsp;-&nbsp;
            {this.state.endTime}
          </span>
        </div>
        <div
          className="DASHBOARD_SIMPLE_TIME_SLOT_BODY"
          onClick={() =>
            this.updateTimeSlotStatus(this.props.timeSlot?.enabled)
          }
        >
          {this.props.timeSlot?.enabled ? (
            <p>Tap to deactivate</p>
          ) : (
            <p>Tap to activate</p>
          )}
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    activeController: state.controller.controller,
    timeSlotLoading: state.timeSlot.loading,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTimeSlots: (token, day, IMEI) =>
      dispatch(getTimeSlotByDay(token, day, IMEI)),
    activateTimeSlot: (token, IMEI, day, timeSlot_id) =>
      dispatch(activateTimeSlot(token, IMEI, day, timeSlot_id)),
    deactivateTimeSlot: (token, IMEI, day, timeSlot_id) =>
      dispatch(deactivateTimeSlot(token, IMEI, day, timeSlot_id)),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(SimpleTimeSlot);
