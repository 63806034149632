import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  alterLoading: false,
  activeTimeSlot: null,
  timeSlot: null,
  timeSlots: [],
};

const getTimeSlotStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getAlterTimeSlotStart = (state, action) => {
  return updateObject(state, {
    error: null,
    alterLoading: true,
  });
};

const getTimeSlotSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    timeSlot: action.payload,
  });
};

const getActiveTimeSlotSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    activeTimeSlot: action.payload,
  });
};

const getTimeSlotsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    alterLoading: false,
    timeSlots: action.payload,
  });
};

const getTimeSlotFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    alterLoading: false,
    loading: false,
  });
};

const timeSlotReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TIME_SLOT_START:
      return getTimeSlotStart(state, action);
    case actionTypes.GET_ALTER_TIME_SLOT_START:
      return getAlterTimeSlotStart(state, action);
    case actionTypes.GET_TIME_SLOT_SUCCESS:
      return getTimeSlotSuccess(state, action);
    case actionTypes.GET_ACTIVE_TIME_SLOT_SUCCESS:
      return getActiveTimeSlotSuccess(state, action);
    case actionTypes.GET_TIME_SLOTS_SUCCESS:
      return getTimeSlotsSuccess(state, action);
    case actionTypes.GET_TIME_SLOT_FAILD:
      return getTimeSlotFaild(state, action);
    default:
      return state;
  }
};

export default timeSlotReducer;
