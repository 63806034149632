import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getControllerAlertStart = () => {
  return {
    type: actionTypes.GET_CONTROLLER_ALERT_START,
  };
};

export const getControllerAlertSuccess = (data) => {
  return {
    type: actionTypes.GET_CONTROLLER_ALERT_SUCCESS,
    payload: data,
  };
};

export const getControllerAlertsSuccess = (data) => {
  return {
    type: actionTypes.GET_CONTROLLER_ALERTS_SUCCESS,
    payload: data,
  };
};

export const getAlertsCountSuccess = (data) => {
  return {
    type: actionTypes.GET_ALERTS_COUNT_SUCCESS,
    payload: data,
  };
};

export const getControllerUnreadAlertsSuccess = (data) => {
  return {
    type: actionTypes.GET_CONTROLLER_UNREAD_ALERTS_SUCCESS,
    payload: data,
  };
};

export const getControllerAlertFaild = () => {
  return {
    type: actionTypes.GET_CONTROLLER_ALERT_FAILD,
  };
};

export const getControllerAlerts = (token) => {
  return (dispatch) => {
    dispatch(getControllerAlertStart());
    console.log('getting all ControllerAlerts');
    axios
      .get(`${HOST_URL}/alerts/controller-alert/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerAlertsSuccess(response.data)))
      .catch((error) => dispatch(getControllerAlertFaild(error)));
  };
};

export const getAlertsCount = (token) => {
  return (dispatch) => {
    dispatch(getControllerAlertStart());
    axios
      .get(`${HOST_URL}/alerts/controller-alert/count_1/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getAlertsCountSuccess(response.data)))
      .catch((error) => dispatch(getControllerAlertFaild(error)));
  };
};

export const getLastNAlerts = (token, N) => {
  return (dispatch) => {
    dispatch(getControllerAlertStart());
    axios
      .get(`${HOST_URL}/alerts/controller-alert/n_${N}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerAlertsSuccess(response.data)))
      .catch((error) => dispatch(getControllerAlertFaild(error)));
  };
};

export const getControllerAlert = (token, id) => {
  return (dispatch) => {
    dispatch(getControllerAlertStart());

    axios
      .get(`${HOST_URL}/alerts/controller-alert/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerAlertSuccess(response.data)))
      .catch((error) => dispatch(getControllerAlertFaild(error)));
  };
};

export const getControllerAlertByType = (token, type) => {
  return (dispatch) => {
    dispatch(getControllerAlertStart());

    axios
      .get(`${HOST_URL}/alerts/controller-alert/type_${type}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerAlertsSuccess(response.data)))
      .catch((error) => dispatch(getControllerAlertFaild(error)));
  };
};

export const getControllerAlertByStatus = (token, status) => {
  return (dispatch) => {
    dispatch(getControllerAlertStart());

    axios
      .get(`${HOST_URL}/alerts/controller-alert/status_${status}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) =>
        dispatch(getControllerUnreadAlertsSuccess(response.data))
      )
      .catch((error) => dispatch(getControllerAlertFaild(error)));
  };
};

export const postControllerAlertData = (token, type, content) => {
  return (dispatch) => {
    dispatch(getControllerAlertStart());
    let fd = new FormData();
    fd.append('type', type);
    fd.append('content', content);

    axios
      .post(`${HOST_URL}/alerts/controller-alert/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getControllerAlertSuccess(response.data));
      })
      .catch((error) => dispatch(getControllerAlertFaild(error)));
  };
};

export const updateControllerAlertStatus = (token, alert_id, read) => {
  return (dispatch) => {
    dispatch(getControllerAlertStart());
    let fd = new FormData();
    fd.append('read', read);

    axios
      .patch(`${HOST_URL}/alerts/controller-alert/${alert_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getControllerAlertSuccess(response.data));
        dispatch(getControllerAlerts(token));
        dispatch(getControllerAlertByStatus(token, false));
      })
      .catch((error) => dispatch(getControllerAlertFaild(error)));
  };
};

export const updateAllAlertsStatus = (token, id, IMEI, read) => {
  return (dispatch) => {
    dispatch(getControllerAlertStart());
    let fd = new FormData();
    fd.append('IMEI', IMEI);
    fd.append('read', read);

    axios
      .put(`${HOST_URL}/alerts/controller-alert/${id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getControllerAlertsSuccess(response.data));
        dispatch(getControllerAlertByStatus(token, false));
      })
      .catch((error) => dispatch(getControllerAlertFaild(error)));
  };
};

export const deleteAllAlerts = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getControllerAlertStart());
    axios
      .delete(`${HOST_URL}/alerts/controller-alert/${IMEI}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getControllerAlertsSuccess(response.data));
        dispatch(getControllerAlertByStatus(token, false));
      })
      .catch((error) => dispatch(getControllerAlertFaild(error)));
  };
};
