import React from 'react';
import './daily-usage-chart.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Chart, Tooltip, Legend, Point, Line, Interval } from 'bizcharts';

const demoData = [
  {
    day: 'Mon',
    run_duration: 30,
  },
  {
    day: 'Tue',
    run_duration: 60,
  },
  {
    day: 'Wed',
    run_duration: 120,
  },
  {
    day: 'Thu',
    run_duration: 40,
  },
  {
    day: 'Fri',
    run_duration: 30,
  },
  {
    day: 'Sat',
    run_duration: 45,
  },
  {
    day: 'Sun',
    run_duration: 30,
  },
];

export default class Doubleaxes extends React.Component {
  state = {
    openInfo: false,
    anchorEl: null,
  };

  handleOpenInfo = (event) => {
    this.setState({
      openInfo: !this.state.openInfo,
      anchorEl: event.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      openInfo: false,
    });
  };
  render() {
    const data = this.props.data?.length > 0 ? this.props.data : demoData;
    const describtion = this.props.describtion;
    const mode = this.props.mode;

    let chartIns = null;
    const scale = {
      power_consumption_cost: {
        min: 0,
        tickCount: 4,
        alias: 'Cost',
        type: 'linear-strict',
      },
      cost_saving: {
        min: 0,
        tickCount: 4,
        alias: 'Saving',
        type: 'linear-strict',
      },
    };
    const colors = ['#6394f9', '#FF4C29'];
    const id = this.state.openInfo ? 'simple-popover' : undefined;
    return (
      <div className="HISTOGRAM_CHART_MAIN_CONTAINER">
        <div className="HISTOGRAM_CHART_INFO_CONTAINER">
          <IconButton onClick={this.handleOpenInfo}>
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        </div>
        {this.props.data?.length > 0 ? null : (
          <div className="CHART_WATER_MARK_CONTAINER">
            <p>No data available yet ...</p>
          </div>
        )}

        <Popover
          id={id}
          open={this.state.openInfo}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 1, maxWidth: '250px', color: '#162447' }}>
            {describtion}
          </Typography>
        </Popover>

        <Chart
          scale={scale}
          autoFit
          height={220}
          width={300}
          data={data}
          onGetG2Instance={(chart) => {
            chartIns = chart;
          }}
        >
          <Legend
            custom={true}
            allowAllCanceled={true}
            itemWidth={70}
            itemHeight={25}
            itemStates={{
              active: {
                nameStyle: {
                  opacity: 0.8,
                  fill: mode === 'light' ? '#000' : '#fff',
                },
              },
              unchecked: {
                nameStyle: {
                  fill: mode === 'light' ? '#333' : '#ccc',
                },
                markerStyle: {
                  opacity: 0.2,
                },
              },
              inactive: {
                nameStyle: {
                  fill: mode === 'light' ? '#000' : '#fff',
                },
                markerStyle: {
                  opacity: 0.2,
                },
              },
            }}
            pageNavigator={{
              marker: {
                style: {
                  inactiveFill: '#bbb',
                  inactiveOpacity: 0.45,
                  fill: mode === 'light' ? '#000' : '#fff',
                  opacity: 0.8,
                  size: 16,
                },
              },
              text: {
                style: {
                  fill: mode === 'light' ? '#333' : '#ccc',
                  fontSize: 10,
                },
              },
            }}
            items={[
              {
                value: 'power_consumption_cost',
                name: 'Cost',
                marker: {
                  symbol: 'square',
                  style: { fill: colors[0] },
                },
              },
              {
                value: 'cost_saving',
                name: 'Saving',
                marker: {
                  symbol: 'hyphen',
                  style: {
                    stroke: colors[1],
                    r: 5,
                    lineWidth: 3,
                  },
                },
              },
            ]}
            onChange={(ev) => {
              const item = ev.item;
              const value = item.value;
              const checked = !item.unchecked;
              const geoms = chartIns.geometries;

              for (let i = 0; i < geoms.length; i++) {
                const geom = geoms[i];

                if (geom.getYScale().field === value) {
                  if (checked) {
                    geom.show();
                  } else {
                    geom.hide();
                  }
                }
              }
            }}
          />
          <Tooltip>
            {(title, items) => {
              return (
                <div className="TOOLTIP_CONTAINER">
                  <span>{items[0].data.day}</span>
                  <div className="TOOLTIP_ITEM">
                    <span>Cost:&nbsp;&nbsp;</span>
                    <p>R&nbsp;{items[0].data.power_consumption_cost}</p>
                  </div>
                  <div className="TOOLTIP_ITEM">
                    <span>Saving:&nbsp;&nbsp;</span>
                    <p> R&nbsp;{items[0].data.cost_saving}</p>
                  </div>
                </div>
              );
            }}
          </Tooltip>
          <Interval position="day*power_consumption_cost" color="day" />
          <Line
            position="day*cost_saving"
            color={colors[1]}
            size={3}
            shape="smooth"
          />
          <Point
            position="day*cost_saving"
            color={colors[1]}
            size={3}
            shape="circle"
          />
        </Chart>
      </div>
    );
  }
}
