import React, { Component } from 'react';
import GaugeChart from 'react-gauge-chart';

export default class TempMeter extends Component {
  render() {
    return (
      <div style={{ display: 'flex', width: '95%' }}>
        <GaugeChart
          id="gauge-chart5"
          // nrOfLevels={20}
          arcsLength={[0.3, 0.4, 0.3]}
          arcWidth={0.2}
          colors={['#5BE12C', '#F5CD19', '#EA4228']}
          percent={this.props.temperature / 100}
          arcPadding={0.06}
          formatTextValue={() => `${this.props.temperature} C`}
          textColor={this.props.textColor}
          needleColor="#719FB0"
          hideText={false}
        />
      </div>
    );
  }
}
