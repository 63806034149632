import React, { Component } from 'react';
import './styles/register-controller.css';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Lottie from 'lottie-react';
import controllerImage from '../../components/profile-controller/lottiefiles/19872-cpu-crypto-loading-animation.json';
import { assignControllerToUser } from '../../store/actions/controller';
import MetaDataForm from '../../components/controller-meta-data/MetaDataForm';

class RegisterController extends Component {
  state = {
    controllerCode: '',
    controllerCodeError: false,
    registrationResponseMessage: '',
    showMetaDataForm: false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.controller !== prevProps.controller &&
      this.props.controller
    ) {
      this.setState({
        controllerCodeError: false,
        registrationResponseMessage:
          'Congratulations! Regitration done successfully.',
      });
      setTimeout(() => this.setState({ showMetaDataForm: true }), 500);
    }
    if (this.props.error !== prevProps.error && this.props.error) {
      if (this.props.error.response.status === 403) {
        this.setState({
          controllerCodeError: true,
          registrationResponseMessage: 'This Controller is already registered.',
        });
      } else if (this.props.error.response.status === 404) {
        this.setState({
          controllerCodeError: true,
          registrationResponseMessage:
            "A controller with the given ID code wasn't found",
        });
      }
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleControllerRegistration = () => {
    const token = this.props.token;
    const controllerCode = this.state.controllerCode;

    this.props.registerController(token, controllerCode);
  };

  render() {
    return (
      <div className="REGISTER_CONTROLLER_MAIN_CONTAINER">
        {!this.state.showMetaDataForm ? (
          <div className="REGISTER_CONTROLLER_FORM_CONTAINER">
            <div className="REGISTER_CONTROLLER_ICON_CONTAINER">
              <Lottie
                animationData={controllerImage}
                loop={true}
                autoplay={true}
                height={150}
                width={150}
              />
            </div>
            <div className="REGISTER_CONTROLLER_HEADER_CONTAINER">
              <h3>Enter the Controller ID then register.</h3>
              <span>ID/SN found on the controller unit.</span>
              {this.state.registrationResponseMessage !== '' ? (
                <div
                  className="VERIFICATION_FEEDBACK_MESSAGE"
                  style={
                    !this.state.controllerCodeError
                      ? { backgroundColor: '#29C7AC' }
                      : { backgroundColor: '#FF6768' }
                  }
                >
                  <span>{this.state.registrationResponseMessage}</span>
                </div>
              ) : null}
            </div>
            <div className="REGISTER_CONTROLLER_FORM_CONTAINER">
              <div className="SIGNUP_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="ve"
                  label="Controller ID"
                  variant="outlined"
                  error={this.state.controllerCodeError}
                  name="controllerCode"
                  fullWidth
                  value={this.state.controllerCode}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'SIGNUP_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'SIGNUP_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="REGISTER_CONTROLLER_BUTTON_CONTAINER">
              {this.props.loading ? (
                <CircularProgress></CircularProgress>
              ) : (
                <div
                  className="SIGNUP_BUTTON_CONTAINER"
                  onClick={this.handleControllerRegistration}
                >
                  <h6>Register</h6>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="REGISTER_CONTROLLER_FORM_CONTAINER">
            <MetaDataForm></MetaDataForm>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    loading: state.controller.loading,
    error: state.controller.error,
    controller: state.controller.controller,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerController: (token, controllerCode) =>
      dispatch(assignControllerToUser(token, controllerCode)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterController);
