import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getTermsAndConditionsStart = () => {
  return {
    type: actionTypes.GET_TERMS_AND_CONDITIONS_START,
  };
};

export const getTermsAndConditionsSuccess = (data) => {
  return {
    type: actionTypes.GET_TERMS_AND_CONDITIONS_SUCCESS,
    payload: data,
  };
};

export const getTermsAndConditionsFaild = () => {
  return {
    type: actionTypes.GET_TERMS_AND_CONDITIONS_FAILD,
  };
};

export const getTermsAndConditions = (token) => {
  return (dispatch) => {
    dispatch(getTermsAndConditionsStart());

    axios
      .get(`${HOST_URL}/legal/terms-and-conditions/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTermsAndConditionsSuccess(response.data)))
      .catch((error) => dispatch(getTermsAndConditionsFaild(error)));
  };
};
