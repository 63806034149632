import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './styles/controller-configs.css';
import {
  getControllerConfigsByIMEI,
  updateControllerConfigsTempData,
} from '../../../store/actions/controllerConfigs';
import ThermostatAutoTwoToneIcon from '@mui/icons-material/ThermostatAutoTwoTone';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import SurfingTwoToneIcon from '@mui/icons-material/SurfingTwoTone';
import TempGauge from './temp-gauge/TempGauge';
import ConfigSettings from './config-settings/ConfigSettings';
// import SettingsIcon from '@mui/icons-material/Settings';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
// import ProgressGauge from '../../progress-gauge/ProgressGauge';
// import RunSchedule from './run-schedule/RunSchedule';
import { CircularProgress } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import VacationMode from './vacation-mode/VacationMode';
import SimpleRunSchedule from './run-schedule/SimpleRunSchedule';
import { applyToAll, resetAll } from '../../../store/actions/runSechedule';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';

class ControllerConfigs extends Component {
  state = {
    value: '1',
    hasError: false,
    showSettings: false,
    showThermostat: true,
    showSchedule: false,
    showVacMode: false,
    day: 'Monday',
    showWarning: false,
    showResetWarning: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      this.props.activeController !== prevProps.activeController &&
      this.props.activeController
    ) {
      this.handleGetConfigs();
    }
  }

  handleGetConfigs = () => {
    this.props.getConfigs(
      this.props.token,
      this.props.activeController.serial_number
    );
  };

  showSettings = () => {
    this.setState({
      showSettings: true,
      showThermostat: false,
      showSchedule: false,
      showVacMode: false,
    });
  };

  showThermostat = () => {
    this.setState({
      showSettings: false,
      showThermostat: true,
      showSchedule: false,
      showVacMode: false,
    });
  };
  showSchedule = () => {
    this.setState({
      showSettings: false,
      showThermostat: false,
      showSchedule: true,
      showVacMode: false,
    });
  };

  showVacMode = () => {
    this.setState({
      showVacMode: true,
      showSettings: false,
      showThermostat: false,
      showSchedule: false,
    });
  };

  handleTempChange = () => {};

  handleWarningClose = () => {
    this.setState({ showWarning: false });
  };

  handleResetWarningClose = () => {
    this.setState({ showResetWarning: false });
  };

  handleScheduleChanges = () => {
    this.handleWarningClose();
    if (!this.props.loadingSchedule) {
      const token = this.props.token;
      const IMEI = this.props.activeController?.serial_number;
      const day = this.state.day;
      if (IMEI) {
        this.props.applySecheduleChanges(token, day, IMEI);
      }
    }
  };

  handleResetSchedule = () => {
    this.handleResetWarningClose();
    if (!this.props.loadingSchedule) {
      const token = this.props.token;
      const IMEI = this.props.activeController?.serial_number;
      const day = this.state.day;
      if (IMEI) {
        this.props.resetSecheduleChanges(token, day, IMEI);
      }
    }
  };

  render() {
    return (
      <div className="DASHBOARD_CONTROLLER_CONFIGS_CONTAINER">
        <div className="DASHBOARD_CONTROLLER_CONFIGS_MODES_CONTAINER">
          <div className="DASHBOARD_CONTROLLER_CONFIGS_MODES">
            {!this.state.showSchedule ? (
              <div className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON_CONTAINER">
                <span>Temp</span>
                <div
                  className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON"
                  style={this.state.showThermostat ? { color: '#94F3E4' } : {}}
                  onClick={this.showThermostat}
                >
                  <ThermostatAutoTwoToneIcon
                    sx={{ fontSize: 30 }}
                  ></ThermostatAutoTwoToneIcon>
                </div>
              </div>
            ) : null}
            {/* new button  */}
            {this.state.showSchedule ? (
              <>
                <div className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON_CONTAINER">
                  <span>&nbsp;</span>
                  <div
                    className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON"
                    style={this.state.showSchedule ? { color: '#e94560' } : {}}
                    onClick={this.showThermostat}
                  >
                    {/* <h5>Schedule</h5> */}
                    <ClearOutlinedIcon
                      sx={{ fontSize: 30, color: '#e94560' }}
                    ></ClearOutlinedIcon>
                  </div>
                </div>
                <div className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON_CONTAINER">
                  <span>&nbsp;</span>
                  <div
                    className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON "
                    style={{
                      color: '#EEEEEE',
                      width: '130px',
                      fontFamily: 'sans-serif',
                    }}
                    onClick={() =>
                      this.setState({
                        showWarning: !this.props.loadingSchedule ? true : false,
                      })
                    }
                  >
                    {this.props.loadingSchedule ? (
                      <CircularProgress></CircularProgress>
                    ) : (
                      <span
                        style={{
                          color: 'inherit',
                          fontSize: '1.1rem',
                        }}
                      >
                        Apply to all
                      </span>
                    )}
                  </div>
                </div>
                <div className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON_CONTAINER">
                  <span>&nbsp;</span>
                  <div
                    className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON "
                    style={{
                      color: '#FA7D09',
                      width: '80px',
                      fontFamily: 'sans-serif',
                    }}
                    onClick={() =>
                      this.setState({
                        showResetWarning: !this.props.loadingSchedule
                          ? true
                          : false,
                      })
                    }
                  >
                    {this.props.loadingSchedule ? (
                      <CircularProgress></CircularProgress>
                    ) : (
                      <span style={{ color: 'inherit', fontSize: '1.1rem' }}>
                        Reset
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : null}
            {/* ----------- */}
            {!this.state.showSchedule ? (
              <>
                <div className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON_CONTAINER">
                  <span>Schedule</span>
                  <div
                    className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON"
                    style={this.state.showSchedule ? { color: '#94F3E4' } : {}}
                    onClick={this.showSchedule}
                  >
                    {/* <h5>Schedule</h5> */}
                    <ScheduleOutlinedIcon
                      sx={{ fontSize: 30 }}
                    ></ScheduleOutlinedIcon>
                  </div>
                </div>
                <div className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON_CONTAINER">
                  <span>V-Mode</span>
                  <div
                    className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON"
                    style={this.state.showVacMode ? { color: '#94F3E4' } : {}}
                    onClick={this.showVacMode}
                  >
                    <SurfingTwoToneIcon
                      sx={{ fontSize: 30 }}
                    ></SurfingTwoToneIcon>
                  </div>
                </div>
                <div className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON_CONTAINER">
                  <span>Insights</span>
                  <div
                    className="DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON"
                    style={this.state.showSettings ? { color: '#94F3E4' } : {}}
                    onClick={() => this.props.history.push('insights')}
                  >
                    <BarChartOutlinedIcon
                      sx={{ fontSize: 30 }}
                    ></BarChartOutlinedIcon>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {this.state.showSettings ? (
          <div className="DASHBOARD_CONTROLLER_TAB_CONTAINER">
            <ConfigSettings></ConfigSettings>
          </div>
        ) : null}
        {this.state.showThermostat ? (
          <div className="DASHBOARD_CONTROLLER_TAB_CONTAINER">
            <TempGauge mode={this.props.mode}></TempGauge>
            <div className="DASHBOARD_CONTROLLER_TAB_DES">
              Set maximum temp, <span style={{ color: '#E43F5A' }}>55°C</span>{' '}
              is recommended.
            </div>
          </div>
        ) : null}
        {this.state.showSchedule ? (
          <div className="DASHBOARD_CONTROLLER_TAB_CONTAINER">
            <SimpleRunSchedule
              setDay={(day) => this.setState({ day: day })}
            ></SimpleRunSchedule>
          </div>
        ) : null}
        {this.state.showVacMode ? (
          <div className="DASHBOARD_CONTROLLER_TAB_CONTAINER">
            <VacationMode></VacationMode>
          </div>
        ) : null}
        <Modal
          open={this.state.showWarning}
          onClose={this.handleWarningClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ Backdrop: { timeout: 500 } }}
        >
          <div className="PARAMETERS_WARNING_MODAL">
            <h2>WARNING</h2>
            <p>
              Would you like to apply the {this.state.day} schedule to all days?
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="PARAMETERS_WARNING_MODAL_BUTTON"
                onClick={this.handleScheduleChanges}
              >
                <span>YES</span>
              </div>
              <div
                className="PARAMETERS_WARNING_MODAL_BUTTON"
                onClick={this.handleWarningClose}
              >
                <span>Close</span>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.showResetWarning}
          onClose={this.handleResetWarningClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ Backdrop: { timeout: 500 } }}
        >
          <div className="PARAMETERS_WARNING_MODAL">
            <h2>WARNING</h2>
            <p>
              Are you certain about resetting the schedule for the entire week?
              Please note that all time slots will be deactivated.
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="PARAMETERS_WARNING_MODAL_BUTTON"
                onClick={this.handleResetSchedule}
              >
                <span>YES</span>
              </div>
              <div
                className="PARAMETERS_WARNING_MODAL_BUTTON"
                onClick={this.handleResetWarningClose}
              >
                <span>Close</span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    configsLoading: state.controllerConfigs.loading,
    controllers: state.controller.controllers,
    activeController: state.controller.controller,
    configs: state.controllerConfigs.controllerConfigs,
    activeTimeSlot: state.timeSlot.activeTimeSlot,
    loadingSchedule: state.timeSlot.alterLoading,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConfigs: (token, IMEI) =>
      dispatch(getControllerConfigsByIMEI(token, IMEI)),
    updateTempConfigs: (
      token,
      controllerConfigs_id,
      max_temperature,
      min_temperature
    ) =>
      dispatch(
        updateControllerConfigsTempData(
          token,
          controllerConfigs_id,
          max_temperature,
          min_temperature
        )
      ),
    applySecheduleChanges: (token, day, IMEI) =>
      dispatch(applyToAll(token, day, IMEI)),
    resetSecheduleChanges: (token, day, IMEI) =>
      dispatch(resetAll(token, day, IMEI)),
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(ControllerConfigs));
