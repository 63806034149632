import * as React from 'react';
import { withRouter } from 'react-router-dom';
import './styles/control-dashboard.css';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/MenuOutlined';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CrisisAlertOutlinedIcon from '@mui/icons-material/CrisisAlertOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HomeMaxOutlinedIcon from '@mui/icons-material/HomeMaxOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import Badge from '@mui/material/Badge';
import Devices from '../../components/devices/Devices';
import Alerts from '../../components/alerts/Alerts';
import General from '../../components/general/General';
import ControlSupport from '../../components/support/ControlSupport';
import ControlSettings from '../../components/settings/ControlSettings';

class SupportDashboard extends React.Component {
  state = {
    open: true,
  };

  handleDrawerOpen = () => {
    this.setState({ open: !this.state.open });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleDevicesOpen = () => {
    this.props.history.push('/control-dashboard/devices');
    localStorage.setItem('prevPath', '/control-dashboard/devices');
  };

  handleAlertsOpen = () => {
    this.props.history.push('/control-dashboard/alerts');
    localStorage.setItem('prevPath', '/control-dashboard/alerts');
  };

  handleGeneralOpen = () => {
    this.props.history.push('/control-dashboard/general');
    localStorage.setItem('prevPath', '/control-dashboard/general');
  };

  handleSupportOpen = () => {
    this.props.history.push('/control-dashboard/support');
    localStorage.setItem('prevPath', '/control-dashboard/support');
  };

  handleSettingsOpen = () => {
    this.props.history.push('/control-dashboard/settings');
    localStorage.setItem('prevPath', '/control-dashboard/settings');
  };

  render() {
    const drawerWidth = 240;
    return (
      <div className="CONTROL_DASHBOARD_MAIN_CONTAINER">
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: '#488B8F',
            // borderBottom: '2px solid #151631',
            height: 60,
            boxShadow: 0,
          }}
        >
          <Toolbar sx={this.state.open ? { marginLeft: 30 } : {}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={(event) => this.handleDrawerOpen(event, true)}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            <div className="CONTROL_DASHBOARD_TOOLBAR_BRAND_CONTAINER">
              <h1>Control Center</h1>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              backgroundColor: '#EEEEEE',
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={this.state.open}
        >
          <div
            style={{
              width: drawerWidth,
              height: 60,
              backgroundColor: '#488B8F',
            }}
          ></div>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={this.handleGeneralOpen}>
                <ListItemIcon>
                  <DonutSmallOutlinedIcon></DonutSmallOutlinedIcon>
                </ListItemIcon>
                <ListItemText primary={'General'} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={this.handleDevicesOpen}>
                <ListItemIcon>
                  <HomeMaxOutlinedIcon></HomeMaxOutlinedIcon>
                </ListItemIcon>
                <ListItemText primary={'Devices'} />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton onClick={this.handleAlertsOpen}>
                <ListItemIcon>
                  <CrisisAlertOutlinedIcon></CrisisAlertOutlinedIcon>
                </ListItemIcon>

                <ListItemText primary={'Alerts'} />
                <Badge
                  color="error"
                  badgeContent={3}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                ></Badge>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            {/* <ListItem disablePadding>
              <ListItemButton onClick={this.handleSettingsOpen}>
                <ListItemIcon>
                  <SettingsOutlinedIcon></SettingsOutlinedIcon>
                </ListItemIcon>
                <ListItemText primary={'Settings'} />
              </ListItemButton>
            </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton onClick={this.handleSupportOpen}>
                <ListItemIcon>
                  <SupportAgentOutlinedIcon></SupportAgentOutlinedIcon>
                </ListItemIcon>
                <ListItemText primary={'Support'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>

        <div className="CONTROL_DASHBOARD_CONTENT_MAIN_CONTAINER">
          <div
            style={{
              display: 'flex',
              width: 300,
              minWidth: 300,
            }}
          ></div>
          <div className="CONTROL_DASHBOARD_CONTENT_CONTAINER">
            {this.props.match.params.form === 'general' ? (
              <General></General>
            ) : null}
            {this.props.match.params.form === 'devices' ? (
              <Devices></Devices>
            ) : null}
            {this.props.match.params.form === 'alerts' ? (
              <Alerts></Alerts>
            ) : null}
            {this.props.match.params.form === 'support' ? (
              <ControlSupport></ControlSupport>
            ) : null}
            {this.props.match.params.form === 'settings' ? (
              <ControlSettings></ControlSettings>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SupportDashboard);
