import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getSupportTicketReplyStart = () => {
  return {
    type: actionTypes.GET_SUPPORT_TICKET_REPLY_START,
  };
};

export const getSupportTicketReplySuccess = (data) => {
  return {
    type: actionTypes.GET_SUPPORT_TICKET_REPLY_SUCCESS,
    payload: data,
  };
};

export const getSupportTicketReplysSuccess = (data) => {
  return {
    type: actionTypes.GET_SUPPORT_TICKET_REPLYS_SUCCESS,
    payload: data,
  };
};

export const getSupportTicketReplyFaild = () => {
  return {
    type: actionTypes.GET_SUPPORT_TICKET_REPLY_FAILD,
  };
};

export const getSupportTicketReplys = (token) => {
  return (dispatch) => {
    dispatch(getSupportTicketReplyStart());
    console.log('getting all SupportTicketReplys');
    axios
      .get(`${HOST_URL}/support/support-ticket-reply/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) =>
        dispatch(getSupportTicketReplysSuccess(response.data))
      )
      .catch((error) => dispatch(getSupportTicketReplyFaild(error)));
  };
};

export const getSupportTicketReply = (token, id) => {
  return (dispatch) => {
    dispatch(getSupportTicketReplyStart());

    axios
      .get(`${HOST_URL}/support/support-ticket-reply/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getSupportTicketReplySuccess(response.data)))
      .catch((error) => dispatch(getSupportTicketReplyFaild(error)));
  };
};

export const getSupportTicketReplyByTicket = (token, ticket_id) => {
  return (dispatch) => {
    dispatch(getSupportTicketReplyStart());

    axios
      .get(`${HOST_URL}/support/support-ticket-reply/ticket_${ticket_id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) =>
        dispatch(getSupportTicketReplysSuccess(response.data))
      )
      .catch((error) => dispatch(getSupportTicketReplyFaild(error)));
  };
};

export const postSupportTicketReplyData = (token, content, ticket_id) => {
  return (dispatch) => {
    dispatch(getSupportTicketReplyStart());
    let fd = new FormData();
    fd.append('content', content);
    fd.append('ticket_id', ticket_id);

    axios
      .post(`${HOST_URL}/support/support-ticket-reply/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getSupportTicketReplySuccess(response.data));
      })
      .catch((error) => dispatch(getSupportTicketReplyFaild(error)));
  };
};

export const updateSupportTicketReplyData = (token, reply_id, content) => {
  return (dispatch) => {
    dispatch(getSupportTicketReplyStart());
    let fd = new FormData();
    fd.append('content', content);

    axios
      .patch(`${HOST_URL}/support/support-ticket-reply/${reply_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getSupportTicketReplySuccess(response.data));
      })
      .catch((error) => dispatch(getSupportTicketReplyFaild(error)));
  };
};
