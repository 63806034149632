import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/alerts.css';
import { dateTimeCalender } from '../../tools/time';
import Button from '@mui/material/Button';
import {
  getControllerAlerts,
  updateControllerAlertStatus,
  updateAllAlertsStatus,
  deleteAllAlerts,
  getLastNAlerts,
  getAlertsCount,
} from '../../store/actions/alerts';
import { CircularProgress } from '@mui/material';

class Alerts extends Component {
  state = {
    alertQ: 10,
  };
  componentDidMount() {
    const token = this.props.token;
    const { alertQ } = this.state;
    this.props.getNAlerts(token, alertQ);
    this.props.getAlertsCount(token);
  }

  getMoreAlerts = () => {
    const token = this.props.token;
    const { alertQ } = this.state;
    this.props.getNAlerts(token, alertQ + 10);
    this.setState({ alertQ: alertQ + 10 });
  };

  updateAlertStatus = (alert) => {
    const token = this.props.token;
    const id = alert.id;
    const readStatus = alert.read;

    if (readStatus === false) {
      this.props.updateAlertStatus(token, id, true);
    }
  };

  handleMarkAlertsAsRead = () => {
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    const id = this.props.alerts[0]?.id;
    console.log(IMEI);
    if (IMEI) {
      this.props.updateAlertsStatus(token, id, IMEI, true);
    }
  };

  handleDeleteAllAlerts = () => {
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    console.log(IMEI);
    if (IMEI) {
      this.props.deleteAlerts(token, IMEI);
    }
  };

  render() {
    const alerts = this.props.alerts;
    return (
      <div className="ALERTS_MAIN_CONTAINER" id={this.props.mode}>
        {this.props.loadingAlerts ? (
          <CircularProgress
            color="warning"
            size={40}
            sx={{ marginTop: '2em' }}
          ></CircularProgress>
        ) : alerts && alerts.length === 0 ? (
          <div className="NO_ALERT_CONTAINER">
            <h2>No Alerts</h2>
          </div>
        ) : (
          <div className="ALERT_ACTIONS_CONTAINER">
            <Button variant="outlined" onClick={this.handleMarkAlertsAsRead}>
              Mark All as read
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={this.handleDeleteAllAlerts}
            >
              Delete all
            </Button>
          </div>
        )}
        {alerts && alerts.length > 0
          ? alerts.map((alert, index) => (
              <div className="ALERT_CONTAINER" key={alert.created_at}>
                <div className="ALERT_HEADER">
                  <h3>{alert.controller.controller_meta_data.lable}</h3>

                  {!alert.read ? (
                    <Button
                      size="small"
                      onClick={() => this.updateAlertStatus(alert)}
                    >
                      mark as read
                    </Button>
                  ) : (
                    <Button size="small" color="success">
                      read
                    </Button>
                  )}
                </div>
                <div className="ALERT_BODY">
                  <div>
                    <span>Alert Level</span>
                    <Button size="small" sx={{ color: alert.alert_type.color }}>
                      {alert.alert_type.alert_type}
                    </Button>
                  </div>
                  <p>{alert.content}</p>
                </div>
                <div className="ALERT_FOOTER">
                  <span>{dateTimeCalender(alert.created_at)}</span>
                </div>
              </div>
            ))
          : null}
        {alerts && alerts.length < this.props.alertsCount ? (
          <div className="ALERT_ACTIONS_CONTAINER">
            {this.props.loadingAlerts ? (
              <CircularProgress
                color="warning"
                size={40}
                sx={{ marginTop: '2em' }}
              ></CircularProgress>
            ) : (
              <Button onClick={this.getMoreAlerts}>Load more</Button>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    alerts: state.alerts.controllerAlerts,
    loadingAlerts: state.alerts.loading,
    alertsCount: state.alerts.alertsCount,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAlerts: (token) => dispatch(getControllerAlerts(token)),
    getAlertsCount: (token) => dispatch(getAlertsCount(token)),
    getNAlerts: (token, n) => dispatch(getLastNAlerts(token, n)),
    updateAlertStatus: (token, id, read) =>
      dispatch(updateControllerAlertStatus(token, id, read)),
    updateAlertsStatus: (token, id, IMEI, read) =>
      dispatch(updateAllAlertsStatus(token, id, IMEI, read)),
    deleteAlerts: (token, IMEI) => dispatch(deleteAllAlerts(token, IMEI)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
