import * as React from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Drawer from '../drawer/Drawer';

function PrimarySearchAppBar(props) {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(!drawerOpen);
  };

  const navigate = (path) => {
    props.history.push(path);
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleNavigation = (path) => {
    props.history.push(path);
  };

  const menuId = 'primary-search-account-menu';

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => navigate('/alerts')}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={props.unreadAlerts?.length} color="error">
            <CrisisAlertIcon />
          </Badge>
        </IconButton>
        <p>Alerts</p>
      </MenuItem>
      <MenuItem onClick={() => navigate('/notifications')}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={props.unreadNotifications?.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={() => navigate('/profile')}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <AppBar
          position="static"
          sx={{
            background: '#162e4c',

            boxShadow:
              'inset 20px 20px 40px #132741, inset -20px -20px 40px #193557;',
          }}
        >
          <Toolbar
            sx={{
              width: { xs: '90%', md: '60%' },
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              // sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              SMART GEYSER
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              size="large"
              edge="start"
              sx={{ ml: 'auto' }}
              onClick={props.switchMode}
            >
              {props.mode === 'light' ? (
                <LightModeIcon sx={{ color: '#F05454', fontSize: 30 }} />
              ) : (
                <DarkModeIcon sx={{ color: '#6B778D', fontSize: 30 }} />
              )}
            </IconButton>
            {props.isAuthenticated ? (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton
                  size="large"
                  aria-label="show N new alerts"
                  color="inherit"
                  onClick={() => navigate('/alerts')}
                >
                  <Badge
                    badgeContent={props.unreadAlerts?.length}
                    color="error"
                  >
                    <CrisisAlertIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show N new notifications"
                  color="inherit"
                  onClick={() => navigate('/notifications')}
                >
                  <Badge
                    badgeContent={props.unreadNotifications?.length}
                    color="error"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={() => handleNavigation('/profile')}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Box>
            ) : null}
            {props.isAuthenticated ? (
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            ) : null}
          </Toolbar>
        </AppBar>
        {props.isAuthenticated ? renderMobileMenu : null}
      </Box>
      {props.isAuthenticated ? (
        <Drawer
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          logout={() => props.logout()}
          mode={props.mode}
          unreadAlerts={props.unreadAlerts}
          unreadNotifications={props.unreadNotifications}
          username={props.userDetails?.username}
        ></Drawer>
      ) : null}
    </div>
  );
}

export default withRouter(PrimarySearchAppBar);
