import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getControllerStart = () => {
  return {
    type: actionTypes.GET_CONTROLLER_START,
  };
};

export const getControllerSuccess = (data) => {
  return {
    type: actionTypes.GET_CONTROLLER_SUCCESS,
    payload: data,
  };
};

export const getControllersSuccess = (data) => {
  return {
    type: actionTypes.GET_CONTROLLERS_SUCCESS,
    payload: data,
  };
};

export const getControllerMetaDataSuccess = (data) => {
  return {
    type: actionTypes.GET_CONTROLLER_META_DATA_SUCCESS,
    payload: data,
  };
};

export const getControllerFaild = (error) => {
  return {
    type: actionTypes.GET_CONTROLLER_FAILD,
    error: error,
  };
};

export const getControllers = (token) => {
  return (dispatch) => {
    dispatch(getControllerStart());
    console.log('getting all controllers');
    axios
      .get(`${HOST_URL}/controllers/controller/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllersSuccess(response.data)))
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};

export const getController = (token, id) => {
  return (dispatch) => {
    dispatch(getControllerStart());

    axios
      .get(`${HOST_URL}/controllers/controller/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerSuccess(response.data)))
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};

export const getControllerByIMEI = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getControllerStart());

    axios
      .get(`${HOST_URL}/controllers/controller/IMEI_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerSuccess(response.data)))
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};

export const getControllerByCode = (token, code) => {
  return (dispatch) => {
    dispatch(getControllerStart());

    axios
      .get(`${HOST_URL}/controllers/controller/code_${code}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerSuccess(response.data)))
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};

export const getControllerByPhneNo = (token, phone) => {
  return (dispatch) => {
    dispatch(getControllerStart());

    axios
      .get(`${HOST_URL}/controllers/controller/phone_${phone}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerSuccess(response.data)))
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};

export const getControllerByEmail = (token, email) => {
  return (dispatch) => {
    dispatch(getControllerStart());

    axios
      .get(`${HOST_URL}/controllers/controller/email_${email}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerSuccess(response.data)))
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};

export const postControllerData = (token, serial_number, phone_number) => {
  return (dispatch) => {
    dispatch(getControllerStart());
    let fd = new FormData();
    fd.append('serial_number', serial_number);
    fd.append('phone_number', phone_number);

    axios
      .post(`${HOST_URL}/controllers/controller/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getControllerSuccess(response.data));
      })
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};

export const updateControllerData = (
  token,
  controller_id,
  serial_number,
  phone_number
) => {
  return (dispatch) => {
    dispatch(getControllerStart());
    let fd = new FormData();
    fd.append('serial_number', serial_number);
    fd.append('phone_number', phone_number);

    axios
      .patch(`${HOST_URL}/controllers/controller/${controller_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getControllerSuccess(response.data));
      })
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};

export const assignControllerToUser = (token, controllerCode) => {
  return (dispatch) => {
    dispatch(getControllerStart());
    let fd = new FormData();
    fd.append('code', controllerCode);

    axios
      .post(`${HOST_URL}/controllers/register-controller/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getControllerSuccess(response.data));
      })
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};

export const postControllerMetaData = (token, controller_id, data) => {
  return (dispatch) => {
    dispatch(getControllerStart());

    axios
      .post(`${HOST_URL}/controllers/controller-meta-data/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getControllers(token));
        dispatch(getController(token, controller_id));
        dispatch(getControllerMetaDataSuccess(response.data));
      })
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};

export const updateControllerMetaData = (
  token,
  controller_id,
  controller_meta_id,
  data
) => {
  return (dispatch) => {
    dispatch(getControllerStart());

    axios
      .patch(
        `${HOST_URL}/controllers/controller-meta-data/${controller_meta_id}/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        dispatch(getControllers(token));
        dispatch(getController(token, controller_id));
      })
      .catch((error) => dispatch(getControllerFaild(error)));
  };
};
