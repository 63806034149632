import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getSupportTicketStart = () => {
  return {
    type: actionTypes.GET_SUPPORT_TICKET_START,
  };
};

export const getSupportTicketSuccess = (data) => {
  return {
    type: actionTypes.GET_SUPPORT_TICKET_SUCCESS,
    payload: data,
  };
};

export const getSupportTicketsSuccess = (data) => {
  return {
    type: actionTypes.GET_SUPPORT_TICKETS_SUCCESS,
    payload: data,
  };
};

export const getSupportTicketFaild = () => {
  return {
    type: actionTypes.GET_SUPPORT_TICKET_FAILD,
  };
};

export const getSupportTickets = (token) => {
  return (dispatch) => {
    dispatch(getSupportTicketStart());
    console.log('getting all SupportTickets');
    axios
      .get(`${HOST_URL}/support/support-tickets/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getSupportTicketsSuccess(response.data)))
      .catch((error) => dispatch(getSupportTicketFaild(error)));
  };
};

export const getSupportTicket = (token, id) => {
  return (dispatch) => {
    dispatch(getSupportTicketStart());

    axios
      .get(`${HOST_URL}/support/support-tickets/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getSupportTicketSuccess(response.data)))
      .catch((error) => dispatch(getSupportTicketFaild(error)));
  };
};

export const getSupportTicketByGategory = (token, category) => {
  return (dispatch) => {
    dispatch(getSupportTicketStart());

    axios
      .get(`${HOST_URL}/support/support-tickets/category-${category}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getSupportTicketsSuccess(response.data)))
      .catch((error) => dispatch(getSupportTicketFaild(error)));
  };
};

export const postSupportTicketData = (token, title, category, content) => {
  return (dispatch) => {
    dispatch(getSupportTicketStart());
    let fd = new FormData();
    fd.append('title', title);
    fd.append('category', category);
    fd.append('content', content);

    axios
      .post(`${HOST_URL}/support/support-tickets/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getSupportTicketSuccess(response.data));
      })
      .catch((error) => dispatch(getSupportTicketFaild(error)));
  };
};

export const updateSupportTicketData = (
  token,
  ticket_id,
  title,
  category,
  content
) => {
  return (dispatch) => {
    dispatch(getSupportTicketStart());
    let fd = new FormData();
    fd.append('title', title);
    fd.append('category', category);
    fd.append('content', content);

    axios
      .patch(`${HOST_URL}/support/support-tickets/${ticket_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getSupportTicketSuccess(response.data));
      })
      .catch((error) => dispatch(getSupportTicketFaild(error)));
  };
};
