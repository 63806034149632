import React, { Component } from 'react';
import './histogram-chart.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { Chart, Interval, Axis, Tooltip } from 'bizcharts';

export default class HistoChart extends Component {
  state = {
    openInfo: false,
    anchorEl: null,
  };

  handleOpenInfo = (event) => {
    this.setState({
      openInfo: !this.state.openInfo,
      anchorEl: event.currentTarget,
    });
  };

  render() {
    const data = this.props.data;
    const describtion = this.props.describtion;

    const scale = {
      value: {
        type: 'linear',
        tickCount: 1,
      },
    };
    return (
      <div className="HISTOGRAM_CHART_MAIN_CONTAINER">
        <div className="HISTOGRAM_CHART_INFO_CONTAINER">
          <IconButton onClick={this.handleOpenInfo}>
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        </div>

        <Popper
          open={this.state.openInfo}
          anchorEl={this.state.anchorEl}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  opacity: 0.5,
                  backgroundColor: '#082032',
                  maxWidth: '250px',
                }}
              >
                <Typography sx={{ p: 2, color: '#DDDDDD' }}>
                  {describtion}
                </Typography>
              </Paper>
            </Fade>
          )}
        </Popper>
        <Chart
          padding="auto"
          autoFit
          height={220}
          width={300}
          data={data}
          shadowColor="#333"
          scale={scale}
        >
          <Axis name="region" visible={true} />
          <Interval
            shape="smooth"
            color="region"
            position="region*value"
          ></Interval>
          <Tooltip>
            {(title, items) => {
              return (
                <div>
                  <span>{title}</span>
                  <p>{items[0].data.country}</p>
                  <p>{items[0].data.value}</p>
                </div>
              );
            }}
          </Tooltip>
        </Chart>
      </div>
    );
  }
}
