import Carousel from 'react-spring-3d-carousel';
import { useState, useEffect } from 'react';
import Card from '../3D-carousel/card/Card';
import { v4 as uuidv4 } from 'uuid';

export default function Carroussel(props) {
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(null);

  let cardsList = [
    {
      key: uuidv4(),
      index: 1,
      content: (
        <Card
          title="Usage Cost"
          description=""
          mode={props.mode}
          type="power_consumption_cost"
          goToSlide={() => setGoToSlide(1)}
        ></Card>
      ),
    },
    {
      key: uuidv4(),
      index: 2,
      content: (
        <Card
          title="Power Consumption in KW"
          description=""
          mode={props.mode}
          type="power_consumption"
          goToSlide={() => setGoToSlide(2)}
        ></Card>
      ),
    },
    {
      key: uuidv4(),
      index: 3,
      content: (
        <Card
          title="Saving"
          description=""
          mode={props.mode}
          type="cost_saving"
          goToSlide={() => setGoToSlide(3)}
        ></Card>
      ),
    },
  ];

  const table = cardsList.map((element, index) => {
    return { ...element };
  });
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={{ tension: 120, friction: 14 }}
      />
    </div>
  );
}
