import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  supportTicketReply: null,
  supportTicketReplys: [],
};

const getSupportTicketReplyStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getSupportTicketReplySuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    supportTicketReply: action.payload,
  });
};

const getSupportTicketReplysSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    supportTicketReplys: action.payload,
  });
};

const getSupportTicketReplyFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const SupportTicketReplyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUPPORT_TICKET_REPLY_START:
      return getSupportTicketReplyStart(state, action);
    case actionTypes.GET_SUPPORT_TICKET_REPLY_SUCCESS:
      return getSupportTicketReplySuccess(state, action);
    case actionTypes.GET_SUPPORT_TICKET_REPLYS_SUCCESS:
      return getSupportTicketReplysSuccess(state, action);
    case actionTypes.GET_SUPPORT_TICKET_REPLY_FAILD:
      return getSupportTicketReplyFaild(state, action);
    default:
      return state;
  }
};

export default SupportTicketReplyReducer;
