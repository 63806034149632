import React, { Component } from 'react';
import { connect } from 'react-redux';
import './parameters.css';
import ControllerParameters from '../../../components/controller-parameters/ControllerParameters';

class Parameters extends Component {
  render() {
    return (
      <div
        className="DASHBOARD_CONTROLLER_PARAMETERS_MAIN_CONTAINER"
        id={this.props.mode}
      >
        <div className="DASHBOARD_CONTROLLER_PARAMETERS_HEADER_CONTAINER">
          <h2>Parameters</h2>
        </div>
        <div className="DASHBOARD_CONTROLLER_PARAMETERS_WRAPPER">
          <ControllerParameters
            parameters={this.props.parameters}
          ></ControllerParameters>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Parameters);
