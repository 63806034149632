import React, { Component } from 'react';
import './styles/dashboard-alert.css';
import Chip from '@mui/material/Chip';

export default class DashboardAlert extends Component {
  handleClick = () => {
    console.log('clicked');
  };
  render() {
    return (
      <div className="CONTROL_DASHBOARD_ALERTS_MAIN_CONTAINER">
        <div className="CONTROL_DASHBOARD_ALERTS_HEADER_CONTAINER">
          <div className="CONTROL_DASHBOARD_ALERTS_INDICATOR">
            <div
              className="ALERTS_INDICATOR"
              style={{ backgroundColor: this.props.alert.color }}
            ></div>
          </div>
          <div className="CONTROL_DASHBOARD_ALERTS_TYPE">
            <Chip
              label={this.props.alert.type}
              sx={{ color: '#222831', backgroundColor: '#DDDDDD' }}
            />
          </div>
          <div className="CONTROL_DASHBOARD_ALERTS_DEVICE">
            <Chip
              label={this.props.alert.controller}
              sx={{ color: '#222831', backgroundColor: '#DDDDDD' }}
            />
          </div>
          <div className="CONTROL_DASHBOARD_ALERTS_USER">
            <Chip
              label={this.props.alert.user}
              sx={{ color: '#222831', backgroundColor: '#DDDDDD' }}
            />
          </div>
          <div className="CONTROL_DASHBOARD_ALERTS_REGION">
            <Chip
              label={this.props.alert.region}
              sx={{ color: '#222831', backgroundColor: '#DDDDDD' }}
            />
          </div>
        </div>
        <div className="CONTROL_DASHBOARD_ALERTS_BODY_CONTAINER">
          <p>{this.props.alert.content}</p>
        </div>
        <div className="CONTROL_DASHBOARD_ALERTS_FOOTER_CONTAINER">
          <span>{this.props.alert.date}</span>
        </div>
        <div className="ALTERT_DEVIDER"></div>
      </div>
    );
  }
}
