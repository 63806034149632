import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  monthlyUsage: null,
  currentMonthlyUsage: null,
};

const getInsightsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getMonthlyUsageSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    monthlyUsage: action.payload,
  });
};

const getCurrentMonthlyUsageSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    currentMonthlyUsage: action.payload,
  });
};

const getAnalyticsFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const geyserInsightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_INSIGHTS_START:
      return getInsightsStart(state, action);
    case actionTypes.GET_MONTHLY_USAGE_SUCCESS:
      return getMonthlyUsageSuccess(state, action);
    case actionTypes.GET_CURRENT_MONTHLY_USAGE_SUCCESS:
      return getCurrentMonthlyUsageSuccess(state, action);
    case actionTypes.GET_INSIGHTS_FAILD:
      return getAnalyticsFaild(state, action);
    default:
      return state;
  }
};

export default geyserInsightsReducer;
