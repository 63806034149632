import React, { Component } from 'react';
import './logs-table.css';
import { timeConverter } from '../../tools/time';
import Badge from '@mui/material/Badge';

export default class AnalyticsTable extends Component {
  render() {
    return (
      <div className="LOGS_TABLE_CONTAINER">
        <div className="LOGS_DAILY_TABLE_DATA">
          {this.props.logs?.length > 0 ? (
            this.props.logs.map((data, index) => (
              <div
                className="LOGS_DAILY_TABLE_DATA_ITEM"
                style={
                  index % 2 === 0
                    ? this.props.mode === 'dark'
                      ? { backgroundColor: '#17223B' }
                      : { backgroundColor: '#DDE6ED' }
                    : {}
                }
                key={data.created_at}
              >
                <div className="LOGS_DAILY_TABLE_DATA_ITEM_HEADER">
                  <span>{timeConverter(data.created_at).format('HH:mm')}</span>
                  <span>{data.date}</span>
                </div>
                <div className="LOGS_DAILY_TABLE_DATA_ITEM_CELLS">
                  <div
                    className="LOGS_DAILY_TABLE_DATA_ITEM_CELL"
                    style={{ width: '10%' }}
                  >
                    <Badge
                      badgeContent={data.status ? 'ON' : 'OFF'}
                      color={data.status ? 'success' : 'error'}
                    />
                  </div>
                  <div
                    className="LOGS_DAILY_TABLE_DATA_ITEM_CELL"
                    style={{ width: '20%', fontWeight: 'bold' }}
                  >
                    <p>{data.temperature}&nbsp;°C</p>
                  </div>
                  <div
                    className="LOGS_DAILY_TABLE_DATA_ITEM_CELL"
                    style={{ width: '45%' }}
                  >
                    <p>{data.action_source}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="NO_LOGS_CONTAINER">
              <span>No Logs Available</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
