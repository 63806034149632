import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  controllerStatus: null,
};

const getControllerStatusStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getControllerStatusSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    controllerStatus: action.payload,
  });
};

const getControllerStatusFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const controllerStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTROLLER_STATUS_START:
      return getControllerStatusStart(state, action);
    case actionTypes.GET_CONTROLLER_STATUS_SUCCESS:
      return getControllerStatusSuccess(state, action);
    case actionTypes.GET_CONTROLLER_STATUS_FAILD:
      return getControllerStatusFaild(state, action);
    default:
      return state;
  }
};

export default controllerStatusReducer;
