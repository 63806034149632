import React, { Component } from 'react';
import './date-range.css';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

export default class DatePickerValue extends Component {
  state = {
    start: null,
    end: null,
  };
  // componentDidMount() {
  //   this.setState({ start: this.props.firstDate });
  //   this.setState({ end: this.props.lastDate });
  // }
  onStartDateChange = (newValue) => {
    this.props.setStartDate(newValue);
    this.setState({ start: newValue });
  };

  onEndDateChange = (newValue) => {
    this.props.setEndDate(newValue);
    this.setState({ end: newValue });
  };

  render() {
    return (
      <div className="LOGS_DATE_PICKERS_CONTAINER" id={this.props.mode}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            label="Start date"
            defaultValue={dayjs(this.props.firstDate)}
            onChange={(newValue) => this.onStartDateChange(newValue)}
            className="LOGS_DATE_PICKER_INPUT_FIELD"
          />
          <MobileDatePicker
            label="End date"
            defaultValue={dayjs(this.props.lastDate)}
            onChange={(newValue) => this.onEndDateChange(newValue)}
            className="LOGS_DATE_PICKER_INPUT_FIELD"
          />
        </LocalizationProvider>
      </div>
    );
  }
}
