import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getControllerConfigsStart = () => {
  return {
    type: actionTypes.GET_CONTROLLER_CONFIGS_START,
  };
};

export const getControllerConfigsSuccess = (data) => {
  return {
    type: actionTypes.GET_CONTROLLER_CONFIGS_SUCCESS,
    payload: data,
  };
};

export const getControllerConfigsFaild = () => {
  return {
    type: actionTypes.GET_CONTROLLER_CONFIGS_FAILD,
  };
};

export const getControllerConfigs = (token, id) => {
  return (dispatch) => {
    dispatch(getControllerConfigsStart());

    axios
      .get(`${HOST_URL}/controller-configs/configs/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerConfigsSuccess(response.data)))
      .catch((error) => dispatch(getControllerConfigsFaild(error)));
  };
};

export const getControllerConfigsByIMEI = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getControllerConfigsStart());

    axios
      .get(`${HOST_URL}/controller-configs/configs/IMEI_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerConfigsSuccess(response.data)))
      .catch((error) => dispatch(getControllerConfigsFaild(error)));
  };
};

export const postControllerConfigsData = (
  token,
  IMEI,
  max_temperature,
  min_temperature,
  max_power_consumption,
  params_interval,
  config_interval
) => {
  return (dispatch) => {
    dispatch(getControllerConfigsStart());
    let fd = new FormData();
    fd.append('IMEI', IMEI);
    fd.append('max_temperature', max_temperature);
    fd.append('min_temperature', min_temperature);
    fd.append('max_power_consumption', max_power_consumption);

    axios
      .post(`${HOST_URL}/controller-configs/configs/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getControllerConfigsSuccess(response.data));
      })
      .catch((error) => dispatch(getControllerConfigsFaild(error)));
  };
};

export const updateControllerConfigsTempData = (
  token,
  controllerConfigs_id,
  max_temperature,
  min_temperature,
  max_power_consumption
) => {
  return (dispatch) => {
    dispatch(getControllerConfigsStart());
    let fd = new FormData();
    fd.append('max_temperature', max_temperature);
    fd.append('min_temperature', min_temperature);
    fd.append('max_power_consumption', max_power_consumption);

    axios
      .patch(
        `${HOST_URL}/controller-configs/configs/${controllerConfigs_id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getControllerConfigsSuccess(response.data));
      })
      .catch((error) => dispatch(getControllerConfigsFaild(error)));
  };
};

export const updateControllerConfigsWantedTemp = (
  token,
  controllerConfigs_id,
  wanted_temp
) => {
  return (dispatch) => {
    dispatch(getControllerConfigsStart());
    let fd = new FormData();
    fd.append('wanted_temp', wanted_temp);

    axios
      .patch(
        `${HOST_URL}/controller-configs/configs/${controllerConfigs_id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getControllerConfigsSuccess(response.data));
      })
      .catch((error) => dispatch(getControllerConfigsFaild(error)));
  };
};

export const updateControllerConfigsVacationMode = (
  token,
  controllerConfigs_id,
  vacation_mode
) => {
  return (dispatch) => {
    dispatch(getControllerConfigsStart());
    let fd = new FormData();
    fd.append('vacation_mode', vacation_mode);

    axios
      .patch(
        `${HOST_URL}/controller-configs/configs/${controllerConfigs_id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getControllerConfigsSuccess(response.data));
      })
      .catch((error) => dispatch(getControllerConfigsFaild(error)));
  };
};
