import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/logs.css';
import DateRange from '../../components/logs/date-range/DateRange';
import LogsTable from '../../components/logs/LogsTable';
import moment from 'moment';
import {
  getCurrentDayDates,
  getCurrentMonthDates,
  getLastSevenDaysDates,
  adjustDateRange,
} from '../../tools/dates';
import {
  getTodyLogsByDate,
  getWeekLogsByDate,
  getMonthLogsByDate,
  getLogsByDate,
} from '../../store/actions/logs';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';

class Logs extends Component {
  state = {
    activeLogs: 'day', //options day, week and month
    custom: false,
    startDate: null,
    endDate: null,
  };
  componentDidMount() {
    this.getCurrentDayLogs();
    this.getLastWeekLogs();
    this.getCurrentMonthLogs();
  }

  formatDate = (date) => {
    return moment(date).format();
  };

  getCurrentMonthLogs = () => {
    const { firstDate, lastDate } = getCurrentMonthDates();
    const token = this.props.token;
    const IMEI = this.props.controller
      ? this.props.controller.serial_number
      : localStorage.getItem('active_g');
    this.props.getMonthLogs(
      token,
      IMEI,
      this.formatDate(firstDate),
      this.formatDate(lastDate)
    );
  };

  getCurrentDayLogs = () => {
    const { firstDate, lastDate } = getCurrentDayDates();
    const token = this.props.token;
    const IMEI = this.props.controller
      ? this.props.controller.serial_number
      : localStorage.getItem('active_g');
    this.props.getTodayLogs(
      token,
      IMEI,
      this.formatDate(firstDate),
      this.formatDate(lastDate)
    );
  };

  getLastWeekLogs = () => {
    const { firstDate, lastDate } = getLastSevenDaysDates();
    const token = this.props.token;
    const IMEI = this.props.controller
      ? this.props.controller.serial_number
      : localStorage.getItem('active_g');
    this.props.getWeekLogs(
      token,
      IMEI,
      this.formatDate(firstDate),
      this.formatDate(lastDate)
    );
  };

  getCustomLogs = () => {
    const { firstDate, lastDate } = adjustDateRange(
      this.state.startDate,
      this.state.endDate
    );
    if (firstDate && lastDate) {
      const token = this.props.token;
      const IMEI = this.props.controller
        ? this.props.controller.serial_number
        : localStorage.getItem('active_g');
      this.props.getCustomLogs(
        token,
        IMEI,
        this.formatDate(firstDate),
        this.formatDate(lastDate)
      );
    }
  };

  handleSwitchToCustomLogs = () => {
    const { custom } = this.state;
    if (custom) {
      this.setState({ custom: false, activeLogs: 'day' });
    } else {
      this.setState({ custom: true, activeLogs: '' });
    }
  };

  handleGetActiveLogs = (date) => {
    this.setState({ activeLogs: date });
    if (date === 'day') {
      this.getCurrentDayLogs();
    } else if (date === 'week') {
      this.getLastWeekLogs();
    } else if (date === 'month') {
      this.getCurrentMonthLogs();
    }
  };

  render() {
    const { activeLogs, custom } = this.state;
    return (
      <div
        className="DASHBOARD_MAIN_CONTAINER"
        id={this.props.mode}
        style={{ paddingTop: '1em' }}
      >
        <div className="LOGS_HEADER">
          <h2>RUN LOGS</h2>
          <Button
            size="small"
            color="warning"
            variant="outlined"
            sx={{ marginBottom: 3 }}
            onClick={this.handleSwitchToCustomLogs}
          >
            {this.state.custom ? 'Back' : 'Custom date'}
          </Button>
        </div>
        {this.state.custom ? (
          <div className="LOGS_DATE_PICKERS_MAIN_CONTAINER">
            <DateRange
              setStartDate={(value) =>
                this.setState({ startDate: new Date(value) })
              }
              setEndDate={(value) =>
                this.setState({ endDate: new Date(value) })
              }
              firstDate={this.state.startDate}
              lastDate={this.state.endDate}
              mode={this.props.mode}
            ></DateRange>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              sx={{ marginTop: 2 }}
              onClick={this.getCustomLogs}
            >
              Apply
            </Button>
          </div>
        ) : (
          <div className="LOGS_NAVIGATION_HEADER">
            <div
              className="LOGS_NAVIGATION_HEADER_ITEM"
              onClick={() => this.handleGetActiveLogs('day')}
            >
              {this.props.loadingLogs ? (
                <CircularProgress></CircularProgress>
              ) : (
                <span style={activeLogs === 'day' ? { color: '#3976d2' } : {}}>
                  Today
                </span>
              )}
            </div>
            <div
              className="LOGS_NAVIGATION_HEADER_ITEM"
              onClick={() => this.handleGetActiveLogs('week')}
            >
              {this.props.loadingLogs ? (
                <CircularProgress></CircularProgress>
              ) : (
                <span style={activeLogs === 'week' ? { color: '#3976d2' } : {}}>
                  Last 7 Days
                </span>
              )}
            </div>
            <div
              className="LOGS_NAVIGATION_HEADER_ITEM"
              onClick={() => this.handleGetActiveLogs('month')}
            >
              {this.props.loadingLogs ? (
                <CircularProgress></CircularProgress>
              ) : (
                <span
                  style={activeLogs === 'month' ? { color: '#3976d2' } : {}}
                >
                  This Month
                </span>
              )}
            </div>
          </div>
        )}

        {activeLogs === 'day' ? (
          <LogsTable
            logs={this.props.todyLogs}
            mode={this.props.mode}
          ></LogsTable>
        ) : activeLogs === 'week' ? (
          <LogsTable
            logs={this.props.weekLogs}
            mode={this.props.mode}
          ></LogsTable>
        ) : activeLogs === 'month' ? (
          <LogsTable
            logs={this.props.monthLogs}
            mode={this.props.mode}
          ></LogsTable>
        ) : custom ? (
          <LogsTable
            logs={this.props.customLogs}
            mode={this.props.mode}
          ></LogsTable>
        ) : (
          <div>No Logs Available</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    controller: state.controller.controller,
    todyLogs: state.geyserLogs.todayLogs,
    weekLogs: state.geyserLogs.weekLogs,
    monthLogs: state.geyserLogs.monthLogs,
    customLogs: state.geyserLogs.customLogs,
    loadingLogs: state.geyserLogs.loading,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTodayLogs: (token, IMEI, startDate, endDate) =>
      dispatch(getTodyLogsByDate(token, IMEI, startDate, endDate)),
    getWeekLogs: (token, IMEI, startDate, endDate) =>
      dispatch(getWeekLogsByDate(token, IMEI, startDate, endDate)),
    getMonthLogs: (token, IMEI, startDate, endDate) =>
      dispatch(getMonthLogsByDate(token, IMEI, startDate, endDate)),
    getCustomLogs: (token, IMEI, startDate, endDate) =>
      dispatch(getLogsByDate(token, IMEI, startDate, endDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
