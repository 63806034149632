import 'dotenv/config';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  authCheckState,
  getUserDetails,
  getTheme,
  setMode,
  logout,
} from './store/actions/auth';
import ClinetDashboard from './pages/client-dashboard/Dashboard';
import ControlDashboard from './pages/control-dashboard/ControlDashboard';
import Login from './pages/login/Login';
import './theme/theme.css';
import Signup from './pages/signup/Signup';
import Header from './layout/header2/Header';
import Profile from './pages/client-profile/Profile';
import SelectController from './pages/select-controller/SelectController';
import Home from './pages/app-home/Home';
import RegisterController from './pages/register-controller/RegisterController';
import VerifyAccount from './pages/verify-account/VerifyAccount';
import UserSupport from './pages/support/UserSupport';
import Notifications from './pages/notifications/Notifications';
import Alerts from './pages/alerts/Alerts';
import { getNotificationsByStatus } from './store/actions/notifications';
import { getControllerAlertByStatus } from './store/actions/alerts';
import Settings from './pages/settings/Settings';
import TermsConditions from './pages/terms-and-conditions/TermsConditions';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import PasswordResetConfirm from './pages/password-reset-confirm/PasswordResetConfirm';
import UserGuide from './pages/user-guide/UserGuide';
import Insights from './pages/insights/Insights';
import Logs from './pages/logs/Logs';
import CompleteGRegistration from './pages/complete-registration/CompleteGRegistration';

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignin();
  }
  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      if (this.props.token !== null) {
        this.props.getUserDetails(this.props.token);
        this.props.getThemeDetails(this.props.token);
        this.props.getUnreadNotifications(this.props.token);
        this.props.getUnreadAlerts(this.props.token);
      }
    }
  }

  switchMode = () => {
    const token = this.props.token;
    const id = this.props.theme?.id;
    if (this.props.theme?.mode === 'light') {
      this.props.setMode(token, id, 'dark');
      localStorage.setItem('mode', 'dark');
    } else {
      this.props.setMode(token, id, 'light');
      localStorage.setItem('mode', 'light');
    }
  };

  handleLogout = () => {
    this.props.logout();
  };

  render() {
    return (
      <Router>
        <div className="CONTAINER">
          <Header
            switchMode={this.switchMode}
            mode={this.props.theme?.mode}
            unreadAlerts={this.props.unreadAlerts}
            unreadNotifications={this.props.unreadNotifications}
            logout={this.handleLogout}
            isAuthenticated={this.props.isAuthenticated}
            userDetails={this.props.userDetails}
          ></Header>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route
              exact
              path="/verify-account"
              component={this.props.isAuthenticated ? VerifyAccount : Login}
            />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path="/password-reset-confirm/:uid/:token/"
              component={PasswordResetConfirm}
            />
            <Route
              exact
              path="/register-controller"
              component={
                this.props.isAuthenticated ? RegisterController : Login
              }
            />
            <Route
              exact
              path="/complete-controller-registration/:code/"
              component={
                this.props.isAuthenticated ? CompleteGRegistration : Login
              }
            />
            <Route
              exact
              path="/select-controller"
              component={this.props.isAuthenticated ? SelectController : Login}
            />
            <Route
              exact
              path="/settings"
              component={this.props.isAuthenticated ? Settings : Login}
            />
            <Route
              exact
              path="/dashboard"
              component={this.props.isAuthenticated ? ClinetDashboard : Login}
            />
            <Route
              exact
              path="/dashboard/:code/"
              component={this.props.isAuthenticated ? ClinetDashboard : Login}
            />
            <Route
              exact
              path="/profile"
              component={this.props.isAuthenticated ? Profile : Login}
            />
            <Route
              exact
              path="/notifications"
              component={this.props.isAuthenticated ? Notifications : Login}
            />
            <Route
              exact
              path="/alerts"
              component={this.props.isAuthenticated ? Alerts : Login}
            />
            <Route
              exact
              path="/insights"
              component={this.props.isAuthenticated ? Insights : Login}
            />

            <Route
              exact
              path="/logs"
              component={this.props.isAuthenticated ? Logs : Login}
            />
            <Route
              exact
              path="/user-guide"
              component={this.props.isAuthenticated ? UserGuide : Login}
            />
            <Route
              exact
              path="/support"
              component={this.props.isAuthenticated ? UserSupport : Login}
            />
            <Route
              exact
              path="/terms-and-conditions"
              component={this.props.isAuthenticated ? TermsConditions : Login}
            />
            <Route
              exact
              path="/control-dashboard/:form/"
              component={this.props.isAuthenticated ? ControlDashboard : Login}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.users.token !== null,
    userDetails: state.users.userDetails,
    token: state.users.token,
    theme: state.users.theme,
    unreadAlerts: state.alerts.unreadAlerts,
    unreadNotifications: state.notifications.unreadNotifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignin: () => dispatch(authCheckState()),
    getUserDetails: (token) => dispatch(getUserDetails(token)),
    setMode: (token, id, mode) => dispatch(setMode(token, id, mode)),
    getUnreadNotifications: (token) =>
      dispatch(getNotificationsByStatus(token, false)),
    getUnreadAlerts: (token) =>
      dispatch(getControllerAlertByStatus(token, false)),
    getThemeDetails: (token) => dispatch(getTheme(token)),
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
