import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getGeyserUsageStart = () => {
  return {
    type: actionTypes.GET_GEYSER_USAGE_START,
  };
};

export const getGeyserUsageSuccess = (data) => {
  return {
    type: actionTypes.GET_GEYSER_USAGE_SUCCESS,
    payload: data,
  };
};

export const getGeyserUsageFaild = () => {
  return {
    type: actionTypes.GET_GEYSER_USAGE_FAILD,
  };
};

export const getGeyserUsage = (token, id) => {
  return (dispatch) => {
    dispatch(getGeyserUsageStart());

    axios
      .get(`${HOST_URL}/geyser-usage/daily/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getGeyserUsageSuccess(response.data)))
      .catch((error) => dispatch(getGeyserUsageFaild(error)));
  };
};

export const getGeyserUsageByIMEI = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getGeyserUsageStart());

    axios
      .get(`${HOST_URL}/geyser-usage/daily/IMEI_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getGeyserUsageSuccess(response.data)))
      .catch((error) => dispatch(getGeyserUsageFaild(error)));
  };
};

export const getGeyserUsageByDate = (token, IMEI, startDate, endDate) => {
  return (dispatch) => {
    dispatch(getGeyserUsageStart());

    axios
      .get(
        `${HOST_URL}/geyser-usage/daily/date_${IMEI}_${startDate}_${endDate}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getGeyserUsageSuccess(response.data)))
      .catch((error) => dispatch(getGeyserUsageFaild(error)));
  };
};
