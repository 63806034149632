import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/dashboard.css';
import {
  getControllerByCode,
  getControllers,
  getControllerByIMEI,
} from '../../store/actions/controller';
import { getControllerConfigsByIMEI } from '../../store/actions/controllerConfigs';
import {
  getControllerParametersByIMEI,
  getLatestControllerParametersByIMEI,
} from '../../store/actions/controllerParameter';
import Performance from './performance/Performance';
import Parameters from './parameters/Parameters';
import Configs from './configs/Configs';

class Dashboard extends Component {
  state = {};

  componentDidMount() {
    const token = this.props.token;
    if (typeof Storage !== 'undefined') {
      const IMEI = localStorage.getItem('active_g');
      this.props.getActiveControllerByIMEI(token, IMEI);
    } else if (this.props.match.params.code !== undefined) {
      this.props.getActiveControllerByCode(
        this.props.token,
        this.props.match.params.code
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.controller !== prevProps.controller &&
      this.props.controller
    ) {
      if (
        typeof Storage !== 'undefined' &&
        localStorage.getItem('active_g') !== undefined
      ) {
        const IMEI = localStorage.getItem('active_g');
        this.props.getLatestParameters(this.props.token, IMEI);
      } else {
        const IMEI = this.props.controller.serial_number;
        this.props.getLatestParameters(this.props.token, IMEI);
      }
    }
  }

  render() {
    return (
      <div className="DASHBOARD_MAIN_CONTAINER" id={this.props.mode}>
        <div className="DASHBOARD_MAIN_HEADER">
          <p>{this.props.controller?.controller_meta_data?.lable}</p>
        </div>
        <Performance></Performance>
        <Parameters></Parameters>
        <Configs></Configs>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    controllers: state.controller.controllers,
    controller: state.controller.controller,
    configs: state.controllerConfigs.controllerConfigs,
    parameters: state.controllerParameters.controllerParameters,
    latestParameters: state.controllerParameters.latestControllerParameters,
    timeSlot: state.timeSlot.timeSlot,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getControllers: (token) => dispatch(getControllers(token)),
    getActiveControllerByCode: (token, code) =>
      dispatch(getControllerByCode(token, code)),
    getActiveControllerByIMEI: (token, IMEI) =>
      dispatch(getControllerByIMEI(token, IMEI)),
    getParameters: (token, IMEI) =>
      dispatch(getControllerParametersByIMEI(token, IMEI)),
    getLatestParameters: (token, IMEI) =>
      dispatch(getLatestControllerParametersByIMEI(token, IMEI)),
    getConfigs: (token, IMEI) =>
      dispatch(getControllerConfigsByIMEI(token, IMEI)),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Dashboard);
