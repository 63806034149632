import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getTimeSlotStart = () => {
  return {
    type: actionTypes.GET_TIME_SLOT_START,
  };
};

export const getAlterSlotStart = () => {
  return {
    type: actionTypes.GET_ALTER_TIME_SLOT_START,
  };
};

export const getTimeSlotSuccess = (data) => {
  return {
    type: actionTypes.GET_TIME_SLOT_SUCCESS,
    payload: data,
  };
};

export const getActiveTimeSlotSuccess = (data) => {
  return {
    type: actionTypes.GET_ACTIVE_TIME_SLOT_SUCCESS,
    payload: data,
  };
};

export const getTimeSlotsSuccess = (data) => {
  return {
    type: actionTypes.GET_TIME_SLOTS_SUCCESS,
    payload: data,
  };
};

export const getTimeSlotFaild = () => {
  return {
    type: actionTypes.GET_TIME_SLOT_FAILD,
  };
};

export const getTimeSlots = (token) => {
  return (dispatch) => {
    dispatch(getTimeSlotStart());
    console.log('getting all TimeSlots');
    axios
      .get(`${HOST_URL}/run-schedule/time-slot/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTimeSlotsSuccess(response.data)))
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};

export const getTimeSlot = (token, id) => {
  return (dispatch) => {
    dispatch(getTimeSlotStart());

    axios
      .get(`${HOST_URL}/run-schedule/time-slot/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTimeSlotSuccess(response.data)))
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};

export const deleteTimeSlot = (token, id, day, IMEI) => {
  return (dispatch) => {
    dispatch(getTimeSlotStart());

    axios
      .delete(`${HOST_URL}/run-schedule/time-slot/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTimeSlotByDay(token, day, IMEI)))
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};

export const getTimeSlotByDay = (token, day, IMEI) => {
  return (dispatch) => {
    dispatch(getTimeSlotStart());

    axios
      .get(`${HOST_URL}/run-schedule/time-slot/day_${day}_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTimeSlotsSuccess(response.data)))
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};

export const getActiveTimeSlotByDay = (token, day, IMEI) => {
  return (dispatch) => {
    dispatch(getTimeSlotStart());

    axios
      .get(`${HOST_URL}/run-schedule/time-slot/active_${day}_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getActiveTimeSlotSuccess(response.data)))
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};

export const postTimeSlotData = (token, IMEI, day, start_time, end_time) => {
  return (dispatch) => {
    dispatch(getTimeSlotStart());
    let fd = new FormData();
    fd.append('day', day);
    fd.append('IMEI', IMEI);
    fd.append('start_time', start_time);
    fd.append('end_time', end_time);

    axios
      .post(`${HOST_URL}/run-schedule/time-slot/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTimeSlotSuccess(response.data));
        dispatch(getTimeSlotByDay(token, day, IMEI));
      })
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};

export const updateTimeSlotData = (
  token,
  timeSlot_id,
  IMEI,
  day,
  enabled,
  start_time,
  end_time,
  auto_enabled
) => {
  return (dispatch) => {
    dispatch(getTimeSlotStart());
    let fd = new FormData();
    fd.append('IMEI', IMEI);
    fd.append('day', day);
    fd.append('enabled', enabled);
    fd.append('start_time', start_time);
    fd.append('end_time', end_time);
    fd.append('auto_enabled', auto_enabled);

    axios
      .patch(`${HOST_URL}/run-schedule/time-slot/${timeSlot_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTimeSlotSuccess(response.data));
        dispatch(getTimeSlotByDay(token, day, IMEI));
      })
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};

export const deactivateTimeSlot = (token, IMEI, day, timeSlot_id) => {
  return (dispatch) => {
    dispatch(getTimeSlotStart());
    let fd = new FormData();
    fd.append('IMEI', IMEI);
    fd.append('day', day);
    fd.append('enabled', false);

    axios
      .patch(`${HOST_URL}/run-schedule/time-slot/${timeSlot_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTimeSlotSuccess(response.data));
        dispatch(getTimeSlotByDay(token, day, IMEI));
      })
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};

export const activateTimeSlot = (token, IMEI, day, timeSlot_id) => {
  return (dispatch) => {
    dispatch(getTimeSlotStart());
    let fd = new FormData();
    fd.append('IMEI', IMEI);
    fd.append('day', day);
    fd.append('enabled', true);

    axios
      .patch(`${HOST_URL}/run-schedule/time-slot/${timeSlot_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTimeSlotSuccess(response.data));
        dispatch(getTimeSlotByDay(token, day, IMEI));
      })
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};

export const applyToAll = (token, day, IMEI) => {
  return (dispatch) => {
    dispatch(getAlterSlotStart());

    axios
      .get(`${HOST_URL}/run-schedule/alter-schedule/all_${day}_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTimeSlotsSuccess(response.data)))
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};

export const resetAll = (token, day, IMEI) => {
  return (dispatch) => {
    dispatch(getAlterSlotStart());

    axios
      .get(`${HOST_URL}/run-schedule/alter-schedule/resetall_${day}_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTimeSlotsSuccess(response.data)))
      .catch((error) => dispatch(getTimeSlotFaild(error)));
  };
};
