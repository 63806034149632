import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/login.css';
import TextField from '@mui/material/TextField';
import * as userActions from '../../store/actions/auth';
import CircularProgress from '@mui/material/CircularProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Backdrop from '@mui/material/Backdrop';
import { Button } from '@mui/material';

class Login extends Component {
  state = {
    username: '',
    usernameError: { value: false, des: '' },
    password: '',
    passwordError: { value: false, des: '' },
    open: false,
  };

  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      if (this.props.token !== null) {
        this.props.getUserDetails(this.props.token);
        this.props.getUserGeoData(this.props.token);
      }
    }
    if (
      this.props.userDetails !== prevProps.userDetails &&
      this.props.userDetails !== null
    ) {
      this.setState({ open: false });
      this.handleRedirecting();
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRedirecting = () => {
    this.props.history.push('/select-controller');
  };

  validateFields = () => {
    const { password, username } = this.state;
    let valid = true;
    if (username === '') {
      this.setState({
        usernameError: { value: true, des: 'Username is required' },
      });
      valid = false;
    }
    if (password === '') {
      this.setState({
        passwordError: { value: true, des: 'Password is required' },
      });
      valid = false;
    }
    return valid;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const valid = this.validateFields();
    if (valid) {
      const { username, password } = this.state;
      this.props.onAuth(username.toLowerCase(), password);
    }
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      usernameError: { value: false, des: '' },
      passwordError: { value: false, des: '' },
    });

  render() {
    return (
      <div className="LOGIN_CONTAINER">
        <Backdrop
          sx={{ color: '#fff' }}
          open={this.state.open}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <form className="form-signin mb-3" onSubmit={this.onSubmit}>
          <div className="text-center mb-4">
            <span className="text-primary">
              {this.props.loading ? (
                <CircularProgress></CircularProgress>
              ) : (
                <div className="LOGIN_FORM_ICON">
                  <LockOpenIcon
                    color="inherit"
                    fontSize="inherit"
                  ></LockOpenIcon>
                </div>
              )}
            </span>
          </div>

          {this.props.error ? (
            <h4 style={{ fontFamily: 'sans-serif', color: '#F05454' }}>
              {this.props.error.response
                ? this.props.error.response.data['non_field_errors']
                  ? this.props.error.response.data['non_field_errors'][0]
                  : null
                : null}
            </h4>
          ) : null}

          <div className="form-group SIGNUP_FORM_INPUT_FIELD_CONTAINER">
            <TextField
              id="username"
              label="Username"
              variant="outlined"
              name="username"
              fullWidth
              placeholder={this.state.usernameError.des}
              error={this.state.usernameError.value}
              value={this.state.username}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'form-control LOGIN_INPUT_FIELD',
              }}
              InputLabelProps={{
                // shrink: true,
                className: 'LOGIN_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>

          <div className="form-group SIGNUP_FORM_INPUT_FIELD_CONTAINER">
            <TextField
              id="password"
              type="password"
              label="Password"
              variant="outlined"
              name="password"
              fullWidth
              placeholder={this.state.passwordError.des}
              error={this.state.passwordError.value}
              value={this.state.password}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'form-control LOGIN_INPUT_FIELD',
              }}
              InputLabelProps={{
                // shrink: true,
                className: 'LOGIN_INPUT_FIELD_LABEL',
              }}
            ></TextField>
            <a href="/forgot-password" style={{ marginLeft: '65%' }}>
              Reset password
            </a>
          </div>
          <div className="LOGIN_BUTTON_CONTAINER">
            <Button
              type="submit"
              size="large"
              color="inherit"
              onClick={this.onSubmit}
            >
              Sign in
            </Button>
          </div>
          <div className="BACK_BUTTON_CONTAINER">
            <Button
              size="large"
              color="info"
              onClick={() => this.props.history.push('/signup')}
            >
              Create Account
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.users.loading,
    token: state.users.token,
    error: state.users.error,
    userDetails: state.users.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) =>
      dispatch(userActions.authLogin(username, password)),
    getUserDetails: (token) => dispatch(userActions.getUserDetails(token)),
    getUserGeoData: (token) => dispatch(userActions.getUserGeoLocation(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
