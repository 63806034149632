import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getRunOrderStart = () => {
  return {
    type: actionTypes.GET_RUN_ORDER_START,
  };
};

export const getRunOrderSuccess = (data) => {
  return {
    type: actionTypes.GET_RUN_ORDER_SUCCESS,
    payload: data,
  };
};

export const getRunOrderFaild = () => {
  return {
    type: actionTypes.GET_RUN_ORDER_FAILD,
  };
};

export const getRunOrder = (token, id) => {
  return (dispatch) => {
    dispatch(getRunOrderStart());

    axios
      .get(`${HOST_URL}/run-schedule/run-order/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getRunOrderSuccess(response.data)))
      .catch((error) => dispatch(getRunOrderFaild(error)));
  };
};

export const getRunOrderByIMEI = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getRunOrderStart());

    axios
      .get(`${HOST_URL}/run-schedule/run-order/order_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getRunOrderSuccess(response.data)))
      .catch((error) => dispatch(getRunOrderFaild(error)));
  };
};

export const getLastRunOrderByIMEI = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getRunOrderStart());
    axios
      .get(`${HOST_URL}/run-schedule/run-order/last_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getRunOrderSuccess(response.data)))
      .catch((error) => dispatch(getRunOrderFaild(error)));
  };
};

export const updateRunOrder = (token, order, id) => {
  return (dispatch) => {
    dispatch(getRunOrderStart());
    let fd = new FormData();
    fd.append('turn_element_on', order);

    axios
      .patch(`${HOST_URL}/run-schedule/run-order/${id}/`, fd, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getRunOrderSuccess(response.data)))
      .catch((error) => dispatch(getRunOrderFaild(error)));
  };
};

export const postRunOrder = (token, order, IMEI, publish) => {
  return (dispatch) => {
    dispatch(getRunOrderStart());
    let fd = new FormData();
    fd.append('IMEI', IMEI);
    fd.append('turn_element_on', order);
    fd.append('publish', publish);

    axios
      .post(`${HOST_URL}/run-schedule/run-order/`, fd, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getRunOrderSuccess(response.data)))
      .catch((error) => dispatch(getRunOrderFaild(error)));
  };
};
