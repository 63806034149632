import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getAppSettingsStart = () => {
  return {
    type: actionTypes.GET_APP_SETTINGS_START,
  };
};

export const getAppSettingsSuccess = (data) => {
  return {
    type: actionTypes.GET_APP_SETTINGS_SUCCESS,
    payload: data,
  };
};

export const getAppSettingsFaild = () => {
  return {
    type: actionTypes.GET_APP_SETTINGS_FAILD,
  };
};

export const getAppSettings = (token) => {
  return (dispatch) => {
    dispatch(getAppSettingsStart());

    axios
      .get(`${HOST_URL}/settings/app-settings/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getAppSettingsSuccess(response.data)))
      .catch((error) => dispatch(getAppSettingsFaild(error)));
  };
};

export const getAppSettingsByUser = (token) => {
  return (dispatch) => {
    dispatch(getAppSettingsStart());

    axios
      .get(`${HOST_URL}/settings/app-settings/user_1/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getAppSettingsSuccess(response.data)))
      .catch((error) => dispatch(getAppSettingsFaild(error)));
  };
};

export const getAppSetting = (token, id) => {
  return (dispatch) => {
    dispatch(getAppSettingsStart());

    axios
      .get(`${HOST_URL}/settings/app-settings/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getAppSettingsSuccess(response.data)))
      .catch((error) => dispatch(getAppSettingsFaild(error)));
  };
};

export const updateAppSettings = (
  token,
  id,
  enable_alerts,
  enable_notifications,
  enable_messages
) => {
  return (dispatch) => {
    dispatch(getAppSettingsStart());
    let fd = new FormData();
    fd.append('enable_alerts', enable_alerts);
    fd.append('enable_notifications', enable_notifications);
    fd.append('enable_messages', enable_messages);

    axios
      .patch(`${HOST_URL}/settings/app-settings/${id}/`, fd, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        dispatch(getAppSettingsSuccess(response.data));
      })
      .catch((error) => dispatch(getAppSettingsFaild(error)));
  };
};

export const postAppSettings = (token) => {
  return (dispatch) => {
    dispatch(getAppSettingsStart());
    axios
      .post(
        `${HOST_URL}/settings/app-settings/`,
        {},
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => {
        dispatch(getAppSettingsSuccess(response.data));
      })
      .catch((error) => dispatch(getAppSettingsFaild(error)));
  };
};
