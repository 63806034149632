import React, { Component } from 'react';
import './styles/settings-item.css';
import Switch from './Switch';

export default class SettingsItem extends Component {
  handleUpdateSettings = (value) => {
    this.props.updateSettings(this.props.title, value);
  };
  render() {
    return (
      <div className="SETTING_ITEM_CONTAINER">
        <div className="SETTING_ITEM_TITLE">
          <h3>{this.props.title}</h3>
          <div className="SETTING_ITEM_ACTION">
            <Switch
              enabled={this.props.enabled}
              updateSwitch={(value) => this.handleUpdateSettings(value)}
            ></Switch>
          </div>
        </div>
        <div className="SETTING_ITEM_DESCREPTION">
          <p>{this.props.description}</p>
        </div>
      </div>
    );
  }
}
