import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  controller: null,
  controllers: [],
  controllerMetaData: null,
};

const getControllerStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getControllerSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    controller: action.payload,
  });
};

const getControllerMetaDataSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    controllerMetaData: action.payload,
  });
};

const getControllersSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    controllers: action.payload,
  });
};

const getControllerFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const controllerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTROLLER_START:
      return getControllerStart(state, action);
    case actionTypes.GET_CONTROLLER_SUCCESS:
      return getControllerSuccess(state, action);
    case actionTypes.GET_CONTROLLERS_SUCCESS:
      return getControllersSuccess(state, action);
    case actionTypes.GET_CONTROLLER_META_DATA_SUCCESS:
      return getControllerMetaDataSuccess(state, action);
    case actionTypes.GET_CONTROLLER_FAILD:
      return getControllerFaild(state, action);
    default:
      return state;
  }
};

export default controllerReducer;
