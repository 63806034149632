import React, { Component } from 'react';
import './styles/home.css';
import Lottie from 'lottie-react';
import homeUserImage from './lottiefiles/user-reviews.json';

export default class Home extends Component {
  navigate = (path) => {
    this.props.history.push(path);
  };
  render() {
    return (
      <div className="HOME_MAIN_CONTAINER">
        <div className="HOME_CONTAINER">
          <div className="HOME_USER_REVIEW_IMAGE">
            <Lottie
              animationData={homeUserImage}
              loop={false}
              autoplay={true}
              height={300}
              width={200}
            />
          </div>
          <div className="HOME_USER_REVIEW_OPTIONS">
            <div className="HOME_USER_REVIEW_HEADER">
              <h1>Welcome to TopiOT world</h1>
            </div>
            <div
              className="HOME_LOGIN_BUTTON"
              onClick={() => this.navigate('/login')}
            >
              <h6>Sign in</h6>
            </div>
            <div
              className="HOME_SIGNUP_BUTTON"
              onClick={() => this.navigate('/signup')}
            >
              <h6>Create Account</h6>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
