import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './styles/profile-controller.css';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import Lottie from 'lottie-react';
import controllerImage from './lottiefiles/19872-cpu-crypto-loading-animation.json';
import {
  postControllerMetaData,
  updateControllerMetaData,
} from '../../store/actions/controller';
import { CircularProgress } from '@mui/material';

class ProfileController extends Component {
  state = {
    controllerLable: '',
    controllerLocation: '',
    rates: parseFloat(2.4),
    shouldSave: false,
  };

  componentDidMount() {
    const data = this.props.data;
    if (data) {
      this.setState({
        controllerLable: data.lable,
        controllerLocation: data.address,
        rates: data.rates_per_kw,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.controllers !== prevProps.controllers) {
      this.setState({ shouldSave: false });
    }
  }

  onLableChange = (e) => {
    if (e.target.name === 'controllerLable' && e.target.value.length < 36) {
      this.setState({ [e.target.name]: e.target.value });
    }
    if (e.target.value !== this.props.data.lable) {
      this.setState({ shouldSave: true });
    } else {
      this.setState({ shouldSave: false });
    }
  };

  onRatesChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, shouldSave: true });

    if (e.target.value !== this.props.data.rates_per_kw) {
      this.setState({ shouldSave: true });
    } else {
      this.setState({ shouldSave: false });
    }
  };

  onAddressChange = (e) => {
    if (e.target.name === 'controllerLocation' && e.target.value.length < 150) {
      this.setState({ [e.target.name]: e.target.value });
    }
    if (e.target.value !== this.props.data.address) {
      this.setState({ shouldSave: true });
    } else {
      this.setState({ shouldSave: false });
    }
  };

  navigate = (path) => {
    this.props.history.push(path);
  };

  catchControllerMetaDataChange = () => {
    const { controllerLable, controllerLocation, rates } = this.state;

    if ((controllerLable || controllerLocation || rates) !== '') {
      return true;
    } else {
      return false;
    }
  };

  handleMetaDataUpdate = () => {
    const token = this.props.token;
    const controller_id = this.props.controller_id;
    const controllerMetaData = this.props.data;
    if (controllerMetaData) {
      let fd = new FormData();
      if (controllerMetaData.lable !== this.state.controllerLable) {
        fd.append('lable', this.state.controllerLable);
      }
      if (controllerMetaData.address !== this.state.controllerLocation) {
        fd.append('address', this.state.controllerLocation);
      }
      if (controllerMetaData.rates_per_kw !== this.state.rates) {
        fd.append('rates_per_kw', this.state.rates);
      }

      this.props.updateMetaData(
        token,
        controller_id,
        controllerMetaData.id,
        fd
      );
    } else {
      let fd = new FormData();
      fd.append('controller_id', controller_id);
      if (this.state.controllerLable !== '') {
        fd.append('lable', this.state.controllerLable);
      }
      if (this.state.controllerLocation !== '') {
        fd.append('address', this.state.controllerLocation);
      }
      if (this.state.rates !== '') {
        fd.append('rates_per_kw', this.state.rates);
      }
      fd.append('suburb', this.state.suburb);
      fd.append('city', this.state.city);
      fd.append('province', this.state.province);
      fd.append('postal_code', this.state.postalCode);
      this.props.saveMetaData(token, controller_id, fd);
    }
  };

  handleControllerDataUpdate = () => {
    if (this.catchControllerMetaDataChange()) {
      this.handleMetaDataUpdate();
    }
  };

  render() {
    return (
      <div
        className="CLIENT_PROFILE_USER_CONTROLLER_CONTAINER"
        id={this.props.mode}
      >
        <div
          className="CLIENT_PROFILE_USER_CONTROLLER_IMAGE_CONTAINER"
          onClick={() => this.navigate('/dashboard')}
        >
          <Lottie
            animationData={controllerImage}
            loop={true}
            autoplay={true}
            height={250}
            width={250}
          />
        </div>
        <div
          className="CLIENT_PROFILE_USER_CONTROLLER_BUTTON_CONTAINER"
          style={
            this.state.shouldSave ? { display: 'flex' } : { display: 'none' }
          }
          onClick={this.handleControllerDataUpdate}
        >
          {this.props.loadingController ? (
            <CircularProgress
              fontSize="inherit"
              color="inherit"
            ></CircularProgress>
          ) : (
            <IconButton
              aria-label="save"
              sx={{ color: 'inherit', fontSize: 'inherit' }}
            >
              <SaveIcon fontSize="inherit" color="inherit" />
            </IconButton>
          )}
        </div>

        <div className="CLIENT_PROFILE_USER_CONTROLLER_INFO_CONTAINER">
          <div className="CLIENT_PROFILE_USER_INFO_SUB_CONTAINER">
            <div className="USER_INPUT_FIELD_CONTAINER">
              <TextField
                id="controller-lable"
                label="Lable"
                variant="outlined"
                name="controllerLable"
                fullWidth
                required
                value={this.state.controllerLable}
                onChange={this.onLableChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'USER_PROFILE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="USER_INPUT_FIELD_CONTAINER">
              <TextField
                id="controller-location"
                label="Address"
                variant="outlined"
                name="controllerLocation"
                fullWidth
                required
                value={this.state.controllerLocation}
                onChange={this.onAddressChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'USER_PROFILE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="CLIENT_PROFILE_USER_INFO_SUB_CONTAINER">
            <div className="USER_INPUT_FIELD_CONTAINER">
              <TextField
                id="power-rate"
                label="Rates Per KW"
                variant="outlined"
                name="rates"
                fullWidth
                type="number"
                required
                value={this.state.rates}
                onChange={this.onRatesChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'USER_PROFILE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    controllers: state.controller.controllers,
    controller: state.controller.controller,
    loadingController: state.controller.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveMetaData: (token, controller_id, data) =>
      dispatch(postControllerMetaData(token, controller_id, data)),
    updateMetaData: (token, controller_id, controller_meta_id, data) =>
      dispatch(
        updateControllerMetaData(token, controller_id, controller_meta_id, data)
      ),
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(ProfileController));
