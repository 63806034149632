import React, { Component } from 'react';
import { connect } from 'react-redux';
import './config-settings.css';
import PowerConsumptionLimit from './power-limit/PowerConsumptionLimit';
import TempSlider from './temp-slider/TempSlider';
import { updateControllerConfigsTempData } from '../../../../store/actions/controllerConfigs';

class ConfigSettings extends Component {
  state = {
    minTemp: 25,
    maxTemp: 70,
    maxPwConsumption: 270,
    intervalId: 1,
  };

  componentDidMount() {
    if (this.props.controllerConfigs) {
      const { max_temperature, min_temperature, max_power_consumption } =
        this.props.controllerConfigs;
      this.setState({
        minTemp: min_temperature,
        maxTemp: max_temperature,
        maxPwConsumption: max_power_consumption,
      });
    }

    const newIntervalId = setInterval(() => {
      this.handleUpdateTempData();
    }, 2000);

    this.setState((prevState) => {
      return {
        ...prevState,
        intervalId: newIntervalId,
      };
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.controllerConfigs !== prevProps.controllerConfigs &&
      this.props.controllerConfigs
    ) {
      const { max_temperature, min_temperature, max_power_consumption } =
        this.props.controllerConfigs;
      this.setState({
        minTemp: min_temperature,
        maxTemp: max_temperature,
        maxPwConsumption: max_power_consumption,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  handleMinTempChange = (newValue) => {
    if (newValue > this.state.maxTemp - 5) {
      console.log('Passed Allowed Temp');
    } else {
      this.setState({ minTemp: newValue });
    }
  };

  handleMaxTempChange = (newValue) => {
    if (newValue < this.state.minTemp + 5) {
      console.log('Passed Allowed Temp');
    } else {
      this.setState({ maxTemp: newValue });
    }
  };

  handlePowerLimitChange = (newValue) => {
    this.setState({ maxPwConsumption: newValue });
  };

  handleUpdateTempData = () => {
    if (this.props.controllerConfigs) {
      const token = this.props.token;
      const { id, max_temperature, min_temperature, max_power_consumption } =
        this.props.controllerConfigs;
      const { minTemp, maxTemp, maxPwConsumption } = this.state;
      if (
        minTemp !== min_temperature ||
        maxTemp !== max_temperature ||
        maxPwConsumption !== max_power_consumption
      ) {
        this.props.updateTempData(
          token,
          id,
          maxTemp,
          minTemp,
          maxPwConsumption
        );
      }
    }
  };

  render() {
    return (
      <div className="CONFIGS_SETTINGS_CONTAINER">
        <TempSlider
          title="Min Temp"
          value={this.state.minTemp}
          mode={this.props.mode}
          handleChange={this.handleMinTempChange}
        ></TempSlider>
        <TempSlider
          title="Max Temp"
          value={this.state.maxTemp}
          mode={this.props.mode}
          handleChange={this.handleMaxTempChange}
        ></TempSlider>
        <PowerConsumptionLimit
          mode={this.props.mode}
          value={this.state.maxPwConsumption}
          handleChange={this.handlePowerLimitChange}
          ratesPerKW={
            this.props.metaData ? this.props.metaData.rates_per_kw : 0
          }
        ></PowerConsumptionLimit>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    controllerConfigs: state.controllerConfigs.controllerConfigs,
    metaData: state.controller.controller?.controller_meta_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTempData: (
      token,
      controllerConfigs_id,
      max_temperature,
      min_temperature,
      max_power_consumption
    ) =>
      dispatch(
        updateControllerConfigsTempData(
          token,
          controllerConfigs_id,
          max_temperature,
          min_temperature,
          max_power_consumption
        )
      ),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ConfigSettings);
