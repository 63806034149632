import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  token: null,
  username: null,
  verificationInfoSent: false,
  verificationInfo: null,
  resetPWResponse: null,
  changePWResponse: null,
  error: null,
  loading: false,
  userDetails: null,
  theme: { mode: localStorage.getItem('mode') },
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    username: action.username,
    error: null,
    loading: false,
  });
};

const sendVerificationInfoSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    verificationInfoSent: true,
  });
};

const getVerificationInfoSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    verificationInfo: action.payload,
  });
};

const getResetPasswordResponse = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    resetPWResponse: action.payload,
  });
};

const changePasswordResponse = (state, action) => {
  return updateObject(state, {
    changePWResponse: action.payload,
    error: null,
    loading: false,
  });
};

const userDetailsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    userDetails: action.payload,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    username: null,
    loading: false,
  });
};

const setUiModeSuccess = (state, action) => {
  return updateObject(state, {
    theme: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_SEND_VERIFICATION_INFO_SUCCESS:
      return sendVerificationInfoSuccess(state, action);
    case actionTypes.AUTH_VERIFICATION_INFO_SUCCESS:
      return getVerificationInfoSuccess(state, action);
    case actionTypes.RESET_PASSWORD_RESPONSE:
      return getResetPasswordResponse(state, action);
    case actionTypes.CHANGE_PASSWORD_RESPONSE:
      return changePasswordResponse(state, action);
    case actionTypes.AUTH_DETAILS_SUCCESS:
      return userDetailsSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.UI_MODE_SUCCESS:
      return setUiModeSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
