import React, { Component } from 'react';
import './styles/alerts.css';
import DashboardAlert from './dashboard-alert/DashboardAlert';

export default class Alerts extends Component {
  render() {
    return (
      <div className="CONTROL_DASHBOARD_CONTENT_ALERTS_CONTAINER">
        <div className="CONTROL_DASHBOARD_CONTENT_ALERT_CONTAINER">
          <DashboardAlert
            alert={{
              type: 'Error',
              color: 'f05454',
              controller: '035388736682477634',
              user: 'Ayser Shuhaib',
              region: 'KZN',
              date: '22-06-2022',
              content:
                'Last update received from this device was 3 hours ago, either the controller is OFF or unable to establish an internet connection.',
            }}
          ></DashboardAlert>
        </div>
        <div className="CONTROL_DASHBOARD_CONTENT_ALERT_CONTAINER">
          <DashboardAlert
            alert={{
              type: 'Warning',
              color: '#EEB76B',
              controller: '035388736682477634',
              user: 'Ayser Shuhaib',
              region: 'KZN',
              date: '22-06-2022',
              content:
                'This device registered a higher power consumption in the last few days which indicates an extensive use or an issue with the geyser.',
            }}
          ></DashboardAlert>
        </div>
        <div className="CONTROL_DASHBOARD_CONTENT_ALERT_CONTAINER">
          <DashboardAlert
            alert={{
              type: 'Warning',
              color: '#EEB76B',
              controller: '035388736682477634',
              user: 'Ayser Shuhaib',
              region: 'KZN',
              date: '22-06-2022',
              content:
                'This device registered a higher power consumption in the last few days which indicates an extensive use or an issue with the geyser.',
            }}
          ></DashboardAlert>
        </div>
      </div>
    );
  }
}
