import React from 'react';
import { useState, useEffect } from 'react';
import './power-consumption-limit.css';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

export default function PowerConsumptionLimit(props) {
  const [powerLimit, setPowerLimit] = useState(props.value);

  useEffect(() => {
    setPowerLimit(props.value);
  }, [props]);

  const handlChangePowerLimit = (operation) => {
    if (operation === 'increase') {
      let newPowerLimit = powerLimit + 5;
      setPowerLimit(newPowerLimit);
      props.handleChange(newPowerLimit);
    } else if (operation === 'decrease' && powerLimit - 5 !== 0) {
      let newPowerLimit = powerLimit - 5;
      setPowerLimit(newPowerLimit);
      props.handleChange(newPowerLimit);
    }
  };

  return (
    <div className="CONFIGS_SETTINGS_POWER_LIMITS_MAIN_CONTAINER">
      <div className="CONFIGS_SETTINGS_POWER_LIMITS_HEADER">
        <Typography>Power Consumption Limit /PM</Typography>
      </div>
      <div className="CONFIGS_SETTINGS_POWER_LIMITS_CONTAINER">
        <div
          className="CONFIGS_SETTINGS_POWER_LIMITS_BUTTON"
          onClick={() => handlChangePowerLimit('decrease')}
        >
          <RemoveIcon fontSize="inherit" color="inherit" />
        </div>
        <div className="CONFIGS_SETTINGS_POWER_LIMITS_VALUES_CONTAINER">
          <div className="CONFIGS_SETTINGS_POWER_LIMITS_VALUE">
            <p>{powerLimit}</p> <span>KW</span>
          </div>
          <div className="CONFIGS_SETTINGS_POWER_LIMITS_VALUE">
            <p>{powerLimit * props.ratesPerKW}</p> <span>R</span>
          </div>
        </div>
        <div
          className="CONFIGS_SETTINGS_POWER_LIMITS_BUTTON"
          onClick={() => handlChangePowerLimit('increase')}
        >
          <AddIcon fontSize="inherit" color="inherit" />
        </div>
      </div>
    </div>
  );
}
