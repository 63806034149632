import React, { Component } from 'react';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';

export default class NetworkIcon extends Component {
  render() {
    switch (this.props.signal) {
      case 'bad Signal':
        return <SignalCellular1BarIcon fontSize="inherit" color="inherit" />;
      case 'poor Signal':
        return <SignalCellular2BarIcon fontSize="inherit" color="inherit" />;
      case 'Good Signal':
        return <SignalCellular3BarIcon fontSize="inherit" color="inherit" />;
      case 'Excellent Signal':
        return <SignalCellular4BarIcon fontSize="inherit" color="inherit" />;
      default:
        return <SignalCellular3BarIcon fontSize="inherit" color="inherit" />;
    }
  }
}
