import React from 'react';
import './monthly-usage-chart.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Chart, Axis, Tooltip, Legend, Point, LineAdvance } from 'bizcharts';

const demoData = [
  {
    month: 'Jan',
    run_duration: 200,
    power_consumption_cost: 500,
    people: 2,
    power_consumption: 69,
  },
  {
    month: 'Feb',
    run_duration: 170,
    power_consumption_cost: 540,
    people: 3,
    power_consumption: 90,
  },
  {
    month: 'Mar',
    run_duration: 190,
    power_consumption_cost: 590,
    people: 5,
    power_consumption: 100,
  },
  {
    month: 'Apr',
    run_duration: 200,
    power_consumption_cost: 450,
    people: 1,
    power_consumption: 65,
  },
  {
    month: 'May',
    run_duration: 220,
    power_consumption_cost: 520,
    people: 3,
    power_consumption: 80,
  },
  {
    month: 'Jun',
    run_duration: 160,
    power_consumption_cost: 480,
    people: 1,
  },
  {
    month: 'Jul',
    run_duration: 250,
    power_consumption_cost: 600,
    people: 2,
  },
];

export default class Doubleaxes extends React.Component {
  state = {
    openInfo: false,
    anchorEl: null,
  };

  handleOpenInfo = (event) => {
    this.setState({
      openInfo: !this.state.openInfo,
      anchorEl: event.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      openInfo: false,
    });
  };
  render() {
    let data = this.props.data?.length > 0 ? this.props.data : demoData;
    // const mode = this.props.mode;
    const describtion = this.props.describtion;
    let chartIns = null;
    const scale = {
      power_consumption_cost: {
        alias: 'Power',
        tickCount: 5,
        min: 0,
        type: 'linear-strict',
      },
      run_duration: {
        alias: 'Time',
        tickCount: 5,
        min: 0,
        type: 'linear-strict',
      },
      month: {
        alias: 'Month',
      },
    };
    const colors = ['#6394f9', '#62daaa'];
    const id = this.state.openInfo ? 'simple-popover' : undefined;
    return (
      <div className="LINE_CHART_MAIN_CONTAINER">
        <div className="LINE_CHART_INFO_CONTAINER">
          <IconButton onClick={this.handleOpenInfo}>
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        </div>
        {this.props.data?.length > 0 ? null : (
          <div className="CHART_WATER_MARK_CONTAINER">
            <p>No data available yet ...</p>
          </div>
        )}

        <Popover
          id={id}
          open={this.state.openInfo}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 1, maxWidth: '250px', color: '#162447' }}>
            {describtion}
          </Typography>
        </Popover>

        <Chart
          scale={scale}
          autoFit
          height={220}
          width={300}
          data={data}
          onGetG2Instance={(chart) => {
            chartIns = chart;
          }}
        >
          <Axis name="power_consumption_cost" />
          <Axis name="run_duration" />
          <Axis name="month" />
          <Legend
            custom={true}
            allowAllCanceled={true}
            itemWidth={100}
            itemHeight={25}
            items={[
              {
                value: 'run_duration',
                name: 'Run duration',
                marker: {
                  symbol: 'circle',
                  style: { stroke: colors[0], r: 5 },
                },
              },
              {
                value: 'power_consumption_cost',
                name: 'Cost',
                marker: {
                  symbol: 'circle',
                  style: { fill: colors[1], r: 5 },
                },
              },
            ]}
            onChange={(ev) => {
              console.log('ev', ev);
              const item = ev.item;
              const value = item.value;
              const checked = !item.unchecked;
              const geoms = chartIns.geometries;

              for (let i = 0; i < geoms.length; i++) {
                const geom = geoms[i];

                if (geom.getYScale().field === value) {
                  if (checked) {
                    geom.show();
                  } else {
                    geom.hide();
                  }
                }
              }
            }}
          />
          <Tooltip shared showCrosshairs />

          <LineAdvance
            position="month*run_duration"
            shape="smooth"
            area
            point
            color={colors[0]}
            tooltip={[
              'month*run_duration',
              (month, run_duration) => {
                return {
                  name: 'Run Time',
                  value: `${run_duration} h`,
                  title: month,
                };
              },
            ]}
          />
          <Point
            position="month*run_duration"
            color={colors[0]}
            size={3}
            shape="circle"
            tooltip={false}
          />
          <LineAdvance
            position="month*power_consumption_cost"
            color={colors[1]}
            size={3}
            shape="smooth"
            area
            point
            tooltip={[
              'month*power_consumption_cost',
              (month, power_consumption_cost) => {
                return {
                  name: 'Cost',
                  value: `R${power_consumption_cost}`,
                  title: month,
                };
              },
            ]}
          />
          <Point
            position="month*power_consumption_cost"
            color={colors[1]}
            size={3}
            shape="circle"
            tooltip={false}
          />
          <Tooltip>
            {(title, items) => {
              return (
                <div className="TOOLTIP_CONTAINER">
                  <span>{title}</span>
                  <div className="TOOLTIP_ITEM">
                    <span>Cost:</span>
                    <p>R&nbsp;{items[0].data.power_consumption_cost}</p>
                  </div>
                  <div className="TOOLTIP_ITEM">
                    <span>Power: &nbsp;</span>
                    <p>{items[0].data.power_consumption}&nbsp;KWh</p>
                  </div>
                  <div className="TOOLTIP_ITEM">
                    <span>Run duration: &nbsp;</span>
                    <p>{items[0].data.run_duration}&nbsp;H</p>
                  </div>
                </div>
              );
            }}
          </Tooltip>
        </Chart>
      </div>
    );
  }
}
