import React from 'react';
import './new-carousel.css';
import Carousel from '../3D-carousel/Carousel';

function NewCarousel(props) {
  return (
    <div className="ANALYTICS_CAROUSEL">
      <Carousel
        height="500px"
        width="100%"
        margin="0 auto"
        offset={3}
        showArrows={false}
        dailyUsage={props.dailyUsage}
      />
    </div>
  );
}

export default NewCarousel;
