import React, { Component } from 'react';
import { connect } from 'react-redux';
import './configs.css';
import ControllerConfigs from '../../../components/configs/controller-configs/ControllerConfigs';
import { getControllers } from '../../../store/actions/controller';
import { getControllerConfigsByIMEI } from '../../../store/actions/controllerConfigs';
import { getControllerParametersByIMEI } from '../../../store/actions/controllerParameter';

class Configs extends Component {
  state = {
    value: '1',
    startTime: new Date(),
    hasError: true,
    minTemperature: 30,
    maxTemperature: 70,
  };

  handleMaxTemperature = (operation) => {
    if (operation === 'add') {
      this.setState({ maxTemperature: this.state.maxTemperature + 5 });
    } else if (operation === 'subtract') {
      this.setState({ maxTemperature: this.state.maxTemperature - 5 });
    }
  };

  handleMinTemperature = (operation) => {
    if (operation === 'add') {
      this.setState({ minTemperature: this.state.minTemperature + 5 });
    } else if (operation === 'subtract') {
      this.setState({ minTemperature: this.state.minTemperature - 5 });
    }
  };

  validateTemperature = () => {
    const { minTemperature, maxTemperature } = this.state;
    if (maxTemperature - minTemperature < 10) {
      // Show a message regarding the appropriate temperature difference
      return false;
    } else if (maxTemperature > 70) {
      // Show a message regarding the maximum temperature
      return false;
    } else if (minTemperature < 30) {
      // Show a message regarding the minimum temperature
      return false;
    } else {
      return true;
    }
  };
  render() {
    return (
      <div className="DASHBOARD_CONTROLLER_CONFIGS_MAIN_CONTAINER">
        {/* <div className="DASHBOARD_SEPERATOR"></div> */}
        <div className="DASHBOARD_CONTROLLER_CONFIGS_MAIN_HEADER_CONTAINER">
          <h2>Configurations</h2>
          {/* <span>Up to date</span> */}
        </div>
        <div className="DASHBOARD_CONTROLLER_CONFIGS_WRAPPER_CONTAINER">
          <ControllerConfigs></ControllerConfigs>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    controllers: state.controller.controllers,
    configs: state.controllerConfigs.controllerConfigs,
    parameters: state.controllerParameters.controllerParameters,
    timeSlot: state.timeSlot.timeSlot,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getControllers: (token) => dispatch(getControllers(token)),
    getParameters: (token, IMEI) =>
      dispatch(getControllerParametersByIMEI(token, IMEI)),
    getConfigs: (token, IMEI) =>
      dispatch(getControllerConfigsByIMEI(token, IMEI)),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Configs);
