import React, { Component } from 'react';
import './performance.css';
import ControllerGraphs from '../../../components/controller-graphs/ControllerGraphs';

export default class Performance extends Component {
  state = {
    scrolling: false,
  };
  render() {
    return (
      <div className="DASHBOARD_GRAPHS_MAIN_CONTAINER">
        <div className="DASHBOARD_GRAPHS_HEADER_CONTAINER">
          <h2>Performance</h2>
        </div>
        <div
          className="DASHBOARD_GRAPHS_WRAPPER"
          onScroll={() => this.setState({ scrolling: true })}
        >
          <ControllerGraphs></ControllerGraphs>
        </div>
      </div>
    );
  }
}
