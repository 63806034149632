import React, { Component } from 'react';
import { connect } from 'react-redux';
import './vacation-mode.css';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { updateControllerConfigsVacationMode } from '../../../../store/actions/controllerConfigs';
import { CircularProgress } from '@mui/material';
import { postRunOrder } from '../../../../store/actions/runOrder';
import { postVModesLog } from '../../../../store/actions/logs';

class VacationMode extends Component {
  handleUpdateVacationMode = () => {
    if (this.props.controllerConfigs) {
      const token = this.props.token;
      const { id, vacation_mode } = this.props.controllerConfigs;
      const IMEI = this.props.activeController?.serial_number;
      this.props.updateVacationMode(token, id, !vacation_mode);
      if (!vacation_mode === true) {
        this.props.createElementOrder(token, false, IMEI, false);
      }
      this.props.createVModeLog(
        token,
        IMEI,
        this.props.parameters?.temperature,
        !vacation_mode
      );
    }
  };

  render() {
    return (
      <div className="VACATION_MODE_MAIN_CONTAINER">
        <div className="VACATION_MODE_BUTTON_CONTAINER">
          <div
            className="VACATION_MODE_BUTTON"
            style={
              this.props.controllerConfigs &&
              this.props.controllerConfigs.vacation_mode === true
                ? { color: '#21E6C1' }
                : { color: '#E94560' }
            }
            onClick={this.handleUpdateVacationMode}
          >
            {this.props.configsLoading ? (
              <CircularProgress color="inherit"></CircularProgress>
            ) : (
              <PowerSettingsNewIcon fontSize="inherit"></PowerSettingsNewIcon>
            )}
          </div>
        </div>
        <div className="VACATION_MODE_INFO_CONTAINER">
          <h1>
            Vacation Mode{' '}
            {this.props.controllerConfigs &&
            this.props.controllerConfigs.vacation_mode === true ? (
              <span style={{ color: '#21E6C1' }}>ON</span>
            ) : (
              <span style={{ color: '#E94560' }}>OFF</span>
            )}
          </h1>
          <p>
            Your geyser will remain OFF during your vacation. Have a great time
          </p>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    configsLoading: state.controllerConfigs.loading,
    activeController: state.controller.controller,
    controllerConfigs: state.controllerConfigs.controllerConfigs,
    parameters: state.controllerParameters.latestControllerParameters,
    mode: state.users.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateVacationMode: (token, controllerConfigs_id, vacation_mode) =>
      dispatch(
        updateControllerConfigsVacationMode(
          token,
          controllerConfigs_id,
          vacation_mode
        )
      ),
    createElementOrder: (token, order, IMEI, publish) =>
      dispatch(postRunOrder(token, order, IMEI, publish)),
    createVModeLog: (token, IMEI, temperature, status) =>
      dispatch(postVModesLog(token, IMEI, temperature, status)),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(VacationMode);
