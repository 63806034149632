import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  supportTicket: null,
  supportTickets: [],
};

const getSupportTicketStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getSupportTicketSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    supportTicket: action.payload,
  });
};

const getSupportTicketsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    supportTickets: action.payload,
  });
};

const getSupportTicketFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const supportTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUPPORT_TICKET_START:
      return getSupportTicketStart(state, action);
    case actionTypes.GET_SUPPORT_TICKET_SUCCESS:
      return getSupportTicketSuccess(state, action);
    case actionTypes.GET_SUPPORT_TICKETS_SUCCESS:
      return getSupportTicketsSuccess(state, action);
    case actionTypes.GET_SUPPORT_TICKET_FAILD:
      return getSupportTicketFaild(state, action);
    default:
      return state;
  }
};

export default supportTicketReducer;
