import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  notifications: null,
  notification: null,
  unreadNotification: null,
  notificationsCount: 0,
};

const getNotificationsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getNotificationsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    notifications: action.payload,
  });
};

const getUnreadNotificationsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    unreadNotifications: action.payload,
  });
};

const getNotificationSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    notification: action.payload,
  });
};

const getNotificationCountSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    notificationsCount: action.payload,
  });
};

const getNotificationsFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS_START:
      return getNotificationsStart(state, action);
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return getNotificationsSuccess(state, action);
    case actionTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS:
      return getUnreadNotificationsSuccess(state, action);
    case actionTypes.GET_NOTIFICATION_SUCCESS:
      return getNotificationSuccess(state, action);
    case actionTypes.GET_NOTIFICATIONS_COUNT_SUCCESS:
      return getNotificationCountSuccess(state, action);
    case actionTypes.GET_NOTIFICATIONS_FAILD:
      return getNotificationsFaild(state, action);
    default:
      return state;
  }
};

export default notificationsReducer;
