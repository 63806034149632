import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  settings: null,
};

const getAppSettingsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getAppSettingsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    settings: action.payload,
  });
};

const getAppSettingsFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const appSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_APP_SETTINGS_START:
      return getAppSettingsStart(state, action);
    case actionTypes.GET_APP_SETTINGS_SUCCESS:
      return getAppSettingsSuccess(state, action);
    case actionTypes.GET_APP_SETTINGS_FAILD:
      return getAppSettingsFaild(state, action);
    default:
      return state;
  }
};

export default appSettingsReducer;
