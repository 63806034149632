// Auth Action Types //
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const UI_MODE_SUCCESS = 'UI_MODE_SUCCESS';

//USER ACTION TYPES//
export const AUTH_DETAILS_SUCCESS = 'AUTH_DETAILS_SUCCESS';
export const AUTH_PROFILE_PIC_SUCCESS = 'AUTH_PROFILE_PIC_SUCCESS';
export const AUTH_VERIFICATION_INFO_SUCCESS = 'AUTH_VERIFICATION_INFO_SUCCESS';
export const AUTH_SEND_VERIFICATION_INFO_SUCCESS =
  'AUTH_SEND_VERIFICATION_INFO_SUCCESS';
export const CHANGE_PASSWORD_RESPONSE = 'CHANGE_PASSWORD_RESPONSE';
export const PROFILE_CHANGE_PASSWORD_RESPONSE =
  'PROFILE_CHANGE_PASSWORD_RESPONSE';
export const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE';

// CONTROLLER ACTION TYPES //
export const GET_CONTROLLER_START = 'GET_CONTROLLER_START';
export const GET_CONTROLLER_SUCCESS = 'GET_CONTROLLER_SUCCESS';
export const GET_CONTROLLERS_SUCCESS = 'GET_CONTROLLERS_SUCCESS';
export const GET_CONTROLLER_META_DATA_SUCCESS =
  'GET_CONTROLLER_META_DATA_SUCCESS';
export const GET_CONTROLLER_FAILD = 'GET_CONTROLLER_FAILD';

// CONTROLLER STATUS ACTION TYPES //
export const GET_CONTROLLER_STATUS_START = 'GET_CONTROLLER_STATUS_START';
export const GET_CONTROLLER_STATUS_SUCCESS = 'GET_CONTROLLER_STATUS_SUCCESS';
export const GET_CONTROLLER_STATUS_FAILD = 'GET_CONTROLLER_STATUS_FAILD';

// CONTROLLER PARAMETERS ACTION TYPES //
export const GET_CONTROLLER_PARAMETERS_START =
  'GET_CONTROLLER_PARAMETERS_START';
export const GET_CONTROLLER_PARAMETERS_SUCCESS =
  'GET_CONTROLLER_PARAMETERS_SUCCESS';
export const GET_CONTROLLER_PARAMETERS_FAILD = 'GET_CONTROLLER_FAILD';

// CONTROLLER PARAMETERS ACTION TYPES //
export const GET_CONTROLLER_CONFIGS_START = 'GET_CONTROLLER_CONFIGS_START';
export const GET_CONTROLLER_CONFIGS_SUCCESS = 'GET_CONTROLLER_CONFIGS_SUCCESS';
export const GET_LATEST_CONTROLLER_PARAMETERS_SUCCESS =
  'GET_LATEST_CONTROLLER_PARAMETERS_SUCCESS';
export const GET_CONTROLLER_CONFIGS_FAILD = 'GET_CONTROLLER_CONFIGS_FAILD';

// RUN SCHEDULE TIME SLOT ACTION TYPE //
export const GET_TIME_SLOT_START = 'GET_TIME_SLOT_START';
export const GET_ALTER_TIME_SLOT_START = 'GET_ALTER_TIME_SLOT_START';
export const GET_TIME_SLOT_SUCCESS = 'GET_TIME_SLOT_SUCCESS';
export const GET_ACTIVE_TIME_SLOT_SUCCESS = 'GET_ACTIVE_TIME_SLOT_SUCCESS';
export const GET_TIME_SLOTS_SUCCESS = 'GET_TIME_SLOTS_SUCCESS';
export const GET_TIME_SLOT_FAILD = 'GET_TIME_SLOT_FAILD';

// RUN ORDER ACTION TYPES //
export const GET_RUN_ORDER_START = 'GET_RUN_ORDER_START';
export const GET_RUN_ORDER_SUCCESS = 'GET_RUN_ORDER_SUCCESS';
export const GET_RUN_ORDER_FAILD = 'GET_RUN_ORDER_FAILD';

// SUPPORT TICKET ACTION TYPES //
export const GET_SUPPORT_TICKET_START = 'GET_SUPPORT_TICKET_START';
export const GET_SUPPORT_TICKET_SUCCESS = 'GET_SUPPORT_TICKET_SUCCESS';
export const GET_SUPPORT_TICKETS_SUCCESS = 'GET_SUPPORT_TICKETS_SUCCESS';
export const GET_SUPPORT_TICKET_FAILD = 'GET_SUPPORT_TICKET_FAILD';

// SUPPORT TICKET ACTION TYPES //
export const GET_SUPPORT_TICKET_REPLY_START = 'GET_SUPPORT_TICKET_REPLY_START';
export const GET_SUPPORT_TICKET_REPLY_SUCCESS =
  'GET_SUPPORT_TICKET_REPLY_SUCCESS';
export const GET_SUPPORT_TICKET_REPLYS_SUCCESS =
  'GET_SUPPORT_TICKET_REPLYS_SUCCESS';
export const GET_SUPPORT_TICKET_REPLY_FAILD = 'GET_SUPPORT_TICKET_REPLY_FAILD';

// CONTROLLER ALERT ACTION TYPES //
export const GET_CONTROLLER_ALERT_START = 'GET_CONTROLLER_ALERT_START';
export const GET_CONTROLLER_ALERT_SUCCESS = 'GET_CONTROLLER_ALERT_SUCCESS';
export const GET_CONTROLLER_ALERTS_SUCCESS = 'GET_CONTROLLER_ALERTS_SUCCESS';
export const GET_CONTROLLER_UNREAD_ALERTS_SUCCESS =
  'GET_CONTROLLER_UNREAD_ALERTS_SUCCESS';
export const GET_ALERTS_COUNT_SUCCESS = 'GET_ALERTS_COUNT_SUCCESS';
export const GET_CONTROLLER_ALERT_FAILD = 'GET_CONTROLLER_ALERT_FAILD';

// NOTIFICATIONS ACTION TYPES //
export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_UNREAD_NOTIFICATIONS_SUCCESS =
  'GET_UNREAD_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATIONS_COUNT_SUCCESS =
  'GET_NOTIFICATIONS_COUNT_SUCCESS';
export const GET_NOTIFICATIONS_FAILD = 'GET_NOTIFICATIONS_FAILD';

// GEYSER USAGE //
export const GET_GEYSER_USAGE_START = 'GET_GEYSER_USAGE_START';
export const GET_GEYSER_USAGE_SUCCESS = 'GET_GEYSER_USAGE_SUCCESS';
export const GET_GEYSER_USAGE_FAILD = 'GET_GEYSER_USAGE_FAILD';

// GEYSER INSIGHTS //
export const GET_INSIGHTS_START = 'GET_INSIGHTS_START';
export const GET_MONTHLY_USAGE_SUCCESS = 'GET_MONTHLY_USAGE_SUCCESS';
export const GET_CURRENT_MONTHLY_USAGE_SUCCESS =
  'GET_CURRENT_MONTHLY_USAGE_SUCCESS';
export const GET_INSIGHTS_FAILD = 'GET_INSIGHTS_FAILD';

// SETTINGS //
export const GET_APP_SETTINGS_START = 'GET_APP_SETTINGS_START';
export const GET_APP_SETTINGS_SUCCESS = 'GET_APP_SETTINGS_SUCCESS';
export const GET_APP_SETTINGS_FAILD = 'GET_APP_SETTINGS_FAILD';

// TERMS AND CONDITIONS //

export const GET_TERMS_AND_CONDITIONS_START = 'GET_TERMS_AND_CONDITIONS_START';
export const GET_TERMS_AND_CONDITIONS_SUCCESS =
  'GET_TERMS_AND_CONDITIONS_SUCCESS';
export const GET_TERMS_AND_CONDITIONS_FAILD = 'GET_TERMS_AND_CONDITIONS_FAILD';

// LOGS ACTION TYPES //

export const GET_LOGS_START = 'GET_LOGS_START';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_TODAY_LOGS_SUCCESS = 'GET_TODAY_LOGS_SUCCESS';
export const GET_WEEK_LOGS_SUCCESS = 'GET_WEEK_LOGS_SUCCESS';
export const GET_MONTH_LOGS_SUCCESS = 'GET_MONTH_LOGS_SUCCESS';
export const GET_CUSTOM_LOGS_SUCCESS = 'GET_CUSTOM_LOGS_SUCCESS';
export const GET_LOGS_FAILD = 'GET_LOGS_FAILD';
