import * as React from 'react';
import { withRouter } from 'react-router-dom';
import './drawer.css';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import CircleIcon from '@mui/icons-material/Circle';
// import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import FitbitOutlinedIcon from '@mui/icons-material/FitbitOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArticleIcon from '@mui/icons-material/Article';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Avatar from '@mui/material/Avatar';

function Drawer(props) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleNavigation = (path) => {
    props.history.push(path);
  };

  const navigate = (path) => {
    props.history.push(path);
    props.toggleDrawer();
  };

  const handleLogout = () => {
    props.logout();
    localStorage.removeItem('token');
    handleNavigation('/login');
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 280,
      }}
      id={props.mode}
      className="DRAWER_LIST_CONTAINER"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="DRAWER_HEADER_CONTAINER">
        <div
          className="DRAWER_HEADER_PROFILE_AVATAR"
          onClick={() => navigate('/profile')}
        >
          <Avatar
            alt={props.username?.toUpperCase()}
            src=""
            sx={{ width: 60, height: 60, backgroundColor: 'inherit' }}
          />
        </div>
      </div>
      <List>
        <ListItem
          disablePadding
          className="DRAWER_LIST_ITEM"
          onClick={() => navigate('/notifications')}
        >
          <ListItemButton>
            <ListItemIcon>
              <NotificationsActiveIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#1F4068' }
                    : { color: '#BBE1FA' }
                }
              ></NotificationsActiveIcon>
            </ListItemIcon>
            <ListItemText primary="Notifications" color="inherit" />
            <Badge
              color="error"
              badgeContent={props.unreadNotifications?.length}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            ></Badge>
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          className="DRAWER_LIST_ITEM"
          onClick={() => navigate('/alerts')}
        >
          <ListItemButton>
            <ListItemIcon>
              <CrisisAlertIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#D65A31' }
                    : { color: '#FFD700' }
                }
              ></CrisisAlertIcon>
            </ListItemIcon>
            <ListItemText primary="Alerts" color="inherit" />
            <Badge
              color="error"
              badgeContent={props.unreadAlerts?.length}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            ></Badge>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className="DRAWER_LIST_ITEM">
          <ListItemButton>
            <ListItemIcon>
              <MailIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#162e4c' }
                    : { color: '#bbbbbb' }
                }
              ></MailIcon>
            </ListItemIcon>
            <ListItemText primary="Messages" color="inherit" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          disablePadding
          className="DRAWER_LIST_ITEM"
          onClick={() => navigate('/select-controller')}
        >
          <ListItemButton>
            <ListItemIcon>
              <CircleIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#162e4c' }
                    : { color: '#bbbbbb' }
                }
              />
            </ListItemIcon>
            <ListItemText primary="My Geysers" color="inherit" />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          className="DRAWER_LIST_ITEM"
          onClick={() => navigate('/settings')}
        >
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#162e4c' }
                    : { color: '#bbbbbb' }
                }
              />
            </ListItemIcon>
            <ListItemText primary="Settings" color="inherit" />
          </ListItemButton>
        </ListItem>
        {/* <Divider />
        <ListItem
          disablePadding
          className="DRAWER_LIST_ITEM"
          onClick={() => navigate('/analytics')}
        >
          <ListItemButton>
            <ListItemIcon>
              <BarChartOutlinedIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#162e4c' }
                    : { color: '#bbbbbb' }
                }
              />
            </ListItemIcon>
            <ListItemText primary="Analytics" color="inherit" />
          </ListItemButton>
        </ListItem> */}
        <ListItem
          disablePadding
          className="DRAWER_LIST_ITEM"
          onClick={() => navigate('/logs')}
        >
          <ListItemButton>
            <ListItemIcon>
              <FitbitOutlinedIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#162e4c' }
                    : { color: '#bbbbbb' }
                }
              />
            </ListItemIcon>
            <ListItemText primary="Run Logs" color="inherit" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding
          className="DRAWER_LIST_ITEM"
          onClick={() => navigate('/user-guide')}
        >
          <ListItemButton>
            <ListItemIcon>
              <SummarizeIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#162e4c' }
                    : { color: '#bbbbbb' }
                }
              />
            </ListItemIcon>
            <ListItemText primary="User Guide" color="inherit" />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          className="DRAWER_LIST_ITEM"
          onClick={() => navigate('/support')}
        >
          <ListItemButton>
            <ListItemIcon>
              <SupportAgentIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#162e4c' }
                    : { color: '#bbbbbb' }
                }
              />
            </ListItemIcon>
            <ListItemText primary="Support" color="inherit" />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          className="DRAWER_LIST_ITEM"
          onClick={() => navigate('/terms-and-conditions')}
        >
          <ListItemButton>
            <ListItemIcon>
              <ArticleIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#162e4c' }
                    : { color: '#bbbbbb' }
                }
              />
            </ListItemIcon>
            <ListItemText primary="Terms & Conditions" color="inherit" />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding
          className="DRAWER_LIST_ITEM"
          onClick={() => handleLogout()}
        >
          <ListItemButton>
            <ListItemIcon>
              <ExitToAppIcon
                sx={
                  props.mode === 'light'
                    ? { color: '#162e4c' }
                    : { color: '#bbbbbb' }
                }
              />
            </ListItemIcon>
            <ListItemText primary="Sign Out" color="inherit" />
          </ListItemButton>
        </ListItem>
      </List>
      <div className="DRAWER_SECOND_LIST_CONTAINER"></div>
    </Box>
  );

  return (
    <div className="DRAWER_MAIN_CONTAINER">
      <React.Fragment>
        <SwipeableDrawer
          anchor="left"
          open={props.drawerOpen}
          onClose={props.toggleDrawer}
          onOpen={props.toggleDrawer}
        >
          {list('left')}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

export default withRouter(Drawer);
