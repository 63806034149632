import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/preview-ticket.css';
// import moment from 'moment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import TicketReply from './ticket-reply/TicketReply';
import { postSupportTicketReplyData } from '../../store/actions/supportTicketReply';
import { getSupportTicket } from '../../store/actions/support';
import { CircularProgress } from '@mui/material';

class PreviewTicket extends Component {
  state = {
    ticketReply: '',
  };

  componentDidUpdate(prevProps) {
    if (this.props.ticketReply !== prevProps.ticketReply) {
      const token = this.props.token;
      const ticket_id = this.props.ticket.id;
      this.props.getSupportTicket(token, ticket_id);
    }
    if (this.props.ticket !== prevProps.ticket) {
      this.setState({ ticketReply: '' });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePostReply = () => {
    const token = this.props.token;
    const replyContent = this.state.ticketReply;
    const ticket_id = this.props.ticket.id;
    if (replyContent !== '') {
      this.props.postTicketReply(token, replyContent, ticket_id);
    }
  };

  render() {
    return (
      <div className="SUPPORT_PREVIEW_TICKET_CONTAINER">
        <div className="SUPPORT_PREVIEW_TICKET_CONTENT_CONTAINER">
          <div className="SUPPORT_PREVIEW_TICKET_REPLY_FORM_CONTAINER">
            <div className="SUPPORT_SUBMIT_REPLY_BODY">
              <TextField
                id="outlined-multiline-flexible"
                label="Reply Content"
                name="ticketReply"
                multiline
                fullWidth
                rows={4}
                value={this.state.ticketReply}
                onChange={this.onChange}
                InputProps={{
                  className: 'SUPPORT_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'SUPPORT_FORM_INPUT_FIELD_LABEL',
                }}
              />
            </div>
            <div className="CONTROL_DASHBOARD_SUBMIT_REPLY_BUTTON">
              {this.props.ticketLoading ? (
                <CircularProgress></CircularProgress>
              ) : (
                <Button
                  variant="contained"
                  sx={{ borderRadius: 20 }}
                  onClick={this.handlePostReply}
                >
                  Send
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    ticket: state.support.supportTicket,
    ticketReply: state.supportTicketReplys.supportTicketReply,
    ticketLoading: state.supportTicketReplys.loading,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSupportTicket: (token, ticket_id) =>
      dispatch(getSupportTicket(token, ticket_id)),
    postTicketReply: (token, content, ticket_id) =>
      dispatch(postSupportTicketReplyData(token, content, ticket_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewTicket);
