import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/auth';
import './styles/signup.css';
import { CircularProgress } from '@mui/material';
import Lottie from 'lottie-react';
import account from './lottiefiles/account.json';
import { postAppSettings } from '../../store/actions/appSettings';

class Signup extends Component {
  state = {
    username: '',
    email: '',
    password1: '',
    password2: '',
    errors: {},
    termsOfService: '',
    usernameFieldsCheck: false,
    emailFieldsCheck: false,
    password1FieldsCheck: false,
    password2FieldsCheck: false,
    P1P2Match: false,
    passwordMatchAlert: false,
    goToProfile: false,
    disableBtn: false,
  };

  componentDidMount() {
    const verificationSentStatus = localStorage.getItem('verification-sent');
    if (verificationSentStatus == null) {
      localStorage.setItem('verification-sent', false);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token && this.props.token) {
      this.props.createAppSetting(this.props.token);
      this.props.createTheme(this.props.token);
      this.props.history.push('/verify-account');
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { username, email, password1, password2, P1P2Match } = this.state;

    if (username === '') {
      this.setState({ errors: { username: 'Username field is required' } });
      this.setState({ usernameFieldsCheck: true });
      return;
    }
    if (email === '') {
      this.setState({ errors: { email: 'Email field is required' } });
      this.setState({ emailFieldsCheck: true });
      return;
    }
    if (password1 === '') {
      this.setState({ errors: { password1: 'Password field is required' } });
      this.setState({ password1FieldsCheck: true });
      return;
    }
    if (password2 === '') {
      this.setState({
        errors: { password2: 'You need to verify your password' },
      });
      this.setState({ password2FieldsCheck: true });

      return;
    }
    if (P1P2Match === true) {
      this.setState({ passwordMatchAlert: true });
      return;
    }
    this.setState({ errors: {} });
    // this.setState({ fieldsCheck: false });
    this.setState({ disableBtn: true });

    console.log('all fields are vaild');
    this.props.onAuth(
      username.toLowerCase(),
      email.toLowerCase(),
      password1,
      password2
    );
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ [e.target.name + 'FieldsCheck']: false });

    this.setState({ passwordMatchAlert: false });
    this.setState({ errors: {} });
  };

  onPassword1Change = (e) => {
    this.setState({ passwordMatchAlert: false });
    if (e.target.value !== this.state.password2) {
      this.setState({ P1P2Match: true });
    } else {
      this.setState({ P1P2Match: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  onPassword2Change = (e) => {
    this.setState({ passwordMatchAlert: false });
    if (e.target.value !== this.state.password1) {
      this.setState({ P1P2Match: true });
    } else {
      this.setState({ P1P2Match: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  backToLogin = () => {
    this.props.history.push('/login');
  };

  render() {
    return (
      <div className="SIGNUP_MAIN_CONTAINER">
        <div className="SIGNUP_FORM_CONTAINER">
          <div className="SIGNUP_ICON_CONTAINER">
            <Lottie
              animationData={account}
              loop={false}
              autoplay={true}
              height={150}
              width={150}
            />
          </div>
          <div className="SIGNUP_FORM_HEADER">
            <h2>Welcome to TopioT</h2>
          </div>
          <div>
            {this.props.userLoading ? (
              <CircularProgress></CircularProgress>
            ) : null}
          </div>
          <form onSubmit={this.onSubmit} className="form-signup">
            <div className="SIGNUP_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="username"
                label={
                  this.state.errors.username
                    ? this.state.errors.username
                    : 'Username'
                }
                variant="outlined"
                error={this.state.usernameFieldsCheck}
                name="username"
                fullWidth
                value={this.state.username}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'SIGNUP_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SIGNUP_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>

            {this.props.error ? (
              <span className="SIGNUP_ERROR_MESSAGE" id="passwordMatchAlert">
                {this.props.error.response
                  ? this.props.error.response.data['username']
                    ? this.props.error.response.data['username'][0]
                    : null
                  : null}
              </span>
            ) : null}

            <div className="SIGNUP_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="email"
                label={
                  this.state.errors.email ? this.state.errors.email : 'Email'
                }
                variant="outlined"
                error={this.state.emailFieldsCheck}
                name="email"
                fullWidth
                value={this.state.email}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'SIGNUP_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SIGNUP_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>

            {this.props.error ? (
              <span className="SIGNUP_ERROR_MESSAGE" id="passwordMatchAlert">
                {this.props.error.response
                  ? this.props.error.response.data['email']
                    ? this.props.error.response.data['email'][0]
                    : null
                  : null}
              </span>
            ) : null}

            <div className="SIGNUP_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="password1"
                type="password"
                label={
                  this.state.errors.password1
                    ? this.state.errors.password1
                    : 'Password (8 Characters or more)'
                }
                variant="outlined"
                error={this.state.password1FieldsCheck}
                name="password1"
                fullWidth
                value={this.state.password1}
                onChange={this.onPassword1Change}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'SIGNUP_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SIGNUP_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>

            {this.props.error ? (
              <span className="SIGNUP_ERROR_MESSAGE" id="passwordMatchAlert">
                {this.props.error.response
                  ? this.props.error.response.data['password1']
                    ? this.props.error.response.data['password1'][0]
                    : null
                  : null}
              </span>
            ) : null}
            <div className="SIGNUP_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="password2"
                type="password"
                label={
                  this.state.errors.password2
                    ? this.state.errors.password2
                    : 'Verify Password'
                }
                variant="outlined"
                error={this.state.password2FieldsCheck}
                name="password2"
                fullWidth
                value={this.state.password2}
                onChange={this.onPassword2Change}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'SIGNUP_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SIGNUP_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>

            {this.state.passwordMatchAlert ? (
              <span className="SIGNUP_ERROR_MESSAGE" id="passwordMatchAlert">
                Password doesn't match
              </span>
            ) : null}

            <div className="SIGNUP_BUTTON_CONTAINER" onClick={this.onSubmit}>
              <h6>Sign Up</h6>
            </div>
            <div className="BACK_BUTTON_CONTAINER" onClick={this.backToLogin}>
              <h6>Login</h6>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.users.loading,
    error: state.users.error,
    userDetails: state.users.userDetails,
    userLoading: state.users.loading,
    verificationInfo: state.users.verificationInfo,
    token: state.users.token,
    verificationInfoSent: state.users.verificationInfoSent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, email, password1, password2, key) =>
      dispatch(actions.authSignup(username, email, password1, password2, key)),
    getUserDetails: (token) => dispatch(actions.getUserDetails(token)),
    createAppSetting: (token) => dispatch(postAppSettings(token)),
    createTheme: (token) => dispatch(actions.postTheme(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
