import React, { Component } from 'react';
import { connect } from 'react-redux';
import './temp-gauge.css';
import CircularSlider from 'advanced-react-circular-slider';
import 'advanced-react-circular-slider/main.css';
import { updateControllerConfigsWantedTemp } from '../../../../store/actions/controllerConfigs';

class TempGauge extends Component {
  state = {
    wantedTemp: null,
    intervalId: 2,
    index: null,
    deviceType: 'Mobile',
  };

  componentDidMount() {
    this.deviceDetector();
    if (this.props.controllerConfigs) {
      const { wanted_temp } = this.props.controllerConfigs;
      const index = Number((wanted_temp - 25) / 5) + 1;
      this.setState({
        wantedTemp: wanted_temp,
        index: index,
      });
    }
    const newIntervalId = setInterval(() => {
      this.handleUpdateTempData();
    }, 1000);

    this.setState((prevState) => {
      return {
        ...prevState,
        intervalId: newIntervalId,
      };
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.controllerConfigs !== prevProps.controllerConfigs &&
      this.props.controllerConfigs
    ) {
      const { wanted_temp } = this.props.controllerConfigs;
      const index = Number((wanted_temp - 25) / 5) + 1;
      this.setState({
        wantedTemp: wanted_temp,
        index: index,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  deviceDetector = () => {
    const userAgent = navigator.userAgent;
    const isMobile = /Mobi/i.test(userAgent);
    const isTablet = /Tablet/i.test(userAgent);
    const isDesktop = !isMobile && !isTablet;

    if (isMobile) {
      this.setState({ deviceType: 'Mobile' });
    } else if (isTablet) {
      this.setState({ deviceType: 'Tablet' });
    } else if (isDesktop) {
      this.setState({ deviceType: 'Desktop or Laptop' });
    } else {
      this.setState({ deviceType: 'Unknown' });
    }
  };

  handleChange = (value) => {
    this.setState({ wantedTemp: value.value });
  };

  handleUpdateTempData = () => {
    if (this.props.controllerConfigs) {
      const token = this.props.token;
      const { id, wanted_temp } = this.props.controllerConfigs;
      const { wantedTemp } = this.state;
      if (wantedTemp !== wanted_temp) {
        this.props.updateTempData(token, id, wantedTemp);
      }
    }
  };

  render() {
    return (
      <div style={{ position: 'relative' }}>
        {this.state.deviceType === 'Mobile' ||
        this.state.deviceType === 'Tablet' ? (
          <div className="GAUGE_COVER"></div>
        ) : null}
        {this.state.index ? (
          <CircularSlider
            labelTop="Temp"
            labelBottom="°C"
            // appendToValue="-"
            // prependToValue="-"
            labelColor={this.props.mode === 'light' ? '#162e4c' : '#ececec'}
            secondaryLabelColor={
              this.props.mode === 'light' ? '#193557' : '#bcbcbc'
            }
            roundLabelColor={
              this.props.mode === 'light' ? '#162e4c' : '#ececec'
            }
            activedlabelColor={'F05454'}
            width={300}
            min={25}
            max={65}
            step={5}
            limit={360}
            labelStep={5}
            dataIndex={this.state.index}
            onChange={(value) => this.handleChange(value)}
            progressColorFrom="#8FD6E1"
            progressColorTo="#F05454"
            doubleLineType="track"
            doubleLineColor={
              this.props.mode === 'light' ? '#F05454' : '#F05454'
            }
            magentTolerance={20}
          ></CircularSlider>
        ) : null}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    controllerConfigs: state.controllerConfigs.controllerConfigs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTempData: (token, controllerConfigs_id, wanted_temp) =>
      dispatch(
        updateControllerConfigsWantedTemp(
          token,
          controllerConfigs_id,
          wanted_temp
        )
      ),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(TempGauge);
