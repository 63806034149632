import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/user-guide.css';
import UserGuideItem from '../../components/user-guide/user-guide-item/UserGuideItem';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CloudQueueRoundedIcon from '@mui/icons-material/CloudQueueRounded';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import TempMeter from '../../components/user-guide/temp-meter/TempMeter';

class UserGuide extends Component {
  render() {
    return (
      <div className="USER_GUIDE_CONTAINER" id={this.props.mode}>
        <div className="USER_GUIDE_HEADER">
          <h1>USER GUIDE</h1>
        </div>
        <div className="USER_GUIDE_ITEMS_CONTAINER">
          <UserGuideItem
            title="Controller Status"
            pic={
              <CloudQueueRoundedIcon
                fontSize="inherit"
                color="inherit"
              ></CloudQueueRoundedIcon>
            }
            description="This display shows the status of the Smart Geyser control unit, which is determined by its connection to the cloud server. The status can be either 'Online' or 'Offline'."
          ></UserGuideItem>
          <UserGuideItem
            title="Network Signal"
            pic={
              <SignalCellular3BarIcon
                fontSize="inherit"
                color="inherit"
              ></SignalCellular3BarIcon>
            }
            description="This display indicates the strength of the network signal, with a greater number of bars representing a stronger signal and fewer bars indicating a weaker signal."
          ></UserGuideItem>
          <UserGuideItem
            title="Temperature Gauge"
            pic={
              <TempMeter
                temperature={25}
                textColor={this.props.mode === 'light' ? '#082032' : '#dddddd'}
              ></TempMeter>
            }
            description="The temperature meter displays the current temperature of the water in your geyser."
          ></UserGuideItem>
          <UserGuideItem
            title="Element Status"
            pic={
              <WhatshotOutlinedIcon
                fontSize="inherit"
                color="inherit"
              ></WhatshotOutlinedIcon>
            }
            description="This indicator shows the current status of the geyser's heating element. When the geyser is turned on, the display will indicate 'ON', and when the geyser is turned off, it will indicate 'OFF'."
          ></UserGuideItem>
          <UserGuideItem
            title="Element Power"
            pic={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="DASHBOARD_CONTROLLER_PARAMETER_INFO">
                  <span style={{ marginTop: '1em', fontSize: '0.9rem' }}>
                    CONSUMING
                  </span>
                </div>
                <div className="DASHBOARD_CONTROLLER_PARAMETER_INDICATOR">
                  <span style={{ fontSize: '1.2rem', marginTop: '0.7em' }}>
                    3.00 KW
                  </span>
                  <p style={{ fontSize: '0.8rem' }}>Power in KW</p>
                </div>
              </div>
            }
            description="This indicates the amount of power in KWh consumed by the geyser heating element."
          ></UserGuideItem>
          <UserGuideItem
            title="Power ON"
            pic={
              <PowerSettingsNewIcon
                fontSize="inherit"
                sx={{ color: '#21E6C1' }}
              ></PowerSettingsNewIcon>
            }
            description="This button functions as a power switch and indicator for the geyser. It enables manual control of the geyser's operation, and the color green on the indicator signifies that the geyser is powered ON."
          ></UserGuideItem>
          <UserGuideItem
            title="Power OFF"
            pic={
              <PowerSettingsNewIcon
                fontSize="inherit"
                sx={{ color: '#E94560' }}
              ></PowerSettingsNewIcon>
            }
            description="This button functions as a power switch and indicator for the geyser. It enables manual control of the geyser's operation, and the color red on the indicator signifies that the geyser is powered OFF."
          ></UserGuideItem>
          <UserGuideItem
            title="Standby Mode"
            pic={
              <PowerSettingsNewIcon
                fontSize="inherit"
                sx={{ color: '#e1ad03' }}
              ></PowerSettingsNewIcon>
            }
            description="This button serves as both the power switch and indicator for the geyser. It allows you to manually control the geyser's operation, while the blinking light indicates that it is in Standby mode. Standby mode is activated as the system verifies the geyser's updated power status."
          ></UserGuideItem>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGuide);
