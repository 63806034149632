import React from 'react';
import { useState } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';

export default function Places(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
    libraries: ['places'],
  });
  const [selected, setSelected] = useState(null);
  // if (!isLoaded) return <div>Preparing Field ....</div>;

  return (
    <>
      {isLoaded ? (
        <PlacesAutocomplete
          setSelected={setSelected}
          isLoaded={isLoaded}
          addressError={props.addressError}
          onSelectAddress={props.onSelectAddress}
          onClearAddress={props.onClearAddress}
          setGeoData={props.setGeoData}
        />
      ) : (
        <AddressField
          addressError={props.addressError}
          onSelectAddress={props.onSelectAddress}
          onClearAddress={props.onClearAddress}
        ></AddressField>
      )}
    </>
  );
}

const PlacesAutocomplete = ({
  setSelected,
  isLoaded,
  addressError,
  onSelectAddress,
  onClearAddress,
  setGeoData,
}) => {
  // const [address, setAddress] = useState('');
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (e, option) => {
    onSelectAddress(e, option);
    const address = option?.description;
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    console.log(results);
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    setGeoData(parseFloat(lat.toFixed(10)), parseFloat(lng.toFixed(10)));
    console.log(lat, lng);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.description,
  });

  return (
    <Autocomplete
      id="select-address"
      options={data}
      getOptionLabel={(option) =>
        option.description ? option.description : value
      }
      filterOptions={filterOptions}
      sx={{ color: '#fff', width: '100%' }}
      onOpen={() => {
        console.log('options');
      }}
      onClose={() => {
        console.log('close');
      }}
      disabled={!ready}
      clearIcon={<CloseIcon color="error" onClose={onClearAddress}></CloseIcon>}
      openOnFocus={true}
      value={value}
      onChange={(e, option) => handleSelect(e, option)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Address"
          name="address"
          fullWidth
          required
          disabled={!ready}
          placeholder={addressError.des}
          error={addressError.value}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {!isLoaded ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            className: 'SIGNUP_INPUT_FIELD',
          }}
          InputLabelProps={{
            // shrink: true,
            className: 'SIGNUP_INPUT_FIELD_LABEL',
          }}
        />
      )}
    />
  );
};

const AddressField = ({ addressError, onSelectAddress, onClearAddress }) => {
  const [address, setAddress] = useState('');
  const handleChange = (e) => {
    setAddress(e.target.value);
    onSelectAddress(e, { description: e.target.value });
  };
  return (
    <TextField
      id="geyser-address"
      label="Address"
      variant="outlined"
      name="address"
      placeholder={addressError.des}
      error={addressError.value}
      required
      fullWidth
      value={address}
      onChange={handleChange}
      // helperText="Please select your currency"
      InputProps={{
        className: 'SIGNUP_INPUT_FIELD',
      }}
      InputLabelProps={{
        shrink: true,
        className: 'SIGNUP_INPUT_FIELD_LABEL',
      }}
    ></TextField>
  );
};
