import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getControllerParametersStart = () => {
  return {
    type: actionTypes.GET_CONTROLLER_PARAMETERS_START,
  };
};

export const getControllerParametersSuccess = (data) => {
  return {
    type: actionTypes.GET_CONTROLLER_PARAMETERS_SUCCESS,
    payload: data,
  };
};

export const getLatestControllerParametersSuccess = (data) => {
  return {
    type: actionTypes.GET_LATEST_CONTROLLER_PARAMETERS_SUCCESS,
    payload: data,
  };
};

export const getControllerParametersFaild = () => {
  return {
    type: actionTypes.GET_CONTROLLER_PARAMETERS_FAILD,
  };
};

export const getControllerParameters = (token, id) => {
  return (dispatch) => {
    dispatch(getControllerParametersStart());

    axios
      .get(`${HOST_URL}/controller-parameters/parameters/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) =>
        dispatch(getControllerParametersSuccess(response.data))
      )
      .catch((error) => dispatch(getControllerParametersFaild(error)));
  };
};

export const getControllerParametersByIMEI = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getControllerParametersStart());

    axios
      .get(`${HOST_URL}/controller-parameters/parameters/IMEI_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) =>
        dispatch(getControllerParametersSuccess(response.data))
      )
      .catch((error) => dispatch(getControllerParametersFaild(error)));
  };
};

export const getLatestControllerParametersByIMEI = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getControllerParametersStart());

    axios
      .get(`${HOST_URL}/controller-parameters/parameters/last_IMEI_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) =>
        dispatch(getLatestControllerParametersSuccess(response.data))
      )
      .catch((error) => dispatch(getControllerParametersFaild(error)));
  };
};
