import { combineReducers } from 'redux';
import authReducer from './auth';
import controllerReducer from './controller';
import timeSlotReducer from './runSchedule';
import controllerParametersReducer from './controllerParameters';
import controllerConfigsReducer from './controllerConfigs';
import controllerAlertReducer from './alerts';
import supportTicketReducer from './support';
import SupportTicketReplyReducer from './supportTicketReply';
import runOrderReducer from './runOrder';
import controllerStatusReducer from './controllerStatus';
import notificationsReducer from './notifications';
import geyserUsageReducer from './geyserUsage';
import appSettingsReducer from './appSettings';
import termsReducer from './termsAndConditions';
import geyserInsightsReducer from './insights';
import geyserLogsReducer from './logs';

export default combineReducers({
  users: authReducer,
  controller: controllerReducer,
  controllerStatus: controllerStatusReducer,
  controllerParameters: controllerParametersReducer,
  runOrder: runOrderReducer,
  controllerConfigs: controllerConfigsReducer,
  timeSlot: timeSlotReducer,
  alerts: controllerAlertReducer,
  support: supportTicketReducer,
  supportTicketReplys: SupportTicketReplyReducer,
  notifications: notificationsReducer,
  geyserUsage: geyserUsageReducer,
  appSettings: appSettingsReducer,
  termsAndConditions: termsReducer,
  geyserAnalytics: geyserInsightsReducer,
  geyserLogs: geyserLogsReducer,
});
