import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  runOrder: null,
};

const getRunOrderStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getRunOrderSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    runOrder: action.payload,
  });
};

const getRunOrderFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const runOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RUN_ORDER_START:
      return getRunOrderStart(state, action);
    case actionTypes.GET_RUN_ORDER_SUCCESS:
      return getRunOrderSuccess(state, action);
    case actionTypes.GET_RUN_ORDER_FAILD:
      return getRunOrderFaild(state, action);
    default:
      return state;
  }
};

export default runOrderReducer;
