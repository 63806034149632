import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  controllerConfigs: null,
};

const getControllerConfigsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getControllerConfigsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    controllerConfigs: action.payload,
  });
};

const getControllerConfigsFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const controllerConfigsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTROLLER_CONFIGS_START:
      return getControllerConfigsStart(state, action);
    case actionTypes.GET_CONTROLLER_CONFIGS_SUCCESS:
      return getControllerConfigsSuccess(state, action);
    case actionTypes.GET_CONTROLLER_CONFIGS_FAILD:
      return getControllerConfigsFaild(state, action);
    default:
      return state;
  }
};

export default controllerConfigsReducer;
