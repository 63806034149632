import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/style.css';
import * as actions from '../../store/actions/auth';
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';
import LockIcon from '@mui/icons-material/Lock';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

class PasswordReset extends Component {
  state = {
    email: '',
    emailError: false,
    message: {},
    showMessage: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      if (this.props.response.status !== 200) {
        const message = this.props.response.response?.data.email['email'];
        this.setState({
          message: { email: message },
          showMessage: true,
        });
      } else if (this.props.response.status === 200) {
        const message = this.props.response?.data['detail'];
        this.setState({
          message: { email: message },
          showMessage: true,
        });
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { email } = this.state;

    if (email === '') {
      this.setState({
        errors: { email: 'Email field is required' },
        emailError: true,
        showMessage: true,
      });
      return;
    }
    this.setState({ errors: {} });

    this.sendResetEmail();
  };

  sendResetEmail() {
    this.props.sendResetEmail(this.state.email);
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      emailError: false,
      showMessage: false,
    });
  };

  render() {
    return (
      <div className="FORGOT_PASSWORD_PAGE_CONTAINER">
        <form className="FORGOT_PASSWORD_FORM" onSubmit={this.onSubmit}>
          <div className="FORGOT_PASSWORD_LOADING_CONTAINER">
            {this.props.loading ? (
              <CircularProgress color="success"></CircularProgress>
            ) : (
              <LockIcon fontSize="inherit" color="inherit"></LockIcon>
            )}
          </div>
          <div className="INPUT_FIELD_FRAME">
            <TextField
              id="outlined-reset-pw"
              label="Your E-mail"
              variant="outlined"
              // color="secondary"
              type="email"
              name="email"
              fullWidth
              required
              error={this.state.emailError ? true : false}
              value={this.state.email}
              onChange={(e) => this.onChange(e)}
              InputProps={{
                className: 'FORGOT_PASSWORD_INPUT_FIELD',
              }}
              InputLabelProps={{
                shrink: true,
                className: 'FLOATING_LABLE_STYLE',
              }}
            />
          </div>
          <div
            className="FORGOT_PASSWORD_ERROR_CONTAINER"
            style={
              this.state.showMessage
                ? { display: 'flex', marginTop: '15px' }
                : { display: 'none' }
            }
          >
            <p>{this.state.message.email}</p>
          </div>
          <div className="FORGOT_PASSWORD_FORM_BUTTON" onClick={this.onSubmit}>
            <h6>Send</h6>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.error,
    token: state.users.token,
    loading: state.users.loading,
    response: state.users.resetPWResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendResetEmail: (email) => dispatch(actions.resetPassword(email)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
