import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getLogsStart = () => {
  return {
    type: actionTypes.GET_LOGS_START,
  };
};

export const getTodayLogsSuccess = (data) => {
  return {
    type: actionTypes.GET_TODAY_LOGS_SUCCESS,
    payload: data,
  };
};
export const getWeekLogsSuccess = (data) => {
  return {
    type: actionTypes.GET_WEEK_LOGS_SUCCESS,
    payload: data,
  };
};
export const getMonthLogsSuccess = (data) => {
  return {
    type: actionTypes.GET_MONTH_LOGS_SUCCESS,
    payload: data,
  };
};
export const getCustomLogsSuccess = (data) => {
  return {
    type: actionTypes.GET_CUSTOM_LOGS_SUCCESS,
    payload: data,
  };
};
export const getLogsSuccess = (data) => {
  return {
    type: actionTypes.GET_LOGS_SUCCESS,
    payload: data,
  };
};

export const getLogsFaild = () => {
  return {
    type: actionTypes.GET_LOGS_FAILD,
  };
};

export const getLogsByIMEI = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getLogsStart());

    axios
      .get(`${HOST_URL}/monitor/run-logs/IMEI_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getLogsSuccess(response.data)))
      .catch((error) => dispatch(getLogsFaild(error)));
  };
};

export const getLogsByDate = (token, IMEI, startDate, endDate) => {
  return (dispatch) => {
    dispatch(getLogsStart());

    axios
      .get(
        `${HOST_URL}/monitor/run-logs/date_${IMEI}_${startDate}_${endDate}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getCustomLogsSuccess(response.data)))
      .catch((error) => dispatch(getLogsFaild(error)));
  };
};

export const getTodyLogsByDate = (token, IMEI, startDate, endDate) => {
  return (dispatch) => {
    dispatch(getLogsStart());

    axios
      .get(
        `${HOST_URL}/monitor/run-logs/date_${IMEI}_${startDate}_${endDate}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getTodayLogsSuccess(response.data)))
      .catch((error) => dispatch(getLogsFaild(error)));
  };
};

export const getWeekLogsByDate = (token, IMEI, startDate, endDate) => {
  return (dispatch) => {
    dispatch(getLogsStart());

    axios
      .get(
        `${HOST_URL}/monitor/run-logs/date_${IMEI}_${startDate}_${endDate}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getWeekLogsSuccess(response.data)))
      .catch((error) => dispatch(getLogsFaild(error)));
  };
};

export const getMonthLogsByDate = (token, IMEI, startDate, endDate) => {
  return (dispatch) => {
    dispatch(getLogsStart());

    axios
      .get(
        `${HOST_URL}/monitor/run-logs/date_${IMEI}_${startDate}_${endDate}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getMonthLogsSuccess(response.data)))
      .catch((error) => dispatch(getLogsFaild(error)));
  };
};

export const postVModesLog = (token, IMEI, temperature, status) => {
  return (dispatch) => {
    dispatch(getLogsStart());
    let fd = new FormData();
    fd.append('IMEI', IMEI);
    fd.append('temperature', temperature);
    fd.append('status', status);
    axios
      .post(`${HOST_URL}/monitor/v-mode-log/`, fd, {
        headers: { Authorization: `Token ${token}` },
      })
      .then()
      .catch((error) => dispatch(getLogsFaild(error)));
  };
};
