import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/client-profile.css';
import TextField from '@mui/material/TextField';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Button, CircularProgress } from '@mui/material';
import ProfileController from '../../components/profile-controller/ProfileController';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';

import SaveIcon from '@mui/icons-material/Save';
import {
  postUserMetaData,
  updateUserDetails,
  updateUserMetaData,
} from '../../store/actions/auth';
import { getControllers, getController } from '../../store/actions/controller';

class Profile extends Component {
  state = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    country: '',
    address: '',
    suburb: '',
    city: '',
    province: '',
    postalCode: '',
    question: '',
    newControllerLable: '',
    newControllerLocation: '',
    newControllerRate: '',
    addNewController: false,
    shouldSave: false,
  };

  componentDidMount() {
    const userMetaData = this.props.userMetaData;
    if (this.props.userDetails && userMetaData) {
      this.setState({
        firstName: this.props.userDetails.first_name,
        lastName: this.props.userDetails.last_name,
        phoneNumber: userMetaData.phone_number,
        country: userMetaData.country,
        address: userMetaData.address,
        suburb: userMetaData.suburb,
        city: userMetaData.city,
        province: userMetaData.province,
        postalCode: userMetaData.postal_code,
      });
    }
    const token = this.props.token;
    this.props.getControllers(token);
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      this.props.getControllers(this.props.token);
    }
    if (
      this.props.userDetails !== prevProps.userDetails &&
      this.props.userDetails &&
      this.props.userMetaData
    ) {
      const userMetaData = this.props.userMetaData;
      this.setState({
        shouldSave: false,
        firstName: this.props.userDetails.first_name,
        lastName: this.props.userDetails.last_name,
        phoneNumber: userMetaData.phone_number,
        country: userMetaData.country,
        address: userMetaData.address,
        suburb: userMetaData.suburb,
        city: userMetaData.city,
        province: userMetaData.province,
        postalCode: userMetaData.postal_code,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, shouldSave: true });
  };

  navigate = (path) => {
    this.props.history.push(path);
  };

  // Hnadle the second case where fields are not empty strings
  catchUserMetaDataChange = () => {
    const {
      phoneNumber,
      country,
      address,
      suburb,
      city,
      province,
      postalCode,
    } = this.state;

    if (
      (phoneNumber ||
        country ||
        address ||
        suburb ||
        city ||
        province ||
        postalCode) !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  catchUserDetailsChange = () => {
    const { first_name, last_name } = this.props.userDetails;
    const { firstName, lastName } = this.state;

    if (firstName !== first_name || lastName !== last_name) {
      return true;
    } else {
      return false;
    }
  };

  handleUserDetailsUpdate = () => {
    const token = this.props.token;
    const id = this.props.userDetails.pk;
    const { firstName, lastName } = this.state;
    this.props.updateUserDetails(token, id, firstName, lastName);
  };

  handleMetaDataUpdate = () => {
    const token = this.props.token;
    const userMetaData = this.props.userMetaData;
    if (userMetaData) {
      let fd = new FormData();
      if (userMetaData.phone_number !== this.state.phoneNumber) {
        fd.append('phone_number', this.state.phoneNumber);
      }
      if (userMetaData.country !== this.state.country) {
        fd.append('country', this.state.country);
      }
      if (userMetaData.address !== this.state.address) {
        fd.append('address', this.state.address);
      }
      if (userMetaData.suburb !== this.state.suburb) {
        fd.append('suburb', this.state.suburb);
      }
      if (userMetaData.city !== this.state.city) {
        fd.append('city', this.state.city);
      }
      if (userMetaData.province !== this.state.province) {
        fd.append('province', this.state.province);
      }
      if (userMetaData.postal_code !== this.state.postalCode) {
        fd.append('postal_code', this.state.postalCode);
      }

      this.props.updateUserMetaData(token, userMetaData.id, fd);
    } else {
      let fd = new FormData();
      if (this.state.phoneNumber !== '') {
        fd.append('phone_number', this.state.phoneNumber);
      }
      if (this.state.country !== '') {
        fd.append('country', this.state.country);
      }
      if (this.state.address !== '') {
        fd.append('address', this.state.address);
      }
      if (this.state.suburb !== '') {
        fd.append('suburb', this.state.suburb);
      }
      if (this.state.city !== '') {
        fd.append('city', this.state.city);
      }
      if (this.state.province !== '') {
        fd.append('province', this.state.province);
      }
      if (this.state.postalCode !== '') {
        fd.append('postal_code', this.state.postalCode);
      }
      this.props.saveUserMetaData(token, fd);
    }
  };

  handleUserDataUpdate = () => {
    if (this.catchUserMetaDataChange()) {
      this.handleMetaDataUpdate();
    }
    if (this.catchUserDetailsChange()) {
      this.handleUserDetailsUpdate();
    }
  };

  render() {
    return (
      <div className="CLIENT_PROFILE_MAIN_CONTAINER" id={this.props.mode}>
        <div
          className="CLIENT_PROFILE_SAVE_BUTTON"
          style={
            this.state.shouldSave ? { display: 'flex' } : { display: 'none' }
          }
          onClick={this.handleUserDataUpdate}
        >
          {this.props.userLoading ? (
            <CircularProgress></CircularProgress>
          ) : (
            <SaveIcon color="inherit" fontSize="inherit"></SaveIcon>
          )}
        </div>
        <div className="CLIENT_PROFILE_HEADER">
          <div className="CLIENT_PROFILE_HEADER_TEXT">
            <h1>My Profile</h1>
          </div>
        </div>
        <div className="CLIENT_PROFILE_CONTAINER">
          <div className="CLIENT_PROFILE_USER_INF0_HEADER">
            <h3>Personal info</h3>
          </div>
          <div className="CLIENT_PROFILE_USER_INFO_MAIN_CONTAINER">
            <div className="CLIENT_PROFILE_USER_INFO_DATA_CONTAINER">
              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="user-name"
                  label="User Name"
                  variant="outlined"
                  name="userName"
                  fullWidth
                  value={
                    this.props.userDetails
                      ? this.props.userDetails.username
                      : ''
                  }
                  contentEditable={false}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="user-email"
                  label="User Email"
                  variant="outlined"
                  name="email"
                  fullWidth
                  value={
                    this.props.userDetails ? this.props.userDetails.email : ''
                  }
                  contentEditable={false}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="first-name"
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  fullWidth
                  value={this.state.firstName}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="last-name"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  fullWidth
                  value={this.state.lastName}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>

              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="phone-number"
                  label="Phone Number"
                  variant="outlined"
                  name="phoneNumber"
                  fullWidth
                  value={this.state.phoneNumber}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="client-country"
                  label="Country"
                  variant="outlined"
                  name="country"
                  fullWidth
                  value={this.state.country}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="CLIENT_PROFILE_USER_INFO_DATA_CONTAINER">
              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="address"
                  label="Address"
                  variant="outlined"
                  name="address"
                  fullWidth
                  value={this.state.address}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="suburb"
                  label="Suburb"
                  variant="outlined"
                  name="suburb"
                  fullWidth
                  value={this.state.suburb}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>

              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="city"
                  label="City"
                  variant="outlined"
                  name="city"
                  fullWidth
                  value={this.state.city}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>

              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Province"
                  label="Province"
                  variant="outlined"
                  name="province"
                  fullWidth
                  value={this.state.province}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="USER_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Postal-code"
                  label="Postal code"
                  variant="outlined"
                  name="postalCode"
                  fullWidth
                  value={this.state.postalCode}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
          </div>
          <div className="CLIENT_PROFILE_USER_CONTROLLERS_MAIN_CONTAINER">
            <div className="CLIENT_PROFILE_USER_CONTROLLERS_HEADER">
              <h3>My geysers</h3>
              <Button
                variant="outlined"
                size="small"
                onClick={() => this.navigate('/register-controller')}
              >
                Add New Geyser
              </Button>
            </div>
            <div className="CLIENT_PROFILE_USER_CONTROLLERS_CONTAINER">
              {this.props.controllers && this.props.controllers.length > 0 ? (
                this.props.controllers.map((controller, index) => (
                  <ProfileController
                    key={`controller${index}`}
                    data={controller.controller_meta_data}
                    controller_id={controller.id}
                  ></ProfileController>
                ))
              ) : this.props.loadingController ? (
                <CircularProgress></CircularProgress>
              ) : null}
            </div>
          </div>
        </div>
        <Modal
          open={this.state.addNewController}
          onClose={this.handleAddControllerClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="ADD_NEW_CONTROLLER_MODAL">
            <h2>Add New Controller</h2>

            <div style={{ width: '300px' }}>
              <TextField
                id="new-controller-lable"
                label="Label"
                variant="outlined"
                name="newControllerLable"
                required
                fullWidth
                value={this.state.newControllerLable}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'USER_PROFILE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div style={{ width: '300px' }}>
              <TextField
                id="new-controller-location"
                label="Location"
                variant="outlined"
                name="newControllerLocation"
                fullWidth
                required
                value={this.state.newControllerLocation}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'USER_PROFILE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div style={{ width: '300px' }}>
              <TextField
                id="new-controller-RATE"
                label="Rate Per KW"
                variant="outlined"
                name="newControllerRate"
                fullWidth
                required
                value={this.state.newControllerRate}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'USER_PROFILE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'USER_PROFILE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div
              className="ADD_NEW_CONTROLLER_MODAL_BUTTON"
              onClick={this.handleAddNewController}
            >
              <span>ADD</span>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    userDetails: state.users.userDetails,
    userMetaData: state.users.userDetails?.user_meta_data,
    userLoading: state.users.loading,
    controllers: state.controller.controllers,
    controller: state.controller.controller,
    loadingController: state.controller.loading,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserDetails: (token, id, firstName, lastName) =>
      dispatch(updateUserDetails(token, id, firstName, lastName)),
    updateUserMetaData: (token, id, data) =>
      dispatch(updateUserMetaData(token, id, data)),
    saveUserMetaData: (token, data) => dispatch(postUserMetaData(token, data)),
    getControllers: (token) => dispatch(getControllers(token)),
    getSelectedController: (token, id) => dispatch(getController(token, id)),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Profile);
