import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/insights.css';
import NewCarousel from '../../components/insights/carousel/NewCarousel';
import AnalyticsHeader from '../../components/insights/header/InsightsHeader';
import AnalyticsTable from '../../components/insights/insights-table/InsightsTable';
import { getGeyserUsageByDate } from '../../store/actions/geyserUsage';
import {
  getGeyserMonthlyUsage,
  getGeyserCurrnetMonthlyUsage,
  getMonthUsageByDate,
} from '../../store/actions/insights';
import moment from 'moment';

class Analytics extends Component {
  componentDidMount() {
    const { firstDate, lastDate } = this.getCurrentMonthDates();
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    this.props.getUsageByDate(
      token,
      IMEI,
      this.formatDate(firstDate),
      this.formatDate(lastDate)
    );
    this.props.getMonthlyUsage(token, IMEI);
    this.props.getCurrentMonthlyUsage(token, IMEI);
  }

  formatDate = (date) => {
    return moment(date).format();
  };
  getCurrentMonthDates = () => {
    const currentDate = new Date();
    const firstDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    firstDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000

    const lastDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    lastDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999

    return { firstDate, lastDate };
  };

  getActiveFirstLastDate = (dateStr) => {
    const date = new Date(dateStr);
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDate.setHours(0, 0, 0, 0);

    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDate.setHours(23, 59, 59, 999);
    console.log(firstDate, lastDate);

    return { firstDate, lastDate };
  };

  handleGetActiveMonthUsage = (month) => {
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    this.props.getActiveMonthUsage(token, month.date, IMEI);
    const { firstDate, lastDate } = this.getActiveFirstLastDate(month.date);
    this.props.getUsageByDate(
      token,
      IMEI,
      this.formatDate(firstDate),
      this.formatDate(lastDate)
    );
  };

  render() {
    return (
      <div className="DASHBOARD_MAIN_CONTAINER" id={this.props.mode}>
        <AnalyticsHeader
          months={this.props.monthlyUsage}
          currentMonthlUsage={this.props.currentMonthlyUsage}
          getMonthData={this.handleGetActiveMonthUsage}
        ></AnalyticsHeader>
        <NewCarousel
          dailyUsage={this.props.dailyUsage}
          mode={this.props.mode}
        ></NewCarousel>
        <AnalyticsTable
          dailyUsage={this.props.dailyUsage}
          monthlyUsage={this.props.currentMonthlyUsage}
        ></AnalyticsTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    controller: state.controller.controller,
    dailyUsage: state.geyserUsage.usage,
    monthlyUsage: state.geyserAnalytics.monthlyUsage,
    currentMonthlyUsage: state.geyserAnalytics.currentMonthlyUsage,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsageByDate: (token, IMEI, startDate, endDate) =>
      dispatch(getGeyserUsageByDate(token, IMEI, startDate, endDate)),
    getMonthlyUsage: (token, IMEI) =>
      dispatch(getGeyserMonthlyUsage(token, IMEI)),
    getActiveMonthUsage: (token, date, IMEI) =>
      dispatch(getMonthUsageByDate(token, date, IMEI)),
    getCurrentMonthlyUsage: (token, IMEI) =>
      dispatch(getGeyserCurrnetMonthlyUsage(token, IMEI)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
