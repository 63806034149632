import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import {
  postControllerMetaData,
  getControllerByCode,
} from '../../store/actions/controller';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import jsonData from '../../tools/data.json';
import AddressField from '../../components/controller-meta-data/address/AddressField';

class MetaDataForm extends Component {
  state = {
    cities: [],
    suburbs: [],
    label: '',
    labelError: { value: false, des: '(e.g. Bedroom) 35 characters max' },
    address: '',
    addressError: { value: false, des: "Your geyser's address" },
    suburb: '',
    city: '',
    province: '',
    provinceError: { value: false, des: '' },
    cityError: { value: false, des: '' },
    suburbError: { value: false, des: '' },
    postalCode: '',
    rates_per_kw: parseFloat(2.4),
    rates_per_kwError: { value: false, des: '' },
    openInfo: false,
    anchorEl: null,
    loadingOptions: false,
    lat: parseFloat(0),
    lng: parseFloat(0),
  };

  componentDidMount() {
    const token = this.props.token;
    this.props.getActiveControllerByCode(token, this.props.match.params.code);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.controllerMetaData !== prevProps.controllerMetaData &&
      this.props.controllerMetaData
    ) {
      setTimeout(() => this.props.history.push('/select-controller'), 200);
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onLableChange = (e) => {
    if (e.target.name === 'label' && e.target.value.length < 36) {
      this.setState({ [e.target.name]: e.target.value });
      this.setState({
        labelError: { value: false, des: '' },
      });
    }
  };

  onAddressChange = (e) => {
    if (e.target.name === 'address' && e.target.value.length < 150) {
      this.setState({ [e.target.name]: e.target.value });
      this.setState({
        addressError: { value: false, des: '' },
      });
    }
  };

  onProvinceChange = (e) => {
    if (e.target.name === 'province' && e.target.value?.length < 150) {
      this.setState({ [e.target.name]: e.target.value });
      this.setState({
        provinceError: { value: false, des: '' },
      });
    }
  };

  onCityChange = (e) => {
    if (e.target.name === 'city' && e.target.value?.length < 150) {
      this.setState({ [e.target.name]: e.target.value });
      this.setState({
        cityError: { value: false, des: '' },
      });
    }
  };
  onSuburbChange = (e) => {
    if (e.target.name === 'suburb' && e.target.value?.length < 150) {
      this.setState({ [e.target.name]: e.target.value });
      this.setState({
        suburbError: { value: false, des: '' },
      });
    }
  };

  onRatesChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateMetaData = () => {
    const { label, address, province, city, suburb, rates_per_kw } = this.state;
    let valid = true;
    if (label === '') {
      this.setState({
        labelError: { value: true, des: 'This field is required' },
      });
      valid = false;
    }
    if (address === '') {
      this.setState({
        addressError: { value: true, des: 'This field is required' },
      });
      valid = false;
    }
    if (province === '') {
      this.setState({
        provinceError: { value: true, des: 'This field is required' },
      });
      valid = false;
    }
    if (city === '') {
      this.setState({
        cityError: { value: true, des: 'This field is required' },
      });
      valid = false;
    }
    if (suburb === '') {
      this.setState({
        suburbError: { value: true, des: 'This field is required' },
      });
      valid = false;
    }
    if (rates_per_kw === '' || rates_per_kw === 0) {
      this.setState({
        rates_per_kwError: { value: true, des: 'This field is required' },
      });
      valid = false;
    }
    return valid;
  };

  onSelectAddress = (e, option) => {
    console.log(option);
    if (e.target?.innerText !== undefined && e.target?.innerText.length < 300) {
      this.setState({
        address: option ? option.description : '',
        addressError: { value: false, des: '' },
      });
    } else {
      this.setState({
        address: '',
      });
    }
  };

  handleSetGeoData = (lat, lng) => {
    this.setState({ lat, lng });
  };

  onSelectProvince = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        province: option ? option.name : '',
        cities: option ? option.cities : [],
        provinceError: { value: false, des: '' },
      });
    } else {
      this.setState({
        province: '',
        cities: [],
      });
    }
  };

  onSelectCity = (e, option) => {
    if (e.target.innerText !== undefined) {
      this.setState({
        city: option ? option.name : '',
        suburbs: option ? option.suburbs : [],
        cityError: { value: false, des: '' },
      });
    } else {
      this.setState({
        city: '',
        suburbs: [],
      });
    }
  };

  onSelectSuburb = (e, option) => {
    if (e.target.innerText !== undefined) {
      this.setState({
        suburb: option ? option.name : '',
        suburbError: { value: false, des: '' },
      });
    } else {
      this.setState({
        suburb: '',
      });
    }
  };

  handleSaveMetaData = () => {
    const valid = this.validateMetaData();
    const token = this.props.token;
    const controller_id = this.props.controller?.id;
    if (valid) {
      let fd = new FormData();
      fd.append('controller_id', controller_id);
      fd.append('lable', this.state.label);
      fd.append('address', this.state.address);
      fd.append('suburb', this.state.suburb);
      fd.append('city', this.state.city);
      fd.append('province', this.state.province);
      fd.append('postal_code', this.state.postalCode);
      fd.append('rates_per_kw', this.state.rates_per_kw);
      fd.append('lat', this.state.lat);
      fd.append('lng', this.state.lng);

      this.props.saveMetaData(token, controller_id, fd);
    }
  };

  handleOpenInfo = (event) => {
    this.setState({
      openInfo: !this.state.openInfo,
      anchorEl: event.currentTarget,
    });
  };

  render() {
    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.name,
    });
    return (
      <div className="REGISTER_CONTROLLER_MAIN_CONTAINER">
        <div className="REGISTER_CONTROLLER_FORM_CONTAINER">
          <div className="REGISTER_CONTROLLER_HEADER_CONTAINER">
            <h3>We are almost done!</h3>
            <h2>Please provide some info about your geyser</h2>
          </div>
          <Popper
            open={this.state.openInfo}
            anchorEl={this.state.anchorEl}
            placement="bottom-start"
            transition
            sx={{ zIndex: 3 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  sx={{
                    opacity: 0.5,
                    maxWidth: '250px',
                  }}
                >
                  <Typography sx={{ p: 1, color: '#333' }}>
                    The electricity provider determines the rate at which a
                    household is charged for each unit of electricity consumed,
                    usually expressed in kilowatt-hours (kWh) and itemized in
                    the utility bill. In South Africa, the average cost per kWh
                    for households ranges from 2.4 to 2.7 Rand.
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
          <div className="META_DATA_INPUT_FIELD_CONTAINER">
            <TextField
              id="geyser-label"
              label="Geyser Label"
              variant="outlined"
              name="label"
              placeholder={this.state.labelError.des}
              error={this.state.labelError.value}
              required
              fullWidth
              value={this.state.label}
              onChange={this.onLableChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'SIGNUP_INPUT_FIELD',
              }}
              InputLabelProps={{
                shrink: true,
                className: 'SIGNUP_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="META_DATA_INPUT_FIELD_CONTAINER">
            <AddressField
              addressError={this.state.addressError}
              onSelectAddress={this.onSelectAddress}
              onClearAddress={() => this.setState({ address: '' })}
              setGeoData={this.handleSetGeoData}
            ></AddressField>
          </div>
          <div className="META_DATA_INPUT_FIELD_CONTAINER">
            <Autocomplete
              id="filter-province"
              options={jsonData.provinces}
              getOptionLabel={(option) =>
                option.name ? option.name : this.state.province
              }
              filterOptions={filterOptions}
              sx={{ color: '#fff', width: '100%' }}
              onOpen={() => {
                console.log('options');
              }}
              onClose={() => {
                console.log('close');
              }}
              clearIcon={<CloseIcon color="error"></CloseIcon>}
              openOnFocus={true}
              value={this.state.province}
              onChange={(e, option) => this.onSelectProvince(e, option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Province"
                  name="province"
                  fullWidth
                  required
                  placeholder={this.state.provinceError.des}
                  error={this.state.provinceError.value}
                  value={this.state.province}
                  onChange={this.onProvinceChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.state.loadingOptions ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    className: 'SIGNUP_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'SIGNUP_INPUT_FIELD_LABEL',
                  }}
                />
              )}
            />
          </div>
          {/* <div className="META_DATA_INPUT_FIELD_CONTAINER">
          <TextField
            id="geyser-province"
            label="Province"
            variant="outlined"
            name="province"
            required
            fullWidth
            placeholder={this.state.provinceError.des}
            error={this.state.provinceError.value}
            value={this.state.province}
            onChange={this.onProvinceChange}
            // helperText="Please select your currency"
            InputProps={{
              className: 'SIGNUP_INPUT_FIELD',
            }}
            InputLabelProps={{
              shrink: true,
              className: 'SIGNUP_INPUT_FIELD_LABEL',
            }}
          ></TextField>
        </div> */}
          <div className="META_DATA_INPUT_FIELD_CONTAINER">
            <Autocomplete
              id="filter-cities"
              options={this.state.cities}
              getOptionLabel={(option) =>
                option.name ? option.name : this.state.city
              }
              filterOptions={filterOptions}
              sx={{ color: '#fff', width: '100%' }}
              onOpen={() => {
                console.log('options');
              }}
              onClose={() => {
                console.log('close');
              }}
              clearIcon={<CloseIcon color="error"></CloseIcon>}
              openOnFocus={true}
              value={this.state.city}
              onChange={(e, option) => this.onSelectCity(e, option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  name="city"
                  required
                  fullWidth
                  placeholder={this.state.cityError.des}
                  error={this.state.cityError.value}
                  value={this.state.city}
                  onChange={this.onChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.state.loadingOptions ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    className: 'SIGNUP_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'SIGNUP_INPUT_FIELD_LABEL',
                  }}
                />
              )}
            />
          </div>
          {/* <div className="META_DATA_INPUT_FIELD_CONTAINER">
          <TextField
            id="geyser-city"
            label="City"
            variant="outlined"
            name="city"
            required
            fullWidth
            placeholder={this.state.cityError.des}
            error={this.state.cityError.value}
            value={this.state.city}
            onChange={this.onChange}
            // helperText="Please select your currency"
            InputProps={{
              className: 'SIGNUP_INPUT_FIELD',
            }}
            InputLabelProps={{
              shrink: true,
              className: 'SIGNUP_INPUT_FIELD_LABEL',
            }}
          ></TextField>
        </div> */}
          <div className="META_DATA_INPUT_FIELD_CONTAINER">
            <Autocomplete
              id="filter-cities"
              options={this.state.suburbs}
              getOptionLabel={(option) =>
                option.name ? option.name : this.state.suburb
              }
              filterOptions={filterOptions}
              sx={{ color: '#fff', width: '100%' }}
              onOpen={() => {
                console.log('options');
              }}
              onClose={() => {
                console.log('close');
              }}
              clearIcon={<CloseIcon color="error"></CloseIcon>}
              openOnFocus={true}
              value={this.state.suburb}
              onChange={(e, option) => this.onSelectSuburb(e, option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Suburb"
                  name="suburb"
                  required
                  fullWidth
                  placeholder={this.state.suburbError.des}
                  error={this.state.suburbError.value}
                  value={this.state.suburb}
                  onChange={this.onChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.state.loadingOptions ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    className: 'SIGNUP_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'SIGNUP_INPUT_FIELD_LABEL',
                  }}
                />
              )}
            />
          </div>
          {/* <div className="META_DATA_INPUT_FIELD_CONTAINER">
          <TextField
            id="geyser-suburb"
            label="Suburb"
            variant="outlined"
            name="suburb"
            required
            fullWidth
            placeholder={this.state.suburbError.des}
            error={this.state.suburbError.value}
            value={this.state.suburb}
            onChange={this.onChange}
            // helperText="Please select your currency"
            InputProps={{
              className: 'SIGNUP_INPUT_FIELD',
            }}
            InputLabelProps={{
              shrink: true,
              className: 'SIGNUP_INPUT_FIELD_LABEL',
            }}
          ></TextField>
        </div> */}
          <div className="META_DATA_INPUT_FIELD_CONTAINER">
            <TextField
              id="geyser-postalCode"
              label="Postal Code"
              variant="outlined"
              name="postalCode"
              fullWidth
              value={this.state.postalCode}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'SIGNUP_INPUT_FIELD',
              }}
              InputLabelProps={{
                shrink: true,
                className: 'SIGNUP_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="META_DATA_INPUT_FIELD_CONTAINER">
            <TextField
              id="geyser-power"
              label="Rates Per KW"
              variant="outlined"
              name="rates_per_kw"
              fullWidth
              required
              placeholder={this.state.rates_per_kwError.des}
              error={this.state.rates_per_kwError.value}
              type="number"
              value={this.state.rates_per_kw}
              onChange={this.onRatesChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'SIGNUP_INPUT_FIELD',
              }}
              InputLabelProps={{
                shrink: true,
                className: 'SIGNUP_INPUT_FIELD_LABEL',
              }}
            ></TextField>
            <IconButton aria-label="info" onClick={this.handleOpenInfo}>
              <InfoOutlinedIcon></InfoOutlinedIcon>
            </IconButton>
          </div>
          <div className="REGISTER_CONTROLLER_BUTTON_CONTAINER">
            {this.props.loading ? (
              <CircularProgress></CircularProgress>
            ) : (
              <div
                className="SIGNUP_BUTTON_CONTAINER"
                onClick={this.handleSaveMetaData}
              >
                <h6>Save</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    loading: state.controller.loading,
    controller: state.controller.controller,
    controllerMetaData: state.controller.controllerMetaData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveControllerByCode: (token, code) =>
      dispatch(getControllerByCode(token, code)),
    saveMetaData: (token, controller_id, data) =>
      dispatch(postControllerMetaData(token, controller_id, data)),
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(MetaDataForm));
