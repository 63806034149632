import React, { Component } from 'react';
import { connect } from 'react-redux';
import SettingsItem from '../../components/settings/SettingsItem';
import './styles/settings.css';
import {
  getAppSettingsByUser,
  updateAppSettings,
} from '../../store/actions/appSettings';

class Settings extends Component {
  componentDidMount() {
    const token = this.props.token;
    this.props.getAppSettings(token);
  }

  updateAppSettings = (type, value) => {
    const token = this.props.token;
    const { id, enable_alerts, enable_notifications, enable_messages } =
      this.props.settings;

    if (type === 'Alerts') {
      this.props.updateAppSettings(
        token,
        id,
        value,
        enable_notifications,
        enable_messages
      );
    } else if (type === 'Notifications') {
      this.props.updateAppSettings(
        token,
        id,
        enable_alerts,
        value,
        enable_messages
      );
    } else if (type === 'Messages') {
      this.props.updateAppSettings(
        token,
        id,
        enable_alerts,
        enable_notifications,
        value
      );
    }
  };

  render() {
    return (
      <div className="SETTINGS_MAIN_CONTAINER" id={this.props.mode}>
        <SettingsItem
          title="Notifications"
          description="Enabling this feature will allow the system to send you an e-mail notification keeping you informed of your SmartGeyser's activities like switching ON/OFF along with the actions source."
          enabled={this.props.settings?.enable_notifications}
          updateSettings={this.updateAppSettings}
        ></SettingsItem>
        <SettingsItem
          title="Alerts"
          description="Enabling this feature will allow the system to send you an e-mail alerts keeping you informed of any abnormal activities detected by your SmartGeyser e.g(a power outage)."
          enabled={this.props.settings?.enable_alerts}
          updateSettings={this.updateAppSettings}
        ></SettingsItem>
        <SettingsItem
          title="Messages"
          description="Enabling this feature will allow the service provider to e-mail you regarding a geyser service, an upgrade or any other offers."
          enabled={this.props.settings?.enable_messages}
          updateSettings={this.updateAppSettings}
        ></SettingsItem>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    settings: state.appSettings.settings,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAppSettings: (token) => dispatch(getAppSettingsByUser(token)),
    updateAppSettings: (
      token,
      id,
      enable_alerts,
      enable_notifications,
      enable_messages
    ) =>
      dispatch(
        updateAppSettings(
          token,
          id,
          enable_alerts,
          enable_notifications,
          enable_messages
        )
      ),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Settings);
