import React, { Component } from 'react';
import './styles/control-graphs.css';
import PieChart from '../../graphs/pie-chart/PieChart';
import PieChartFill from '../../graphs/pie-chart-2/PieChartFill';
// import LineChart from '../../graphs/line-chart/LineChart';
import HistogramChart from '../../graphs/histogram-chart/HistogramChart';

export default class ControlGraphs extends Component {
  render() {
    return (
      <div className="CONTROL_DASHBOARD_GRAPHS_MAIN_CONTAINER">
        <div className="CONTROL_DASHBOARD_GRAPHS_WRAPPER_CONTAINER">
          <div className="CONTROL_DASHBOARD_GRAPHS_CONTAINER">
            <div>
              <PieChartFill
                describtion="The amount of Online (connected) and Offline (disconnected) devices."
                data={[
                  {
                    type: 'Online',
                    value: 95,
                  },
                  {
                    type: 'Offline',
                    value: 5,
                  },
                ]}
              ></PieChartFill>
            </div>
            <div style={{ marginLeft: '50px' }}>
              <PieChart
                describtion="The amount of Running (heating element ON) and Idling (heating element OFF) devices."
                data={[
                  {
                    type: 'Running',
                    value: 60,
                  },
                  {
                    type: 'Idling',
                    value: 40,
                  },
                ]}
              ></PieChart>
            </div>

            <div style={{ marginLeft: '50px' }}>
              <HistogramChart
                describtion="The amount of devices installed per region "
                data={[
                  { region: 'KZN', value: 50 },
                  { region: 'GP', value: 100 },
                  { region: 'FS', value: 70 },
                  { region: 'EC', value: 40 },
                  { region: 'WC', value: 20 },
                ]}
              ></HistogramChart>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
