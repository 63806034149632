import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/user-support.css';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
// import PreviousTickets from '../../components/previous-tickets/PreviousTickets';
import {
  getSupportTicket,
  getSupportTickets,
  postSupportTicketData,
} from '../../store/actions/support';
import PreviewTicket from '../../components/preview-ticket/PreviewTicket';
import TicketsTable from '../../components/support/tickets-table/TicketsTable';
import TicketDialog from '../../components/support/ticket-dialog/TicketDialog';

class UserSupport extends Component {
  state = {
    category: 'User Account',
    ticketTitle: '',
    ticketBody: '',
    loadingOptions: false,
  };

  componentDidMount() {
    const token = this.props.token;
    this.props.getSupportTickets(token);
  }

  componentDidUpdate(prevProps) {
    if (this.props.ticket !== prevProps.ticket) {
      this.props.getSupportTickets(this.props.token);
      this.setState({ ticketTitle: '', ticketBody: '' });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelectFilterType = (e, option) => {
    console.log(option);

    if (e.target.innerText !== undefined) {
      this.setState({
        category: option ? option.category : '',
      });
    } else {
      this.setState({
        category: '',
      });
    }
  };

  handleCreateTicket = () => {
    const token = this.props.token;
    const { category, ticketTitle, ticketBody } = this.state;

    this.props.postSupportTicket(token, ticketTitle, category, ticketBody);
  };

  handlePreviewTicket = (ticket_id) => {
    const token = this.props.token;

    this.props.getSupportTicket(token, ticket_id);
  };

  render() {
    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.category,
    });

    return (
      <div className="USER_SUPPORT_MAIN_CONTAINER" id={this.props.mode}>
        <div className="USER_SUPPORT_CONTAINER">
          <div className="USER_SUPPORT_HEADER">
            <h2>Support</h2>
            <span>Please submit your question to our support team.</span>
          </div>
          <div className="USER_PREVIOUS_TICKETS_MAIN_CONTAINER">
            {/* <PreviousTickets
              mode={this.props.mode}
              tickets={this.props.tickets ? this.props.tickets : []}
              previewTicket={(ticket_id) => this.handlePreviewTicket(ticket_id)}
            ></PreviousTickets> */}
            <div className="MD_SUPPORT_TICKETS_CONTAINER">
              {this.props.tickets && this.props.tickets?.length > 0 ? (
                <TicketsTable
                  data={this.props.tickets ? this.props.tickets : []}
                  getActiveTicket={(id) => this.handlePreviewTicket(id)}
                ></TicketsTable>
              ) : null}
            </div>
          </div>
          {this.props.ticket ? (
            <div className="USER_PREVIEW_TICKET_MAIN_CONTAINER">
              <>
                <TicketDialog ticket={this.props.ticket}></TicketDialog>
                <PreviewTicket
                  ticket={this.props.ticket ? this.props.ticket : null}
                ></PreviewTicket>
              </>
            </div>
          ) : null}
          <div className="USER_SUBMIT_TICKET_CONTAINER">
            <div className="USER_SUBMIT_TICKET_LABLE">
              <h3>Open New Ticket</h3>
            </div>
            <div className="USER_SUBMIT_TICKET_HEADER">
              <div className="SUPPORT_INPUT_FIELD_CONTAINER">
                <Autocomplete
                  id="category-options"
                  options={[
                    { category: 'User Account' },
                    { category: 'Controller Device' },
                    { category: 'Others' },
                  ]}
                  getOptionLabel={(option) =>
                    option.category ? option.category : this.state.category
                  }
                  filterOptions={filterOptions}
                  sx={{ color: '#fff' }}
                  onOpen={() => {
                    console.log('options');
                  }}
                  onClose={() => {
                    console.log('close');
                  }}
                  clearIcon={<CloseIcon color="error"></CloseIcon>}
                  openOnFocus={true}
                  onChange={(e, option) => this.onSelectFilterType(e, option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      name="category"
                      fullWidth
                      value={this.state.category}
                      onChange={this.onChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loadingOptions ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),

                        className: 'SUPPORT_FORM_INPUT_FIELD',
                      }}
                      InputLabelProps={{
                        // shrink: true,
                        className: 'SUPPORT_FORM_INPUT_FIELD_LABEL',
                      }}
                    />
                  )}
                />
              </div>
              <div className="SUPPORT_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Ticket Title"
                  variant="outlined"
                  name="ticketTitle"
                  fullWidth
                  value={this.props.ticketTitle}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'SUPPORT_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'SUPPORT_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="USER_SUBMIT_TICKET_BODY">
              <TextField
                id="outlined-multiline-flexible"
                label="Ticket Content"
                name="ticketBody"
                multiline
                fullWidth
                rows={6}
                value={this.state.ticketBody}
                onChange={this.onChange}
                InputProps={{
                  className: 'SUPPORT_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'SUPPORT_FORM_INPUT_FIELD_LABEL',
                }}
              />
            </div>
            <div className="USER_SUBMIT_TICKET_FOOTER">
              <div className="CONTROL_DASHBOARD_SUBMIT_TICKET_BUTTON">
                {this.props.ticketLoading ? (
                  <CircularProgress></CircularProgress>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ borderRadius: 20 }}
                    onClick={this.handleCreateTicket}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    tickets: state.support.supportTickets,
    ticket: state.support.supportTicket,
    ticketLoading: state.support.loading,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSupportTickets: (token) => dispatch(getSupportTickets(token)),
    getSupportTicket: (token, ticket_id) =>
      dispatch(getSupportTicket(token, ticket_id)),
    postSupportTicket: (token, title, category, content) =>
      dispatch(postSupportTicketData(token, title, category, content)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSupport);
