import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  latestControllerParameters: null,
  controllerParameters: null,
};

const getControllerParametersStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getControllerParametersSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    controllerParameters: action.payload,
  });
};
const getLatestControllerParametersSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    latestControllerParameters: action.payload,
  });
};

const getControllerParametersFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const controllerParametersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTROLLER_PARAMETERS_START:
      return getControllerParametersStart(state, action);
    case actionTypes.GET_CONTROLLER_PARAMETERS_SUCCESS:
      return getControllerParametersSuccess(state, action);
    case actionTypes.GET_LATEST_CONTROLLER_PARAMETERS_SUCCESS:
      return getLatestControllerParametersSuccess(state, action);
    case actionTypes.GET_CONTROLLER_PARAMETERS_FAILD:
      return getControllerParametersFaild(state, action);
    default:
      return state;
  }
};

export default controllerParametersReducer;
