import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/devices.css';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import ControllerParameters from '../controller-parameters/ControllerParameters';
import ControllerGraphs from '../controller-graphs/ControllerGraphs';
import {
  getControllerByEmail,
  getControllerByIMEI,
  getControllerByPhneNo,
} from '../../store/actions/controller';

class Devices extends Component {
  state = {
    filterBy: '',
    filterValue: '',
    loadingOptions: false,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelectFilterType = (e, option) => {
    console.log(option);

    if (e.target.innerText !== undefined) {
      this.setState({
        filterBy: option ? option.filter_by : '',
      });
    } else {
      this.setState({
        filterBy: '',
      });
    }
  };

  handleGetDeviceDetails = () => {
    const token = this.props.token;
    const { filterBy, filterValue } = this.state;
    if (filterBy === 'Client Number') {
      this.props.getDeviceByPhone(token, filterValue);
    } else if (filterBy === 'Client Email') {
      this.props.getDeviceByEmail(token, filterValue.replaceAll('.', '-'));
    } else if (filterBy === 'Serial Number') {
      this.props.getDeviceByIMEI(token, filterValue);
    } else {
      console.log('Cannot perform search');
    }
  };

  render() {
    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.filter_by,
    });
    return (
      <div className="CONTROL_DASHBOARD_CONTENT_DEVICES_CONTAINER">
        <div className="CONTROL_DASHBOARD_CONTENT_FILTER_CONTAINER">
          <div className="CONTROL_DASHBOARD_CONTENT_FILTER_FIELDS_CONTAINER">
            <div className="CONTROLLER_FILTER_FORM_INPUT_FIELD_CONTAINER">
              <Autocomplete
                id="filter-search"
                options={[
                  { filter_by: 'Client Number' },
                  { filter_by: 'Client Email' },
                  { filter_by: 'Serial Number' },
                ]}
                getOptionLabel={(option) =>
                  option.filter_by ? option.filter_by : this.state.filterBy
                }
                filterOptions={filterOptions}
                sx={{ color: '#fff' }}
                onOpen={() => {
                  console.log('options');
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={<CloseIcon color="error"></CloseIcon>}
                openOnFocus={true}
                value={this.state.filterBy}
                onChange={(e, option) => this.onSelectFilterType(e, option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter By"
                    name="filterBy"
                    fullWidth
                    value={this.state.filterBy}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingOptions ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'FILTER_DEVICE_FORM_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'FILTER_DEVICE_FORM_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
            <div className="CONTROLLER_FILTER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="filter-value"
                label="Filter Value"
                variant="outlined"
                name="filterValue"
                fullWidth
                value={this.state.filterValue}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="CONTROL_DASHBOARD_CONTENT_FILTER_BUTTON_CONTAINER">
            <Button
              variant="outlined"
              size="medium"
              onClick={this.handleGetDeviceDetails}
            >
              Search
            </Button>
          </div>
        </div>
        <div className="CONTROL_DASHBOARD_CONTENT_USER_CONTAINER">
          <div className="CONTROL_DASHBOARD_CONTENT_USER_HEADER_CONTAINER">
            <h3>User Details</h3>
          </div>
          <div className="CONTROL_DASHBOARD_CONTENT_USER_BODY_CONTAINER">
            <div className="CONTROLLER_FILTER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="User Name"
                variant="outlined"
                name="username"
                fullWidth
                value="Demo"
                contentEditable={false}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="CONTROLLER_FILTER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                fullWidth
                value="demo@topiot.co.za"
                contentEditable={false}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="CONTROLLER_FILTER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Phone Number"
                variant="outlined"
                name="phoneNumber"
                fullWidth
                value="0617980805"
                contentEditable={false}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="CONTROLLER_FILTER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Rigion"
                variant="outlined"
                name="region"
                fullWidth
                value="KZN"
                contentEditable={false}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="CONTROLLER_FILTER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Address"
                variant="outlined"
                name="address"
                fullWidth
                value="9 Princess Gate, 211 Florida Road, Morningside, Durban, 4001"
                contentEditable={false}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'FILTER_DEVICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
        </div>
        <div className="CONTROL_DASHBOARD_CONTENT_PARAMETERS_CONTAINER">
          <div className="DASHBOARD_CONTROLLER_PARAMETERS_MAIN_CONTAINER">
            <ControllerParameters></ControllerParameters>
          </div>
        </div>
        <div className="CONTROL_DASHBOARD_CONTENT_GRAPHS_CONTAINER">
          <div className="DASHBOARD_GRAPHS_MAIN_CONTAINER">
            <ControllerGraphs></ControllerGraphs>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDeviceByIMEI: (token, IMEI) =>
      dispatch(getControllerByIMEI(token, IMEI)),
    getDeviceByEmail: (token, email) =>
      dispatch(getControllerByEmail(token, email)),
    getDeviceByPhone: (token, phone_number) =>
      dispatch(getControllerByPhneNo(token, phone_number)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
