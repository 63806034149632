import React, { Component } from 'react';
import './insights-header.css';
import moment from 'moment';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

export default class AnalyticsHeader extends Component {
  state = {
    prevMonth: null,
    activeMonth: null,
    nextMonth: null,
  };

  componentDidMount() {
    this.extractMonths();
  }
  componentDidUpdate(prevProps) {
    if (this.props.currentMonthlUsage !== prevProps.currentMonthlUsage) {
      this.extractMonths();
    }
    if (this.props.months !== prevProps.months) {
      this.extractMonths();
    }
  }

  extractMonths = () => {
    const months = this.props.months;
    months?.map((month, index) => {
      if (month.month === this.props.currentMonthlUsage?.month) {
        if (index === 0) {
          this.setState({
            prevMonth: null,
            nextMonth: months[index + 1],
          });
        } else if (index === months.length - 1) {
          this.setState({
            prevMonth: months[index - 1],
            nextMonth: null,
          });
        } else {
          this.setState({
            prevMonth: months[index - 1],
            nextMonth: months[index + 1],
          });
        }
      }
      return true;
    });
  };

  handleMonthChange = (month) => {
    const months = this.props.months;
    const index = months.indexOf(month);
    console.log(index);
    if (index === 0) {
      this.setState({
        prevMonth: null,
        activeMonth: months[index],
        nextMonth: months[index + 1],
      });
    } else if (index === months.length - 1) {
      this.setState({
        prevMonth: months[index - 1],
        activeMonth: months[index],
        nextMonth: null,
      });
    } else {
      this.setState({
        prevMonth: months[index - 1],
        activeMonth: months[index],
        nextMonth: months[index + 1],
      });
    }
    this.props.getMonthData(month);
  };

  render() {
    return (
      <div className="ANALYTICS_HEADER">
        <div className="PREV_MONTH">
          {this.state.prevMonth ? (
            <div
              className="PREV_MONTH"
              onClick={() => this.handleMonthChange(this.state.prevMonth)}
            >
              <ArrowBackIosNewOutlinedIcon
                fontSize="medium"
                color="inherit"
              ></ArrowBackIosNewOutlinedIcon>
              <span>{this.state.prevMonth?.month}</span>
            </div>
          ) : null}
        </div>

        <div className="ACTIVE_MONTH">
          <h3>
            {this.props.currentMonthlUsage
              ? moment(this.props.currentMonthlUsage.date).format('MMM YYYY')
              : null}
          </h3>
        </div>

        <div className="NEXT_MONTH">
          {this.state.nextMonth ? (
            <div
              className="NEXT_MONTH"
              onClick={() => this.handleMonthChange(this.state.nextMonth)}
            >
              <span>{this.state.nextMonth?.month}</span>
              <ArrowForwardIosOutlinedIcon
                fontSize="medium"
                color="inherit"
              ></ArrowForwardIosOutlinedIcon>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
