import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/select-controller.css';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getControllers, getController } from '../../store/actions/controller';

class SelectController extends Component {
  state = {
    controllerRegWarning: false,
  };
  componentDidMount() {
    const token = this.props.token;
    this.props.getControllers(token);
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      this.props.getControllers(this.props.token);
    }
    if (
      this.props.controller !== prevProps.controller &&
      this.props.controller
    ) {
      if (this.props.controller.controller_meta_data) {
        this.handleNavigations();
      } else {
        this.setState({ controllerRegWarning: true });
      }
    }
  }

  handleNavigations = () => {
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('active_g', this.props.controller.serial_number);
      this.props.history.push('/dashboard');
    } else {
      this.props.history.push(`/dashboard/${this.props.controller.code}/`);
    }
  };

  handleSelectController = (controller) => {
    const token = this.props.token;
    this.props.getSelectedController(token, controller.id);
  };

  navigate = (path) => {
    this.props.history.push(path);
  };

  handleControllerRegWarningClose = () => {
    this.setState({ controllerRegWarning: false });
  };

  handleCompleteReg = () => {
    this.navigate(
      `/complete-controller-registration/${this.props.controller.code}`
    );
  };

  render() {
    return (
      <div className="SELECT_CONTROLLER_MAIN_CONTAINER" id={this.props.mode}>
        <div className="SELECT_CONTROLLER_HEADER_CONTAINER">
          <h1>Select the geyser you want to control.</h1>
          <Button
            variant="outlined"
            size="small"
            onClick={() => this.navigate('/register-controller')}
          >
            Add New Geyser
          </Button>
        </div>
        <div className="CONTROLLERS_MAIN_CONTAINER">
          {this.props.controllers && this.props.controllers.length > 0
            ? this.props.controllers.map((controller, index) => (
                <div
                  className="AVAILABLE_CONTROLLER_CONTAINER"
                  key={controller.serial_number}
                  onClick={() => this.handleSelectController(controller)}
                >
                  {this.props.loadingController ? (
                    <CircularProgress></CircularProgress>
                  ) : (
                    <div className="AVAILABLE_CONTROLLER_LABLE">
                      <h1>
                        {controller.controller_meta_data &&
                        controller.controller_meta_data.lable !== ''
                          ? controller.controller_meta_data.lable
                          : `Geyser ${index + 1}`}
                      </h1>
                    </div>
                  )}
                </div>
              ))
            : null}
        </div>
        <Modal
          open={this.state.controllerRegWarning}
          onClose={this.handleControllerRegWarningClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ Backdrop: { timeout: 500 } }}
        >
          <div className="PARAMETERS_WARNING_MODAL">
            <h2>WARNING</h2>
            <p>
              To gain control over this SmartGeyser, it is necessary for you to
              finalize the registration process.
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="PARAMETERS_WARNING_MODAL_BUTTON"
                onClick={this.handleCompleteReg}
              >
                <span>Complete</span>
              </div>
              <div
                className="PARAMETERS_WARNING_MODAL_BUTTON"
                onClick={this.handleControllerRegWarningClose}
              >
                <span>Close</span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    controllers: state.controller.controllers,
    controller: state.controller.controller,
    loadingController: state.controller.loading,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getControllers: (token) => dispatch(getControllers(token)),
    getSelectedController: (token, id) => dispatch(getController(token, id)),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(SelectController);
