import React, { Component } from 'react';
import './ticket-dialog.css';
import Badge from '@mui/material/Badge';
import { dateTimeCalender } from '../../../tools/time';

export default class TicketDialog extends Component {
  render() {
    return (
      <div className="MD_SUPPORT_TICKET_CONTENT">
        {/* <div className="MD_SUPPORT_TICKET_CREATOR">
          <p>{this.props.ticket.content}</p>
          <div className="MD_SUPPORT_TICKET_DATA">
            <Badge
              badgeContent={moment(this.props.ticket.created_at).calendar()}
              color="info"
              sx={{ width: '140px' }}
            />
            <Badge
              badgeContent={this.props.ticket.author?.username}
              color="primary"
              sx={{ width: '100px' }}
            />
          </div>
        </div> */}
        {this.props.ticket.support_ticket_reply?.length > 0
          ? this.props.ticket.support_ticket_reply.map((reply) => (
              <div
                className={
                  reply.author?.username === this.props.ticket.author?.username
                    ? 'MD_SUPPORT_TICKET_CREATOR'
                    : 'MD_SUPPORT_TICKET_ASSESSTANCE'
                }
                key={`ticket-reply${reply.id}`}
              >
                <p>{reply.content}</p>
                <div className="MD_SUPPORT_TICKET_DATA">
                  <Badge
                    badgeContent={dateTimeCalender(reply.created_at)}
                    color="info"
                    sx={{ width: '140px' }}
                  />
                  <Badge
                    badgeContent={
                      reply.author?.username.length > 10
                        ? `${reply.author?.username.substring(0, 10)} ...`
                        : reply.author?.username
                    }
                    color="primary"
                    sx={{ width: '100px' }}
                  />
                </div>
              </div>
            ))
          : null}
      </div>
    );
  }
}
