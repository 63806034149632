import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/controller-parameters.css';
import Lottie from 'lottie-react';
import fire from './lottie/fire-1.json';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import MemoryIcon from '@mui/icons-material/Memory';
import CloudQueueRoundedIcon from '@mui/icons-material/CloudQueueRounded';
import CellTowerIcon from '@mui/icons-material/CellTower';
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded';
import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { CircularProgress } from '@mui/material';
import TempMeter from './temp-meter/TempMeter';
import { getLatestControllerParametersByIMEI } from '../../store/actions/controllerParameter';
import {
  getLastRunOrderByIMEI,
  postRunOrder,
} from '../../store/actions/runOrder';
import { getControllerByIMEI } from '../../store/actions/controller';
import {
  getLastControllerStatusByIMEI,
  postControllerStatus,
} from '../../store/actions/controllerStatus';
import {
  deactivateTimeSlot,
  getActiveTimeSlotByDay,
} from '../../store/actions/runSechedule';
import NetworkIcon from './network-icon/NetworkIcon';
import { timeConverter } from '../../tools/time';

class ControllerParameters extends Component {
  state = {
    controllerStatus: 'Checking...',
    intervalId: 0,
    heaterElementOn: false,
    runOrderWarning: false,
    showRunOrderMessage: false,
    heaterElementStatus: 'SB', //SB is standby
    activeHeating: false,
    showVmodeWarning: false,
  };

  componentDidMount() {
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    if (IMEI && token) {
      this.props.getActiveController(token, IMEI);
      this.props.getLatestParameters(token, IMEI);
      this.props.getLastRunOrder(token, IMEI);
      this.props.getLastControllerStatus(token, IMEI);
      this.handleGetActiveTimeSlot();

      const newIntervalId = setInterval(() => {
        this.props.getLatestParameters(token, IMEI);
        this.checkControllerStatus();
        this.handleGetActiveTimeSlot();
      }, 30000);

      this.setState((prevState) => {
        return {
          ...prevState,
          intervalId: newIntervalId,
        };
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Set the controller status initial value

    if (this.props.parameters !== prevProps.parameters) {
      this.handleHeaterElementStatus();
    }

    if (this.props.runOrder !== prevProps.runOrder) {
      this.handleHeaterElementStatus();
    }
    if (this.props.activeTimeSlot !== prevProps.activeTimeSlot) {
      this.handleHeaterElementStatus();
    }
    if (this.props.controllerConfigs !== prevProps.controllerConfigs) {
      this.handleHeaterElementStatus();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  handleHeaterElementStatus = () => {
    const vMode = this.props.controllerConfigs?.vacation_mode;
    if (vMode === true) {
      this.setState({ heaterElementStatus: 'OFF', activeHeating: false });
    } else {
      const heating_element_status =
        this.props.parameters?.heating_element_status;
      const heating_element_current =
        this.props.parameters?.heating_element_current;
      const turn_element_on = this.props.runOrder?.turn_element_on;
      const activeTimeSlot =
        this.props.activeTimeSlot?.[0] !== undefined ? true : false;

      if (
        (heating_element_status === 'ON' && turn_element_on) ||
        (activeTimeSlot && heating_element_status === 'ON')
      ) {
        this.setState({ heaterElementStatus: 'ON' });
      } else if (
        (heating_element_status === 'ON' && !turn_element_on) ||
        (heating_element_status === 'OFF' && turn_element_on) ||
        (activeTimeSlot && heating_element_status === 'OFF')
      ) {
        this.setState({ heaterElementStatus: 'SB', activeHeating: false });
      } else if (
        (heating_element_status === 'OFF' && !turn_element_on) ||
        (heating_element_status === 'OFF' && !activeTimeSlot)
      ) {
        this.setState({ heaterElementStatus: 'OFF', activeHeating: false });
      }
      if (heating_element_status === 'ON' && heating_element_current > 4) {
        this.setState({ activeHeating: true });
      } else {
        this.setState({ activeHeating: false });
      }
    }
  };

  handleGetActiveTimeSlot = () => {
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    const date = new Date();
    const dayName = timeConverter(date).format('dddd');
    this.props.getActiveTimeSlot(token, dayName, IMEI);
  };

  handleDeactivateTimeSlot = () => {
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    const timeSlotID = this.props.activeTimeSlot[0]?.id;
    const day = this.props.activeTimeSlot[0]?.day;
    this.props.deactivateTimeSlot(token, IMEI, day, timeSlotID);
  };

  handleRunOrderCommand = (action) => {
    const token = this.props.token;
    const IMEI = localStorage.getItem('active_g');
    const vmode = this.props.controllerConfigs?.vacation_mode;

    if (vmode === true) {
      this.setState({ showVmodeWarning: true });
    } else {
      if (action === 'OFF') {
        this.setState({ runOrderWarning: false });
        this.props.createElementOrder(token, false, IMEI, true);
        this.handleDeactivateTimeSlot();
        this.handleGetActiveTimeSlot();
      } else if (
        action === 'AUTO' &&
        this.props.activeTimeSlot[0] === undefined
      ) {
        const turn_element_on = this.props.runOrder
          ? this.props.runOrder.turn_element_on
          : null;
        if (turn_element_on !== null) {
          this.props.createElementOrder(token, !turn_element_on, IMEI, true);
          if (!turn_element_on) {
            this.setState({ showRunOrderMessage: true });
          }
        } else {
          this.props.createElementOrder(token, true, IMEI, true);
          this.setState({ showRunOrderMessage: true });
        }
      } else {
        this.setState({ runOrderWarning: true });
      }
    }
  };

  checkControllerStatus = () => {
    const lastReceivedParametersDate = new Date(
      this.props.parameters.created_at
    );
    const date = new Date();
    let diffMs = date - lastReceivedParametersDate; // The difference in ms
    let diffMins = Math.round(diffMs / 60000); // the difference in min
    if (diffMins > 10) {
      this.setState({ controllerStatus: 'Offline' });
      this.updateControllerStatus('Offline');
    } else {
      this.setState({ controllerStatus: 'Online' });
      this.updateControllerStatus('Online');
    }
  };

  handleRunOrderWarningClose = () => {
    this.setState({ runOrderWarning: false });
  };

  handleRunOrderMessageClose = () => {
    this.setState({ showRunOrderMessage: false });
  };

  handleVmodeWarningClose = () => {
    this.setState({ showVmodeWarning: false });
  };

  updateControllerStatus = (status) => {
    const token = this.props.token;
    const IMEI = this.props.activeController?.serial_number;
    if (this.props.controllerStatus) {
      if (this.props.controllerStatus.controller_status !== status) {
        this.props.createControllerStatus(token, IMEI, status);
      }
    } else {
      this.props.createControllerStatus(token, IMEI, status);
    }
  };

  render() {
    return (
      <div
        className="DASHBOARD_CONTROLLER_PARAMETERS_CONTAINER"
        id={this.props.mode}
      >
        <div
          className="DASHBOARD_CONTROLLER_PARAMETER_CONTAINER"
          onClick={this.checkControllerStatus}
        >
          <div className="DASHBOARD_CONTROLLER_PARAMETER_ICON">
            <MemoryIcon fontSize="inherit" color="inherit"></MemoryIcon>
          </div>
          <div className="DASHBOARD_CONTROLLER_PARAMETER_INFO">
            <span>CONTROLLER</span>
          </div>
          {this.props.loadingControllerStatus ? (
            <CircularProgress></CircularProgress>
          ) : (
            <div className="DASHBOARD_CONTROLLER_PARAMETER_INDICATOR">
              <CloudQueueRoundedIcon
                fontSize="inherit"
                color="inherit"
              ></CloudQueueRoundedIcon>
              <p>{this.state.controllerStatus}</p>
            </div>
          )}
        </div>
        <div className="DASHBOARD_CONTROLLER_PARAMETER_CONTAINER">
          <div className="DASHBOARD_CONTROLLER_PARAMETER_ICON">
            <CellTowerIcon fontSize="inherit" color="inherit"></CellTowerIcon>
          </div>
          <div className="DASHBOARD_CONTROLLER_PARAMETER_INFO">
            <span>NETWORK</span>
          </div>
          <div className="DASHBOARD_CONTROLLER_PARAMETER_INDICATOR">
            <NetworkIcon
              signal={this.props.parameters?.network_signal}
            ></NetworkIcon>
            <p>
              {this.props.parameters
                ? this.props.parameters.network_signal
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="DASHBOARD_CONTROLLER_PARAMETER_CONTAINER">
          <div className="DASHBOARD_CONTROLLER_PARAMETER_ICON">
            <DeviceThermostatRoundedIcon
              fontSize="inherit"
              color="inherit"
            ></DeviceThermostatRoundedIcon>
          </div>
          <div className="DASHBOARD_CONTROLLER_PARAMETER_INFO">
            <span>TEMP</span>
          </div>
          <div className="DASHBOARD_CONTROLLER_PARAMETER_INDICATOR">
            {/* <span>
              {this.props.parameters
                ? this.props.parameters.temperature
                : 'N/A'}{' '}
              C&#xb0;
            </span>
            <p>Normal</p> */}
            <TempMeter
              temperature={
                this.props.parameters
                  ? Math.round(this.props.parameters.temperature)
                  : 25
              }
              textColor={this.props.mode === 'light' ? '#082032' : '#dddddd'}
            ></TempMeter>
          </div>
        </div>
        <div className="DASHBOARD_CONTROLLER_PARAMETER_CONTAINER">
          <div className="DASHBOARD_CONTROLLER_PARAMETER_ICON">
            <RouteOutlinedIcon
              fontSize="inherit"
              color="inherit"
            ></RouteOutlinedIcon>
          </div>
          <div className="DASHBOARD_CONTROLLER_PARAMETER_INFO">
            <span>E - STATUS</span>
          </div>
          <div className="DASHBOARD_CONTROLLER_PARAMETER_INDICATOR">
            <div className="CONTROLLER_HEATING_INDICATOR">
              {this.state.activeHeating ? (
                <Lottie
                  animationData={fire}
                  loop={true}
                  autoplay={true}
                  height={20}
                  width={20}
                />
              ) : (
                <WhatshotOutlinedIcon
                  fontSize="inherit"
                  color="inherit"
                ></WhatshotOutlinedIcon>
              )}
            </div>
            {this.state.activeHeating ? (
              <div id="heating_indicator">
                <span>Heating</span>
              </div>
            ) : (
              <p>
                {this.props.parameters &&
                !this.props.controllerConfigs?.vacation_mode
                  ? this.props.parameters?.heating_element_status.toUpperCase()
                  : 'OFF'}
              </p>
            )}
          </div>
        </div>
        <div className="DASHBOARD_CONTROLLER_PARAMETER_CONTAINER">
          <div className="DASHBOARD_CONTROLLER_PARAMETER_ICON">
            <SpeedOutlinedIcon
              fontSize="inherit"
              color="inherit"
            ></SpeedOutlinedIcon>
          </div>
          <div className="DASHBOARD_CONTROLLER_PARAMETER_INFO">
            <span>CONSUMING</span>
          </div>
          <div className="DASHBOARD_CONTROLLER_PARAMETER_INDICATOR">
            <span>
              {this.state.activeHeating
                ? this.props.parameters?.heating_element_current > 4
                  ? this.props.activeController?.controller_meta_data
                      ?.element_power
                  : 0
                : 0}
              &nbsp;KW
            </span>
            <p>Power in KW</p>
          </div>
        </div>
        <div className="DASHBOARD_CONTROLLER_PARAMETER_CONTAINER">
          <div className="DASHBOARD_CONTROLLER_PARAMETER_ICON"></div>

          {this.props.loadingRunOrder ? (
            <div className="DASHBOARD_CONTROLLER_PARAMETER_POWER_BUTTON">
              <CircularProgress
                fontSize="inherit"
                color="inherit"
              ></CircularProgress>{' '}
            </div>
          ) : (
            <div
              className="DASHBOARD_CONTROLLER_PARAMETER_POWER_BUTTON"
              onClick={() => this.handleRunOrderCommand('AUTO')}
            >
              {this.props.controllerConfigs?.vacation_mode ? (
                <h3
                  style={{
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    marginTop: '0em',
                    // color: '#ea4228',
                    fontFamily: 'Roboto',
                  }}
                >
                  V-Mode Active
                </h3>
              ) : null}
              <PowerSettingsNewIcon
                fontSize="inherit"
                sx={
                  this.state.heaterElementStatus === 'ON'
                    ? { color: '#21E6C1' }
                    : this.state.heaterElementStatus === 'SB'
                    ? { color: 'inherit' }
                    : { color: '#E94560' }
                }
              ></PowerSettingsNewIcon>
              {this.state.heaterElementStatus === 'SB' ? (
                <span style={{ fontSize: '1rem' }}>Standby</span>
              ) : null}
            </div>
          )}
        </div>
        <Modal
          open={this.state.runOrderWarning}
          onClose={this.handleRunOrderWarningClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ Backdrop: { timeout: 500 } }}
        >
          <div className="PARAMETERS_WARNING_MODAL">
            <h2>WARNING</h2>
            <p>
              You are trying to turn off the geyser during active run schedule.
              Are you sure you want to turn off the geyser and disable the
              active schedule?.
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="PARAMETERS_WARNING_MODAL_BUTTON"
                onClick={() => this.handleRunOrderCommand('OFF')}
              >
                <span>Confirm</span>
              </div>
              <div
                className="PARAMETERS_WARNING_MODAL_BUTTON"
                onClick={this.handleRunOrderWarningClose}
              >
                <span>Close</span>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.showRunOrderMessage}
          onClose={this.handleRunOrderMessageClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ Backdrop: { timeout: 500 } }}
        >
          <div className="PARAMETERS_WARNING_MODAL">
            <h2>WARNING</h2>
            <p>
              Turning On the geyser manualy is not recommended. Please add a
              schedule for geyser's regular use. If you wish to continue, please
              remember to turn it Off when you are done.
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="PARAMETERS_WARNING_MODAL_BUTTON"
                onClick={this.handleRunOrderMessageClose}
              >
                <span>Okay</span>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.showVmodeWarning}
          onClose={this.handleVmodeWarningClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ Backdrop: { timeout: 500 } }}
        >
          <div className="PARAMETERS_WARNING_MODAL">
            <h2>WARNING</h2>
            <p>
              Vacation Mode is currently enabled for your geyser. To use the
              geyser, please disable Vacation Mode.
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="PARAMETERS_WARNING_MODAL_BUTTON"
                onClick={this.handleVmodeWarningClose}
              >
                <span>Okay</span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    controllers: state.controller.controllers,
    activeController: state.controller.controller,
    controllerStatus: state.controllerStatus.controllerStatus,
    loadingControllerStatus: state.controllerStatus.loading,
    parameters: state.controllerParameters.latestControllerParameters,
    runOrder: state.runOrder.runOrder,
    loadingRunOrder: state.runOrder.loading,
    activeTimeSlot: state.timeSlot.activeTimeSlot,
    controllerConfigs: state.controllerConfigs.controllerConfigs,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveController: (token, IMEI) =>
      dispatch(getControllerByIMEI(token, IMEI)),
    getLastControllerStatus: (token, IMEI) =>
      dispatch(getLastControllerStatusByIMEI(token, IMEI)),
    getLatestParameters: (token, IMEI) =>
      dispatch(getLatestControllerParametersByIMEI(token, IMEI)),
    createElementOrder: (token, order, IMEI, publish) =>
      dispatch(postRunOrder(token, order, IMEI, publish)),
    getLastRunOrder: (token, IMEI) =>
      dispatch(getLastRunOrderByIMEI(token, IMEI)),
    getActiveTimeSlot: (token, day, IMEI) =>
      dispatch(getActiveTimeSlotByDay(token, day, IMEI)),
    deactivateTimeSlot: (token, IMEI, day, timeSlotID) =>
      dispatch(deactivateTimeSlot(token, IMEI, day, timeSlotID)),
    createControllerStatus: (token, IMEI, status) =>
      dispatch(postControllerStatus(token, IMEI, status)),
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(ControllerParameters);
