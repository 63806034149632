import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/style.css';
import * as actions from '../../store/actions/auth';
import LockIcon from '@mui/icons-material/Lock';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

class PasswordResetConfirm extends Component {
  state = {
    new_password1: '',
    new_password2: '',
    password1FieldsCheck: false,
    password2FieldsCheck: false,
    P1P2Match: false,
    invalidLink: false,
    showMessage: false,
    errors: {},
  };

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const response = this.props.response;
      if (response.status === 200) {
        this.setState({
          showMessage: true,
          errors: {
            type: 'success',
            message: 'Your password has been successfully reset.',
          },
        });

        setTimeout(() => {
          this.props.history.push('/login');
        }, 3000);
      } else if (response.status !== 200) {
        if (response.response?.data?.token[0] === 'Invalid value') {
          this.setState({
            showMessage: true,
            invalidLink: true,
            errors: {
              type: 'error',
              message: 'Invalid link. Please request password rest again.',
            },
          });
        } else {
          this.setState({
            showMessage: true,
            errors: {
              type: 'error',
              message: 'Operation Failed, Please contact support team.',
            },
          });
        }
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { new_password1, new_password2, P1P2Match } = this.state;

    if (new_password1 === '') {
      this.setState({
        errors: { new_password1: 'New Password field is required' },
      });
      this.setState({ password1FieldsCheck: true });
      return;
    }
    if (new_password2 === '') {
      this.setState({
        errors: { new_password2: 'Confirm Password field is required' },
      });
      this.setState({ password2FieldsCheck: true });
      return;
    }
    if (P1P2Match === true) {
      this.setState({
        showMessage: true,
        errors: { type: 'error', message: 'Passwords are not matching!' },
      });
      return;
    }

    this.setState({ errors: {} });
    this.changeIt();
  };

  changeIt() {
    const { new_password1, new_password2 } = this.state;
    this.props.PasswordResetConfirmChange(
      new_password1,
      new_password2,
      this.props
    );
  }

  // onChange = e => this.setState({ [e.target.name]: e.target.value });

  onPassword1Change = (e) => {
    this.setState({ showMessage: false, invalidLink: false });
    if (e.target.value !== this.state.new_password2) {
      this.setState({ P1P2Match: true });
    } else {
      this.setState({ P1P2Match: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  onPassword2Change = (e) => {
    this.setState({ showMessage: false, invalidLink: false });
    if (e.target.value !== this.state.new_password1) {
      this.setState({ P1P2Match: true });
    } else {
      this.setState({ P1P2Match: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div className="CONFIRM_RESET_PASSWORD_PAGE_CONTAINER">
        <form className="CONFIRM_RESET_PASSWORD_FORM" onSubmit={this.onSubmit}>
          <div className="FORGOT_PASSWORD_LOADING_CONTAINER">
            {this.props.loading ? (
              <CircularProgress color="success"></CircularProgress>
            ) : (
              <LockIcon fontSize="inherit" color="inherit"></LockIcon>
            )}
          </div>

          <div className="CONFIRM_PASSWORD_INPUT_FIELD_FRAME">
            <TextField
              id="outlined-secondary-2"
              label="Your New Password"
              variant="outlined"
              // color="secondary"
              type="password"
              name="new_password1"
              fullWidth
              required
              placeholder={
                this.state.password1FieldsCheck
                  ? this.state.errors.new_password1
                  : ''
              }
              error={this.state.password1FieldsCheck ? true : false}
              value={this.state.new_password1}
              onChange={(e) => this.onPassword1Change(e)}
              InputProps={{
                className: 'CONFIRM_PASSWORD_INPUT_FIELD',
              }}
              InputLabelProps={{
                shrink: true,
                className: 'FLOATING_LABLE_STYLE',
              }}
            />
          </div>
          <div className="CONFIRM_PASSWORD_INPUT_FIELD_FRAME">
            <TextField
              id="outlined-secondary-2"
              label="Verify Your Password"
              variant="outlined"
              // color="secondary"
              type="password"
              name="new_password2"
              fullWidth
              required
              placeholder={
                this.state.password2FieldsCheck
                  ? this.state.errors.new_password2
                  : ''
              }
              error={this.state.password2FieldsCheck ? true : false}
              value={this.state.new_password2}
              onChange={(e) => this.onPassword2Change(e)}
              InputProps={{
                className: 'CONFIRM_PASSWORD_INPUT_FIELD',
              }}
              InputLabelProps={{
                shrink: true,
                className: 'FLOATING_LABLE_STYLE',
              }}
            />
          </div>

          <div
            className="CONFIRM_PASSWORD_ERROR_CONTAINER"
            style={
              this.state.showMessage && this.state.errors.type === 'error'
                ? { display: 'flex', marginTop: '15px', color: '#f85f73' }
                : this.state.showMessage && this.state.errors.type === 'success'
                ? { display: 'flex', marginTop: '15px', color: '#045757' }
                : { display: 'none' }
            }
          >
            <p>
              {this.state.errors.message}{' '}
              {this.state.invalidLink ? (
                <a href="/forgot-password"> request reset password</a>
              ) : null}
            </p>
          </div>
          <div className="CONFIRM_PASSWORD_FORM_BUTTON" onClick={this.onSubmit}>
            <h6>Confirm</h6>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.users.loading,
    error: state.error,
    token: state.users.token,
    response: state.users.changePWResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    PasswordResetConfirmChange: (new_password1, new_password2, props) =>
      dispatch(
        actions.confirmPasswordChange(new_password1, new_password2, props)
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetConfirm);
