import React from 'react';
import './styles/pie-chart.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import {
  Chart,
  registerShape,
  Axis,
  Interval,
  Interaction,
  Coordinate,
  Tooltip,
  Legend,
} from 'bizcharts';

const sliceNumber = 0.01;

registerShape('interval', 'sliceShape', {
  draw(cfg, container) {
    const points = cfg.points;
    let path = [];
    path.push(['M', points[0].x, points[0].y]);
    path.push(['L', points[1].x, points[1].y - sliceNumber]);
    path.push(['L', points[2].x, points[2].y - sliceNumber]);
    path.push(['L', points[3].x, points[3].y]);
    path.push('Z');
    path = this.parsePath(path);
    return container.addShape('path', {
      attrs: {
        fill: cfg.color,
        path: path,
      },
    });
  },
});

const demoData = [
  {
    day: 'Mon',
    run_duration: 30,
  },
  {
    day: 'Tue',
    run_duration: 60,
  },
  {
    day: 'Wed',
    run_duration: 120,
  },
  {
    day: 'Thu',
    run_duration: 40,
  },
  {
    day: 'Fri',
    run_duration: 30,
  },
  {
    day: 'Sat',
    run_duration: 45,
  },
  {
    day: 'Sun',
    run_duration: 30,
  },
];

export default class PieChart extends React.Component {
  state = {
    openInfo: false,
    anchorEl: null,
  };

  handleClose = () => {
    this.setState({
      openInfo: false,
    });
  };

  handleOpenInfo = (event) => {
    this.setState({
      openInfo: !this.state.openInfo,
      anchorEl: event.currentTarget,
    });
  };

  render() {
    const data = this.props.data?.length > 0 ? this.props.data : demoData;
    const describtion = this.props.describtion;
    const mode = this.props.mode;
    const id = this.state.openInfo ? 'simple-popover' : undefined;
    return (
      <div className="PIE_CHART_MAIN_CONTAINER">
        <div className="PIE_CHART_INFO_CONTAINER">
          <IconButton onClick={this.handleOpenInfo}>
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        </div>
        {this.props.data?.length > 0 ? null : (
          <div className="CHART_WATER_MARK_CONTAINER">
            <p>No data available yet ...</p>
          </div>
        )}
        <Popover
          id={id}
          open={this.state.openInfo}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 1, maxWidth: '250px', color: '#162447' }}>
            {describtion}
          </Typography>
        </Popover>

        <Chart data={data} height={220} width={300} autoFit>
          <Coordinate type="theta" radius={0.8} innerRadius={0.75} />

          <Axis visible={false} />

          <Interval
            adjust="stack"
            position="run_duration"
            shape="sliceShape"
            label="name"
            color="day"
            // color={[
            //   'type',
            //   (xVal) => {
            //     if (xVal === 'Error') {
            //       return 'red';
            //     }
            //     return 'yellow';
            //   },
            // ]}
          />
          <Tooltip>
            {(title, items) => {
              return (
                <div className="TOOLTIP_CONTAINER">
                  <span>{items[0].data.day}</span>
                  <div className="TOOLTIP_ITEM">
                    <p>{items[0].data.run_duration} min</p>
                  </div>
                </div>
              );
            }}
          </Tooltip>
          <Interaction type="element-single-selected"></Interaction>
          {/* <Annotation.Text
            position={[-1.2, -5]}
            content="Total"
            style={{
              fontSize: 20,
              fontWeight: 'normal',
              fill: 'white',
            }}
            // rotate={Math.PI * 0.25}
          /> */}
          {/* <Annotation.Text
            position={[-2.1, -20]}
            content={50}
            style={{
              fontSize: 40,
              fontWeight: 'normal',
              fill: 'white',
            }}
            // rotate={Math.PI * 0.25}
          /> */}
          <Legend
            flipPage={true}
            itemWidth={70}
            itemHeight={25}
            itemStates={{
              active: {
                nameStyle: {
                  opacity: 0.8,
                  fill: mode === 'light' ? '#000' : '#fff',
                },
              },
              unchecked: {
                nameStyle: {
                  fill: mode === 'light' ? '#333' : '#ccc',
                },
                markerStyle: {
                  opacity: 0.2,
                },
              },
              inactive: {
                nameStyle: {
                  fill: mode === 'light' ? '#000' : '#fff',
                },
                markerStyle: {
                  opacity: 0.2,
                },
              },
            }}
            pageNavigator={{
              marker: {
                style: {
                  inactiveFill: '#bbb',
                  inactiveOpacity: 0.45,
                  fill: mode === 'light' ? '#000' : '#fff',
                  opacity: 0.8,
                  size: 20,
                },
              },
              text: {
                style: {
                  fill: mode === 'light' ? '#333' : '#ccc',
                  fontSize: 10,
                },
              },
            }}
          />
        </Chart>
      </div>
    );
  }
}
