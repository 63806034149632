import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getInsightsStart = () => {
  return {
    type: actionTypes.GET_INSIGHTS_START,
  };
};

export const getMonthlyUsageSuccess = (data) => {
  return {
    type: actionTypes.GET_MONTHLY_USAGE_SUCCESS,
    payload: data,
  };
};

export const getCurrentMonthlyUsageSuccess = (data) => {
  return {
    type: actionTypes.GET_CURRENT_MONTHLY_USAGE_SUCCESS,
    payload: data,
  };
};

export const getAnalyticsFaild = () => {
  return {
    type: actionTypes.GET_INSIGHTS_FAILD,
  };
};

export const getGeyserMonthlyUsage = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getInsightsStart());

    axios
      .get(`${HOST_URL}/analytics/monthly-usage/IMEI_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getMonthlyUsageSuccess(response.data)))
      .catch((error) => dispatch(getAnalyticsFaild(error)));
  };
};

export const getGeyserCurrnetMonthlyUsage = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getInsightsStart());

    axios
      .get(`${HOST_URL}/analytics/monthly-usage/current_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) =>
        dispatch(getCurrentMonthlyUsageSuccess(response.data))
      )
      .catch((error) => dispatch(getAnalyticsFaild(error)));
  };
};

export const getMonthUsageByDate = (token, date, IMEI) => {
  return (dispatch) => {
    dispatch(getInsightsStart());

    axios
      .get(`${HOST_URL}/analytics/monthly-usage/date_${date}_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) =>
        dispatch(getCurrentMonthlyUsageSuccess(response.data))
      )
      .catch((error) => dispatch(getAnalyticsFaild(error)));
  };
};
