import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';
import axios from 'axios';

export const getControllerStatusStart = () => {
  return {
    type: actionTypes.GET_CONTROLLER_STATUS_START,
  };
};

export const getControllerStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_CONTROLLER_STATUS_SUCCESS,
    payload: data,
  };
};

export const getControllerStatusFaild = () => {
  return {
    type: actionTypes.GET_CONTROLLER_STATUS_FAILD,
  };
};

export const getControllerStatus = (token, id) => {
  return (dispatch) => {
    dispatch(getControllerStatusStart());

    axios
      .get(`${HOST_URL}/controllers/controller-status/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerStatusSuccess(response.data)))
      .catch((error) => dispatch(getControllerStatusFaild(error)));
  };
};

export const getControllerStatusByIMEI = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getControllerStatusStart());

    axios
      .get(`${HOST_URL}/controllers/controller-status/order_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerStatusSuccess(response.data)))
      .catch((error) => dispatch(getControllerStatusFaild(error)));
  };
};

export const getLastControllerStatusByIMEI = (token, IMEI) => {
  return (dispatch) => {
    dispatch(getControllerStatusStart());
    axios
      .get(`${HOST_URL}/controllers/controller-status/last_${IMEI}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getControllerStatusSuccess(response.data)))
      .catch((error) => dispatch(getControllerStatusFaild(error)));
  };
};

export const postControllerStatus = (token, IMEI, status) => {
  return (dispatch) => {
    dispatch(getControllerStatusStart());
    let fd = new FormData();
    fd.append('IMEI', IMEI);
    fd.append('controller_status', status);

    axios
      .post(`${HOST_URL}/controllers/controller-status/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => dispatch(getControllerStatusSuccess(response.data)))
      .catch((error) => dispatch(getControllerStatusFaild(error)));
  };
};
