import React, { Component } from 'react';
import './styles/verify-account.css';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/auth';
import { CircularProgress } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';

class VerifyAccount extends Component {
  state = {
    verificationCode: '',
    verificationCodeError: false,
    accountVerified: false,
    accountVerificationResult: '',
  };

  componentDidMount() {
    if (
      localStorage.getItem('verification-sent') !== 'true' &&
      this.props.userDetails
    ) {
      this.requestVerificationCode();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.verificationInfoSent !== prevProps.verificationInfoSent &&
      this.props.verificationInfoSent
    ) {
      localStorage.setItem('verification-sent', true);
    }

    if (this.props.verificationInfo !== prevProps.verificationInfo) {
      if (this.props.verificationInfo.verified) {
        this.setState({
          accountVerified: true,
          accountVerificationResult: 'Account verified successfully',
        });
        setTimeout(() => this.props.history.push('/register-controller'), 1000);
      } else {
        this.setState({
          accountVerified: false,
          accountVerificationResult: this.props.verificationInfo?.message,
        });
      }
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  requestVerificationCode = () => {
    const token = this.props.token;
    const recipient = this.props.userDetails?.email;
    if (recipient) {
      this.props.sendVerficationInfo(token, recipient);
    }
  };

  verifyCode = () => {
    const token = this.props.token;
    const verificationCode = this.state.verificationCode;
    this.props.verifyCode(token, verificationCode);
  };

  handleResendCode = () => {
    console.log('resend code');
    this.requestVerificationCode();
  };

  render() {
    return (
      <div className="VERIFY_ACCOUNT_MAIN_CONTAINER">
        <div className="VERIFY_FORM_CONTAINER">
          <div className="VERIFY_ICON_CONTAINER">
            <SecurityIcon color="inherit" fontSize="inherit"></SecurityIcon>
          </div>
          <div className="VERIFY_HEADER_CONTAINER">
            <h3>Check your E-mail and Enter the verification code.</h3>
            {this.props.verificationInfo || this.props.error ? (
              <div
                className="VERIFICATION_FEEDBACK_MESSAGE"
                style={
                  this.state.accountVerified
                    ? { backgroundColor: '#29C7AC' }
                    : { backgroundColor: '#FF6768' }
                }
              >
                <span>
                  {this.state.accountVerificationResult}
                  {this.props.error?.message}
                </span>
              </div>
            ) : null}
          </div>
          <div className="VERIFY_FORM_CONTAINER">
            <div className="SIGNUP_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="ve"
                label="Verification Code"
                variant="outlined"
                error={this.state.verificationCodeError}
                name="verificationCode"
                fullWidth
                value={this.state.verificationCode}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'SIGNUP_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SIGNUP_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="VERIFY_BUTTON_CONTAINER">
            {this.props.loading ? (
              <CircularProgress></CircularProgress>
            ) : (
              <div
                className="SIGNUP_BUTTON_CONTAINER"
                onClick={this.verifyCode}
              >
                <h6>Verify</h6>
              </div>
            )}
            <div>
              <span>
                {' '}
                Didn't receive code!.{' '}
                <a href="#!" onClick={this.handleResendCode}>
                  Resend
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    userDetails: state.users.userDetails,
    loading: state.users.loading,
    error: state.users.error,
    userLoading: state.users.loading,
    verificationInfo: state.users.verificationInfo,
    verificationInfoSent: state.users.verificationInfoSent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendVerficationInfo: (token, recipient) =>
      dispatch(actions.emailVerificationRequest(token, recipient)),
    verifyCode: (token, verificationCode) =>
      dispatch(actions.verifyCode(token, verificationCode)),
    getUserDetails: (token) => dispatch(actions.getUserDetails(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);
