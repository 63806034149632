// Calculate date range for fetching data

export const getCurrentDayDates = () => {
  const currentDate = new Date();
  const firstDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    0,
    0,
    0,
    0
  );

  const lastDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    23,
    59,
    59,
    999
  );

  return { firstDate, lastDate };
};

export const getLastSevenDaysDates = () => {
  const lastSevenDays = [];
  const currentDate = new Date();

  for (let i = 6; i >= 0; i--) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - i,
      0,
      0,
      0,
      0
    );
    lastSevenDays.push(date);
  }

  const firstDate = lastSevenDays[0];
  const lastDate = lastSevenDays[lastSevenDays.length - 1];

  lastDate.setHours(23, 59, 59, 999);

  return { firstDate, lastDate };
};

export const getCurrentMonthDates = () => {
  const currentDate = new Date();
  const firstDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  firstDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000

  const lastDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  lastDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999

  return { firstDate, lastDate };
};

export const adjustDateRange = (Date1, Date2) => {
  if (Date1 && Date2) {
    const firstDate = new Date(
      Date1.getFullYear(),
      Date1.getMonth(),
      Date1.getDate(),
      0,
      0,
      0,
      0
    );

    const lastDate = new Date(
      Date2.getFullYear(),
      Date2.getMonth(),
      Date2.getDate(),
      23,
      59,
      59,
      999
    );
    console.log(firstDate, lastDate);
    return { firstDate, lastDate };
  } else if (Date1 && !Date2) {
    const startDate = new Date(Date1);
    const firstDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0,
      0
    );

    const endDate = new Date(Date1);
    const lastDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59,
      59,
      999
    );
    return { firstDate, lastDate };
  } else if (Date2 && !Date1) {
    const startDate = new Date(Date2);
    const firstDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0,
      0
    );

    const endDate = new Date(Date2);
    const lastDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59,
      59,
      999
    );
    return { firstDate, lastDate };
  } else {
    return { firstDate: null, lastDate: null };
  }
};
